var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
            _vm.userType == 1 ||
                (_vm.userType != 1 &&
                    !_vm.employeeView &&
                    _vm.permissionIsAssigned('medicals-read'))
        )?_c('div',{staticClass:"main-content-wrap sidenav-open d-flex flex-column"},[_c('div',{staticClass:"main-header pl-4",class:_vm.$vuetify.breakpoint.name == 'xs' ||
                _vm.$vuetify.breakpoint.name == 'sm'
                    ? 'pr-1'
                    : 'pr-5',staticStyle:{"z-index":"60"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"logo col-md-1 col-lg-1 col-12 py-0",staticStyle:{"align-self":"center"}},[_c('div',{staticClass:"dropdown animate__animated animate__fadeIn",staticStyle:{"font-size":"120%"}},[_c('i',{staticClass:"fal fa-arrow-alt-circle-left mr-1",staticStyle:{"cursor":"pointer","position":"relative","top":"2px","color":"#069"},on:{"click":function($event){_vm.employee_name
                                    ? (_vm.employee_name = '')
                                    : _vm.$router.push({ name: 'ClientArea' })}}}),_c('span',{staticClass:"ml-2",staticStyle:{"top":"4%","position":"relative"}},[_vm._v("Back")])])]),(
                        _vm.userType != 1 &&
                            _vm.permissionIsAssigned('medicals-read')
                    )?_c('div',{staticClass:"input-group-prepend"},[_c('button',{staticClass:"btn dropdown-toggle w-100",attrs:{"type":"button","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_c('span',{style:(_vm.$vuetify.breakpoint.name == 'xs'
                                    ? 'margin-left: 1.5rem;'
                                    : _vm.$vuetify.breakpoint.name == 'sm'
                                    ? 'margin-left: 5rem;'
                                    : null)},[_vm._v(_vm._s(_vm.employeeView ? 'Your Medical Records' : "All Employees' Medical Records"))])]),(_vm.permissionIsAssigned('medicals-read'))?_c('div',{staticClass:"dropdown-menu",staticStyle:{"min-width":"13rem"}},[_c('a',{staticClass:"dropdown-item",staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.employeeView = !_vm.employeeView}}},[_vm._v(" "+_vm._s(_vm.employeeView ? "All Employees' Medical Records" : 'Your Medical Records')+" ")])]):_vm._e()]):_vm._e()]),_c('div',{staticStyle:{"margin":"auto"}}),_c('div',{staticClass:"header-part-right",class:_vm.$vuetify.breakpoint.name == 'xs' ||
                    _vm.$vuetify.breakpoint.name == 'sm'
                        ? null
                        : 'pr-5'},[(_vm.userType == 1)?_c('div',{staticClass:"dropdown"},[_c('button',{staticClass:"btn btn-outline-secondary m-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.push({ name: 'BillingHistory' })}}},[_vm._v(" Billing History ")])]):_vm._e(),_c('div',{staticClass:"dropdown"},[(_vm.userType != '1')?_c('button',{staticClass:"btn btn-outline-secondary m-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.push({ name: 'LogReport' })}}},[_vm._v(" Log Time ")]):_vm._e(),(_vm.userType == '1')?_c('button',{staticClass:"btn btn-outline-secondary m-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.push({ name: 'Attendance' })}}},[_vm._v(" Log History ")]):_vm._e()])])]),_c('div',{staticClass:"main-content pt-0",class:_vm.$vuetify.breakpoint.name == 'xs' ||
                _vm.$vuetify.breakpoint.name == 'sm'
                    ? 'px-3'
                    : null},[_c('div',{staticClass:"breadcrumb",staticStyle:{"margin-top":"1rem"}},[_c('div',{staticClass:"row",style:(_vm.$vuetify.breakpoint.name == 'xs'
                            ? 'width: 110%;'
                            : 'width: 80%;')},[_c('h1',{staticClass:"my-0 row pb-0"},[(_vm.employee_name)?_c('span',{staticClass:"col-lg-5 col-md-5 col-12"},[_vm._v("Medical Bills for "+_vm._s(_vm.employee_name.toLowerCase() == 'all' ? 'All Employees' : _vm.employee_name))]):_c('span',{staticClass:"col-lg-5 col-md-5 col-12"},[_vm._v("All Medicals Allowances")]),_c('v-row',{staticClass:"col-md-7 col-lg-7 col-12",staticStyle:{"float":"right"}},[_c('v-col',{staticClass:"pt-0",class:_vm.$vuetify.breakpoint.name == 'xs' ||
                                    _vm.$vuetify.breakpoint.name == 'sm'
                                        ? 'text-left'
                                        : 'text-center',attrs:{"cols":"12","lg":"4","md":"4","offset-lg":"1","offset-md":"1"}},[(_vm.employee_name)?_c('button',{staticClass:"btn btn-raised ripple btn-raised-secondary m-1 text-white",attrs:{"type":"button"},on:{"click":function($event){_vm.employee_name = ''}}},[_vm._v(" Show All Medical Allowances ")]):[(
                                            _vm.permissionIsAssigned(
                                                'medicals-create'
                                            )
                                        )?_c('button',{staticClass:"btn btn-raised ripple btn-raised-secondary m-1 text-white",attrs:{"type":"button"},on:{"click":function($event){_vm.create_medicals_dialog = true}}},[_vm._v(" Add Medical Allowance ")]):_vm._e()]],2),_c('v-col',{staticClass:"text-right pt-0",attrs:{"cols":"12","lg":"3","md":"3"}},[_c('button',{staticClass:"btn btn-raised ripple btn-raised-secondary m-1 text-white",attrs:{"type":"button"},on:{"click":function($event){_vm.create_medical_bill_dialog = true}}},[_vm._v(" Add Medical Bill ")])]),_c('v-col',{staticClass:"pt-0 text-right",attrs:{"cols":"12","lg":"4","md":"4"}},[_c('v-combobox',{staticClass:"remove-mb",attrs:{"items":_vm.allEmployees,"placeholder":"Select employee bills","dense":"","solo":"","messages":"*Select Employee to View Bills","full-width":true},model:{value:(_vm.employee_name),callback:function ($$v) {_vm.employee_name=$$v},expression:"employee_name"}})],1)],1)],1),(_vm.employee_name)?_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card",staticStyle:{"border-radius":"10px"}},[_c('div',{staticClass:"card-body px-1 py-1 pt-0 pr-0"},[_c('div',{staticClass:"table-responsive",staticStyle:{"border-radius":"10px","height":"75vh","overflow-y":"scroll","padding-top":"0","padding-bottom":"0"}},[_c('table',{staticClass:"table table-hover"},[_vm._m(0),_c('tbody',_vm._l((_vm.employee_medical_bills),function(bill){return _c('tr',{key:bill.id},[(
                                                        _vm.get_hospital_name(
                                                            bill.hospital_id
                                                        )
                                                    )?_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.get_hospital_name( bill.hospital_id ))+" ")]):_c('td',{staticClass:"text-left"},[_c('i',[_vm._v("Hospital not found")])]),_c('td',{staticClass:"text-left",attrs:{"scope":"row"}},[_vm._v(" ₦"+_vm._s(_vm._f("number_with_commas")(bill.amount))+" ")]),_c('td',{staticClass:"text-left",attrs:{"scope":"row"}},[_vm._v(" "+_vm._s(bill.description)+" ")]),_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(new Date( bill.created_at ) .toUTCString() .substring( 0, 16 ))+" ")]),(
                                                        bill.is_approved ==
                                                            0
                                                    )?_c('td',[_c('span',{staticClass:"badge badge-warning text-white text-center",staticStyle:{"width":"60px"}},[_vm._v("PENDING")])]):(
                                                        bill.is_approved ==
                                                            1
                                                    )?_c('td',[_c('span',{staticClass:"badge badge-success text-center",staticStyle:{"width":"60px"}},[_vm._v("APPROVED")])]):_c('td',[_c('span',{staticClass:"badge badge-danger text-center",staticStyle:{"width":"60px"}},[_vm._v("REJECTED")])]),_c('td',{staticClass:"text-left employee-options",staticStyle:{"padding-top":"20px"}},[_c('div',{staticClass:"row"},[(
                                                                _vm.permissionIsAssigned(
                                                                    'medicals-comment'
                                                                )
                                                            )?_c('i',{staticClass:"col-2 py-0 text-center fal fa-message-dots",staticStyle:{"color":"#069 !important","font-size":"125%","cursor":"pointer"},on:{"click":function($event){return _vm.view_comment(
                                                                    bill
                                                                )}}}):_vm._e(),_c('i',{staticClass:"col-2 py-0 text-center fal fa-eye",staticStyle:{"color":"#069 !important","font-size":"125%","cursor":"pointer"},on:{"click":function($event){return _vm.make_current_bill(
                                                                    bill
                                                                )}}}),(
                                                                _vm.permissionIsAssigned(
                                                                    'medicals-approve'
                                                                )
                                                            )?_c('i',{staticClass:"col-2 py-0 text-center fal fa-check-circle",staticStyle:{"color":"#069 !important","font-size":"125%","cursor":"pointer"},on:{"click":function($event){return _vm.approve_medical_bill(
                                                                    bill
                                                                )}}}):_vm._e(),(
                                                                _vm.permissionIsAssigned(
                                                                    'medicals-approve'
                                                                )
                                                            )?_c('i',{staticClass:"col-2 py-0 text-center fal fa-times-circle",staticStyle:{"color":"#069 !important","font-size":"125%","cursor":"pointer"},on:{"click":function($event){return _vm.disapprove_medical_bill(
                                                                    bill
                                                                )}}}):_vm._e()])])])}),0)])])])])]):_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card",staticStyle:{"border-radius":"10px"}},[_c('div',{staticClass:"card-body px-1 py-1 pt-0 pr-0"},[_c('div',{staticClass:"table-responsive",staticStyle:{"border-radius":"10px","height":"75vh","overflow-y":"scroll","padding-top":"0","padding-bottom":"0"}},[_c('table',{staticClass:"table table-hover"},[_c('thead',[_c('tr',{staticStyle:{"border":"2px solid #4440"}},[_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Employee Name ")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Amount Allocated ")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Amount Left ")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Date Added ")]),(
                                                        _vm.permissionIsAssigned(
                                                            'medicals-update'
                                                        ) ||
                                                            _vm.permissionIsAssigned(
                                                                'medicals-delete'
                                                            )
                                                    )?_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Action ")]):_vm._e()])]),_c('tbody',_vm._l((_vm.auth_company_all_medicals),function(medical){return _c('tr',{key:medical.id},[_c('th',{staticClass:"underline text-left",attrs:{"scope":"row"},on:{"click":function($event){_vm.employee_name = _vm.get_employee_name(
                                                            medical.employee_id
                                                        )}}},[_vm._v(" "+_vm._s(_vm.get_employee_name( medical.employee_id ))+" ")]),_c('td',{staticClass:"text-left",attrs:{"scope":"row"}},[_vm._v(" ₦"+_vm._s(_vm._f("number_with_commas")(medical.initial_amount))+" ")]),(
                                                        medical.updated_amount
                                                    )?_c('td',{staticClass:"text-left"},[_vm._v(" ₦"+_vm._s(_vm._f("number_with_commas")(medical.updated_amount))+" ")]):_c('td',{staticClass:"text-left"},[_vm._v(" ₦"+_vm._s(_vm._f("number_with_commas")(medical.initial_amount))+" ")]),_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(medical.month)+", "+_vm._s(medical.year)+" ")]),(
                                                        _vm.permissionIsAssigned(
                                                            'medicals-update'
                                                        ) ||
                                                            _vm.permissionIsAssigned(
                                                                'medicals-delete'
                                                            )
                                                    )?_c('td',{staticClass:"text-left"},[_c('div',{staticClass:"row pt-2"},[(
                                                                _vm.permissionIsAssigned(
                                                                    'medicals-update'
                                                                )
                                                            )?_c('i',{staticClass:"col-3 py-0 text-center fal fa-edit",staticStyle:{"color":"#069 !important","font-size":"125%","cursor":"pointer"},on:{"click":function($event){return _vm.launch_medicals_edit(
                                                                    medical
                                                                )}}}):_vm._e(),(
                                                                _vm.permissionIsAssigned(
                                                                    'medicals-delete'
                                                                )
                                                            )?_c('i',{staticClass:"col-3 py-0 text-center fal fa-trash",staticStyle:{"color":"#069 !important","font-size":"125%","cursor":"pointer"},on:{"click":function($event){return _vm.delete_medicals(
                                                                    medical
                                                                )}}}):_vm._e()])]):_vm._e()])}),0)])])])])])])]),_c('div',{staticClass:"row"})]),_c('div',{staticClass:"flex-grow-1"})]):_c('div',{staticClass:"main-content-wrap sidenav-open d-flex flex-column"},[_c('div',{staticClass:"main-header pl-4",class:_vm.$vuetify.breakpoint.name == 'xs' ||
                _vm.$vuetify.breakpoint.name == 'sm'
                    ? 'pr-1'
                    : 'pr-5',staticStyle:{"z-index":"60"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"logo col-md-1 col-lg-1 col-12 py-0",staticStyle:{"align-self":"center"}},[_c('div',{staticClass:"dropdown animate__animated animate__fadeIn",staticStyle:{"font-size":"120%"}},[_c('i',{staticClass:"fal fa-arrow-alt-circle-left mr-1",staticStyle:{"cursor":"pointer","position":"relative","top":"2px","color":"#069"},on:{"click":function($event){return _vm.$router.push({ name: 'ClientArea' })}}}),_c('span',{staticClass:"ml-2",staticStyle:{"top":"4%","position":"relative"}},[_vm._v("Back")])])]),(
                        _vm.userType != 1 &&
                            _vm.permissionIsAssigned('medicals-read')
                    )?_c('div',{staticClass:"input-group-prepend"},[_c('button',{staticClass:"btn dropdown-toggle w-100",attrs:{"type":"button","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_c('span',{style:(_vm.$vuetify.breakpoint.name == 'xs'
                                    ? 'margin-left: 1.5rem;'
                                    : _vm.$vuetify.breakpoint.name == 'sm'
                                    ? 'margin-left: 5rem;'
                                    : null)},[_vm._v(_vm._s(_vm.employeeView ? 'Your Medical Records' : "All Employees' Medical Records"))])]),(_vm.permissionIsAssigned('medicals-read'))?_c('div',{staticClass:"dropdown-menu",staticStyle:{"min-width":"13rem"}},[_c('a',{staticClass:"dropdown-item",staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.employeeView = !_vm.employeeView}}},[_vm._v(" "+_vm._s(_vm.employeeView ? "All Employees' Medical Records" : 'Your Medical Records')+" ")])]):_vm._e()]):_vm._e()]),_c('div',{staticStyle:{"margin":"auto"}}),_c('div',{staticClass:"header-part-right",class:_vm.$vuetify.breakpoint.name == 'xs' ||
                    _vm.$vuetify.breakpoint.name == 'sm'
                        ? null
                        : 'pr-5'},[(_vm.userType == 1)?_c('div',{staticClass:"dropdown"},[_c('button',{staticClass:"btn btn-outline-secondary m-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.push({ name: 'BillingHistory' })}}},[_vm._v(" Billing History ")])]):_vm._e(),_c('div',{staticClass:"dropdown"},[(_vm.userType != '1')?_c('button',{staticClass:"btn btn-outline-secondary m-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.push({ name: 'LogReport' })}}},[_vm._v(" Log Time ")]):_vm._e(),(_vm.userType == '1')?_c('button',{staticClass:"btn btn-outline-secondary m-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.push({ name: 'Attendance' })}}},[_vm._v(" Log History ")]):_vm._e()])])]),_c('div',{staticClass:"main-content pt-0",class:_vm.$vuetify.breakpoint.name == 'xs' ||
                _vm.$vuetify.breakpoint.name == 'sm'
                    ? 'px-2'
                    : null},[_c('div',{staticClass:"breadcrumb",staticStyle:{"margin-top":"1rem"}},[_c('div',{staticClass:"row",style:(_vm.$vuetify.breakpoint.name == 'xs'
                            ? 'width: 110%;'
                            : 'width: 80%;')},[_c('h1',{staticClass:"my-0 row pb-0"},[_c('span',{staticClass:"col-lg-5 col-md-5 col-12"},[_vm._v("Your Medical Records "+_vm._s(/*employee_medicals && */ _vm.employee_medicals ? null : '(No medical allowance)'))]),(_vm.employee_medicals)?_c('v-row',{staticClass:"col-md-7 col-lg-7 col-12"},[_c('v-col',{staticClass:"pt-0 pr-0",class:_vm.$vuetify.breakpoint.name == 'xs' ||
                                    _vm.$vuetify.breakpoint.name == 'sm'
                                        ? 'text-left'
                                        : 'text-right',attrs:{"cols":"5","lg":"4","md":"4"}},[(!_vm.employee_medicals)?_c('h5',[_vm._v(" Total amount: "),_c('br'),_vm._v("₦0 ")]):[(
                                            _vm.employee_medicals.initial_amount
                                        )?_c('h5',[_vm._v(" Total amount: "),_c('br'),_vm._v("₦"+_vm._s(_vm._f("number_with_commas")(_vm.employee_medicals.initial_amount))+" ")]):_c('h5',[_vm._v("Total amount: "),_c('br'),_vm._v("₦0")])]],2),_c('v-col',{staticClass:"pt-0 pr-0",class:_vm.$vuetify.breakpoint.name == 'xs' ||
                                    _vm.$vuetify.breakpoint.name == 'sm'
                                        ? 'text-left'
                                        : 'text-right',attrs:{"cols":"7","lg":"4","md":"4"}},[(!_vm.employee_medicals)?_c('h5',[_vm._v(" Available amount: "),_c('br'),_vm._v("₦0 ")]):[(
                                            _vm.employee_medicals.updated_amount
                                        )?_c('h5',[_vm._v(" Available amount: "),_c('br'),_vm._v("₦"+_vm._s(_vm._f("number_with_commas")(_vm.employee_medicals.updated_amount))+" ")]):(
                                            _vm.employee_medicals.initial_amount
                                        )?_c('h5',[_vm._v(" Available amount: "),_c('br'),_vm._v("₦"+_vm._s(_vm._f("number_with_commas")(_vm.employee_medicals.initial_amount))+" ")]):_c('h5',[_vm._v(" Available amount: "),_c('br'),_vm._v("₦0 ")])]],2),_c('v-col',{staticClass:"text-right pt-0",attrs:{"cols":"6","lg":"4","md":"4"}},[_c('button',{staticClass:"btn btn-raised ripple btn-raised-secondary m-1 mt-0 text-white",attrs:{"type":"button"},on:{"click":function($event){_vm.create_medical_bill_dialog = true}}},[_vm._v(" Add Medical Bill ")])])],1):_vm._e()],1),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card",staticStyle:{"border-radius":"10px"}},[_c('div',{staticClass:"card-body px-1 py-1 pt-0 pr-0"},[_c('div',{staticClass:"table-responsive",staticStyle:{"border-radius":"10px","height":"75vh","overflow-y":"scroll","padding-top":"0","padding-bottom":"0"}},[_c('table',{staticClass:"table table-hover"},[_vm._m(1),(_vm.employee_medicals)?_c('tbody',_vm._l((_vm.employee_medical_bills),function(bill){return _c('tr',{key:bill.id},[(
                                                        _vm.get_hospital_name(
                                                            bill.hospital_id
                                                        )
                                                    )?_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.get_hospital_name( bill.hospital_id ))+" ")]):_c('td',{staticClass:"text-left"},[_c('i',[_vm._v("Hospital not found")])]),_c('td',{staticClass:"text-left",attrs:{"scope":"row"}},[_vm._v(" ₦"+_vm._s(_vm._f("number_with_commas")(bill.amount))+" ")]),_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(bill.description)+" ")]),_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(bill.month)+", "+_vm._s(bill.year)+" ")]),(
                                                        bill.is_approved ==
                                                            0
                                                    )?_c('td',[_c('span',{staticClass:"badge badge-warning text-center text-white",staticStyle:{"width":"60px"}},[_vm._v("PENDING")])]):(
                                                        bill.is_approved ==
                                                            1
                                                    )?_c('td',[_c('span',{staticClass:"badge text-center badge-success",staticStyle:{"width":"60px"}},[_vm._v("APPROVED")])]):_c('td',[_c('span',{staticClass:"badge text-center badge-danger",staticStyle:{"width":"60px"}},[_vm._v("REJECTED")])]),_c('td',{staticClass:"text-left employee-options",staticStyle:{"padding-top":"20px"}},[_c('div',{staticClass:"row"},[(
                                                                _vm.permissionIsAssigned(
                                                                    'medicals-comment'
                                                                )
                                                            )?_c('i',{staticClass:"col-2 py-0 text-center fal fa-message-dots",staticStyle:{"color":"#069 !important","font-size":"125%","cursor":"pointer"},on:{"click":function($event){return _vm.view_comment(
                                                                    bill
                                                                )}}}):_vm._e(),_c('i',{staticClass:"col-2 py-0 text-center fal fa-eye",staticStyle:{"color":"#069 !important","font-size":"125%","cursor":"pointer"},on:{"click":function($event){return _vm.make_current_bill(
                                                                    bill
                                                                )}}}),(
                                                                (bill.is_approved ==
                                                                    0 ||
                                                                    bill.is_approved ==
                                                                        2) &&
                                                                    _vm.permissionIsAssigned(
                                                                        'medicals-approve'
                                                                    )
                                                            )?_c('i',{staticClass:"col-2 py-0 text-center fal fa-pencil-alt",staticStyle:{"color":"#069 !important","font-size":"125%","cursor":"pointer"},on:{"click":function($event){return _vm.launch_medical_bill_edit(
                                                                    bill
                                                                )}}}):_vm._e(),(
                                                                (bill.is_approved ==
                                                                    0 ||
                                                                    bill.is_approved ==
                                                                        2) &&
                                                                    _vm.permissionIsAssigned(
                                                                        'medicals-approve'
                                                                    )
                                                            )?_c('i',{staticClass:"col-2 py-0 text-center fal fa-trash-alt",staticStyle:{"color":"#069 !important","font-size":"125%","cursor":"pointer"},on:{"click":function($event){return _vm.delete_medical_bill(
                                                                    bill
                                                                )}}}):_vm._e()])])])}),0):_c('tbody')])])])])])])]),_c('div',{staticClass:"row"})]),_c('div',{staticClass:"flex-grow-1"})]),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.medical_bill_image),callback:function ($$v) {_vm.medical_bill_image=$$v},expression:"medical_bill_image"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Medical Bill Receipt")])]),_c('v-card-text',[_c('v-container',[_c('img',{attrs:{"src":_vm.current_medical_bill
                                ? _vm.assets_url +
                                  _vm.current_medical_bill.receipt_url
                                : null,"alt":"","srcset":""}})])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.medical_bill_image = false}}},[_vm._v(" Close ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.create_medical_bill_dialog),callback:function ($$v) {_vm.create_medical_bill_dialog=$$v},expression:"create_medical_bill_dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Add Medical Bill")])]),_c('v-card-text',[_c('v-container',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"container-fluid"},[_c('v-row',[_c('v-col',{staticClass:"remove-hint",attrs:{"cols":"12"}},[_c('span',{staticStyle:{"color":"#111d5e"}},[_vm._v("Amount "),_c('i',{staticClass:"fas fa-asterisk",staticStyle:{"font-size":"8px","color":"#f00","position":"relative","bottom":"5px"}})]),_c('v-text-field',{attrs:{"placeholder":"Enter amount","solo":"","dense":"","type":"number"},model:{value:(_vm.billFormData.amount),callback:function ($$v) {_vm.$set(_vm.billFormData, "amount", $$v)},expression:"billFormData.amount"}})],1),_c('v-col',{staticClass:"remove-hint",attrs:{"cols":"12"}},[_c('span',{staticStyle:{"color":"#111d5e"}},[_vm._v("Description "),_c('i',{staticClass:"fas fa-asterisk",staticStyle:{"font-size":"8px","color":"#f00","position":"relative","bottom":"5px"}})]),_c('v-textarea',{attrs:{"placeholder":"Description","solo":""},model:{value:(_vm.billFormData.description),callback:function ($$v) {_vm.$set(_vm.billFormData, "description", $$v)},expression:"billFormData.description"}})],1),(
                                        _vm.userType == 1 ||
                                            (_vm.userType != 1 &&
                                                !_vm.employeeView &&
                                                _vm.permissionIsAssigned(
                                                    'medicals-read'
                                                ))
                                    )?_c('v-col',{staticClass:"remove-hint",attrs:{"cols":"12"}},[_c('span',{staticStyle:{"color":"#111d5e"}},[_vm._v("Employee "),_c('i',{staticClass:"fas fa-asterisk",staticStyle:{"font-size":"8px","color":"#f00","position":"relative","bottom":"5px"}})]),_c('v-select',{attrs:{"item-color":"#069","items":_vm.allEmployees2,"placeholder":"Select Employee","solo":"","color":"#069"},model:{value:(_vm.billFormData.employee_name),callback:function ($$v) {_vm.$set(_vm.billFormData, "employee_name", $$v)},expression:"billFormData.employee_name"}})],1):_vm._e(),_c('v-col',{staticClass:"remove-hint",attrs:{"cols":"12"}},[_c('span',{staticStyle:{"color":"#111d5e"}},[_vm._v("Hospital "),_c('i',{staticClass:"fas fa-asterisk",staticStyle:{"font-size":"8px","color":"#f00","position":"relative","bottom":"5px"}})]),_c('v-select',{attrs:{"item-color":"#069","items":_vm.hospitals,"placeholder":"Select Hospital","solo":"","color":"#069"},model:{value:(_vm.billFormData.hospital_name),callback:function ($$v) {_vm.$set(_vm.billFormData, "hospital_name", $$v)},expression:"billFormData.hospital_name"}})],1),_c('v-col',{staticClass:"remove-hint",attrs:{"cols":"12"}},[_c('span',{staticStyle:{"color":"#111d5e"}},[_vm._v("Medical Bill Receipt "),_c('i',{staticClass:"fas fa-asterisk",staticStyle:{"font-size":"8px","color":"#f00","position":"relative","bottom":"5px"}})]),_c('v-file-input',{staticClass:"upload-medical-bill",staticStyle:{"text-indent":"5px","cursor":"pointer"},attrs:{"prepend-icon":"","prepend-inner-icon":"mdi-attachment mdi-rotate-270","placeholder":"Upload the medical bill receipt","solo":"","color":"#069"}})],1),_c('v-col',{staticClass:"remove-hint",attrs:{"cols":"12","sm":"4","md":"4","offset-md":"4","offset-sm":"4"}},[_c('button',{staticClass:"btn btn-secondary m-1 text-white btn-raised ripple",staticStyle:{"width":"99%"},attrs:{"type":"button"},on:{"click":function($event){_vm.add_medical_bills_submit
                                                ? null
                                                : _vm.add_medical_bills()}}},[_vm._v(" "+_vm._s(_vm.add_medical_bills_text)+" "),_c('v-icon',{style:(!_vm.add_medical_bills_submit
                                                    ? 'display: none;'
                                                    : 'color: #fff; padding: 0px 10px; font-size: 16px;')},[_vm._v(" fal fa-circle-notch fa-spin ")])],1)])],1)],1)])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.create_medical_bill_dialog = false}}},[_vm._v(" Close ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.create_medicals_dialog),callback:function ($$v) {_vm.create_medicals_dialog=$$v},expression:"create_medicals_dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Add Medical Allowance")])]),_c('v-card-text',[_c('v-container',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"container-fluid"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('span',{staticStyle:{"color":"#111d5e"}},[_vm._v("Employee "),_c('i',{staticClass:"fas fa-asterisk",staticStyle:{"font-size":"8px","color":"#f00","position":"relative","bottom":"5px"}})]),_c('v-select',{staticClass:"remove-mb",attrs:{"items":_vm.allEmployees2,"placeholder":"Select employee","dense":"","solo":"","full-width":true},model:{value:(_vm.formData.employee_name),callback:function ($$v) {_vm.$set(_vm.formData, "employee_name", $$v)},expression:"formData.employee_name"}})],1),_c('v-col',{staticClass:"remove-hint",attrs:{"cols":"6"}},[_c('span',{staticStyle:{"color":"#111d5e"}},[_vm._v("Initial Amount "),_c('i',{staticClass:"fas fa-asterisk",staticStyle:{"font-size":"8px","color":"#f00","position":"relative","bottom":"5px"}})]),_c('v-text-field',{attrs:{"placeholder":"Enter initial amount","solo":"","dense":"","type":"number"},model:{value:(_vm.formData.initial_amount),callback:function ($$v) {_vm.$set(_vm.formData, "initial_amount", $$v)},expression:"formData.initial_amount"}})],1),_c('v-col',{staticClass:"remove-hint",attrs:{"cols":"12","sm":"4","md":"4","offset-md":"4","offset-sm":"4"}},[(
                                            _vm.userType == 1 ||
                                                (_vm.userType != 1 &&
                                                    !_vm.employeeView &&
                                                    _vm.permissionIsAssigned(
                                                        'medicals-read'
                                                    ))
                                        )?_c('button',{staticClass:"btn btn-secondary m-1 text-white btn-raised ripple",staticStyle:{"width":"99%"},attrs:{"type":"button"},on:{"click":function($event){_vm.add_medicals_submit
                                                ? null
                                                : _vm.add_medicals()}}},[_vm._v(" "+_vm._s(_vm.add_medicals_text)+" "),_c('v-icon',{style:(!_vm.add_medicals_submit
                                                    ? 'display: none;'
                                                    : 'color: #fff; padding: 0px 10px; font-size: 16px;')},[_vm._v(" fal fa-circle-notch fa-spin ")])],1):_vm._e()])],1)],1)])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.create_medicals_dialog = false}}},[_vm._v(" Close ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.edit_medicals_dialog),callback:function ($$v) {_vm.edit_medicals_dialog=$$v},expression:"edit_medicals_dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Edit Medical")])]),_c('v-card-text',[_c('v-container',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"container-fluid"},[_c('v-row',[_c('v-col',{staticClass:"remove-hint",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"placeholder":"Initial Amount","solo":"","dense":"","type":"number"},model:{value:(
                                            _vm.editFormData.initial_amount
                                        ),callback:function ($$v) {_vm.$set(_vm.editFormData, "initial_amount", $$v)},expression:"\n                                            editFormData.initial_amount\n                                        "}})],1),_c('v-col',{staticClass:"remove-hint",attrs:{"cols":"12","sm":"4","md":"4","offset-md":"4","offset-sm":"4"}},[(
                                            _vm.userType == 1 ||
                                                (_vm.userType != 1 &&
                                                    !_vm.employeeView &&
                                                    _vm.permissionIsAssigned(
                                                        'medicals-read'
                                                    ))
                                        )?_c('button',{staticClass:"btn btn-secondary m-1 text-white btn-raised ripple",staticStyle:{"width":"99%"},attrs:{"type":"button"},on:{"click":function($event){_vm.edit_medicals_submit
                                                ? null
                                                : _vm.edit_medicals()}}},[_vm._v(" "+_vm._s(_vm.edit_medicals_text)+" "),_c('v-icon',{style:(!_vm.edit_medicals_submit
                                                    ? 'display: none;'
                                                    : 'color: #fff; padding: 0px 10px; font-size: 16px;')},[_vm._v(" fal fa-circle-notch fa-spin ")])],1):_vm._e()])],1)],1)])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.edit_medicals_dialog = false}}},[_vm._v(" Close ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.edit_medical_bill_dialog),callback:function ($$v) {_vm.edit_medical_bill_dialog=$$v},expression:"edit_medical_bill_dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Edit Your Medical Bill")])]),_c('v-card-text',[_c('v-container',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"container-fluid"},[_c('v-row',[_c('v-col',{staticClass:"remove-hint",attrs:{"cols":"12"}},[_c('span',{staticStyle:{"color":"#111d5e"}},[_vm._v("Amount "),_c('i',{staticClass:"fas fa-asterisk",staticStyle:{"font-size":"8px","color":"#f00","position":"relative","bottom":"5px"}})]),_c('v-text-field',{attrs:{"placeholder":"Amount","solo":"","dense":"","type":"number"},model:{value:(_vm.editBillFormData.amount),callback:function ($$v) {_vm.$set(_vm.editBillFormData, "amount", $$v)},expression:"editBillFormData.amount"}})],1),_c('v-col',{staticClass:"remove-hint",attrs:{"cols":"12"}},[_c('span',{staticStyle:{"color":"#111d5e"}},[_vm._v("Description "),_c('i',{staticClass:"fas fa-asterisk",staticStyle:{"font-size":"8px","color":"#f00","position":"relative","bottom":"5px"}})]),_c('v-textarea',{attrs:{"placeholder":"Description","solo":""},model:{value:(
                                            _vm.editBillFormData.description
                                        ),callback:function ($$v) {_vm.$set(_vm.editBillFormData, "description", $$v)},expression:"\n                                            editBillFormData.description\n                                        "}})],1),_c('v-col',{staticClass:"remove-hint",attrs:{"cols":"12"}},[_c('span',{staticStyle:{"color":"#111d5e"}},[_vm._v("Hospital "),_c('i',{staticClass:"fas fa-asterisk",staticStyle:{"font-size":"8px","color":"#f00","position":"relative","bottom":"5px"}})]),_c('v-select',{attrs:{"item-color":"#069","items":_vm.hospitals,"placeholder":"Select Hospital","solo":"","color":"#069"},model:{value:(
                                            _vm.editBillFormData.hospital_name
                                        ),callback:function ($$v) {_vm.$set(_vm.editBillFormData, "hospital_name", $$v)},expression:"\n                                            editBillFormData.hospital_name\n                                        "}})],1),_c('v-col',{staticClass:"remove-hint",attrs:{"cols":"12"}},[_c('span',{staticStyle:{"color":"#111d5e"}},[_vm._v("Medical Bill Receipt "),_c('i',{staticClass:"fas fa-asterisk",staticStyle:{"font-size":"8px","color":"#f00","position":"relative","bottom":"5px"}})]),_c('v-file-input',{staticClass:"upload-medical-bill-2",staticStyle:{"text-indent":"5px","cursor":"pointer"},attrs:{"prepend-icon":"","prepend-inner-icon":"mdi-attachment mdi-rotate-270","placeholder":"Update the medical bill receipt","solo":"","color":"#069"}})],1),_c('v-col',{staticClass:"remove-hint",attrs:{"cols":"12","sm":"4","md":"4","offset-md":"4","offset-sm":"4"}},[_c('button',{staticClass:"btn btn-secondary m-1 text-white btn-raised ripple",staticStyle:{"width":"99%"},attrs:{"type":"button"},on:{"click":function($event){_vm.edit_medical_bill_submit
                                                ? null
                                                : _vm.edit_medical_bill()}}},[_vm._v(" "+_vm._s(_vm.edit_medical_bill_text)+" "),_c('v-icon',{style:(!_vm.edit_medical_bill_submit
                                                    ? 'display: none;'
                                                    : 'color: #fff; padding: 0px 10px; font-size: 16px;')},[_vm._v(" fal fa-circle-notch fa-spin ")])],1)])],1)],1)])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.edit_medical_bill_dialog = false}}},[_vm._v(" Close ")])],1)],1)],1),_c('div',{staticClass:"flex-grow-1"}),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.comment_dialog),callback:function ($$v) {_vm.comment_dialog=$$v},expression:"comment_dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.approval ? 'Approve' : 'Disapprove')+" Medical Bill with Comment")])]),_c('v-card-text',[_c('v-container',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"container-fluid"},[_c('v-row',[_c('v-col',{staticClass:"remove-hint",attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"placeholder":_vm.userType == 1 ||
                                            (_vm.userType != 1 &&
                                                !_vm.employeeView &&
                                                _vm.permissionIsAssigned(
                                                    'medicals-read'
                                                ))
                                                ? 'Medical Bill Comment'
                                                : 'No comment',"solo":"","disabled":_vm.userType != 1 ? true : false},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1),(
                                        _vm.userType == 1 ||
                                            (_vm.userType != 1 &&
                                                !_vm.employeeView &&
                                                _vm.permissionIsAssigned(
                                                    'medicals-read'
                                                ))
                                    )?_c('v-col',{staticClass:"remove-hint",attrs:{"cols":"12","sm":"4","md":"4","offset-md":"4","offset-sm":"4"}},[_c('button',{staticClass:"btn btn-secondary m-1 text-white btn-raised ripple",staticStyle:{"width":"99%"},attrs:{"type":"button"},on:{"click":function($event){_vm.add_comment_submit
                                                ? null
                                                : _vm.medical_bill_action()}}},[_vm._v(" "+_vm._s(_vm.add_comment_text)+" "),_c('v-icon',{style:(!_vm.add_comment_submit
                                                    ? 'display: none;'
                                                    : 'color: #fff; padding: 0px 10px; font-size: 16px;')},[_vm._v(" fal fa-circle-notch fa-spin ")])],1)]):_vm._e()],1)],1)])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.comment_dialog = false}}},[_vm._v(" Close ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.medical_bill_comment_dialog),callback:function ($$v) {_vm.medical_bill_comment_dialog=$$v},expression:"medical_bill_comment_dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Medical Bill Comments")])]),_c('v-card-text',[_c('v-container',[_c('table',{staticClass:"table table-hover"},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Date Sent")]),_c('th',{staticClass:"text-left"},[_vm._v("Comment")])])]),_c('tbody',_vm._l((_vm.all_medical_bill_comments),function(comment){return _c('tr',{key:comment.id},[_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(new Date(comment.created_at) .toUTCString() .substring(0, 25))+" ")]),_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(comment.comments)+" ")])])}),0)])])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.medical_bill_comment_dialog = false}}},[_vm._v(" Close ")])],1)],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',{staticStyle:{"border":"2px solid #4440"}},[_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Hospital ")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Amount ")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Description ")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Date Added ")]),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v(" Status ")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Action ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',{staticStyle:{"border":"2px solid #4440"}},[_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Hospital ")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Amount ")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Description ")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Date Added ")]),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v(" Status ")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Action ")])])])}]

export { render, staticRenderFns }