<template>
  <div class="">
    <v-app class="">
      <v-row style="margin-top: 60px;">
        <v-col
          cols="12"
          style="background: url(/img/contact-banner.png); background-repeat: round;"
        >
          <div class="px-10" style="padding-top: 100px; padding-bottom: 20px;">
            <h2
              class="text-left"
              style="font-family: Poppins !important; font-size: 38px; line-height: 57px; font-weight: 600; color: #FFF !important;"
            >
              REQUEST DEMO
            </h2>
          </div>
        </v-col>
        <v-col cols="12">
          <v-container>
            <v-row>
              <v-col cols="12" md="4">
                <div style="margin-top: 40px;">
                  <v-row>
                    <v-col cols="12">
                      <img src="/img/contact-arrow.svg" />
                      <span
                        class="ml-2"
                        style="color: #225BA6; font-weight: 500; font-size: 20px;"
                        >Kylian ERP</span
                      >
                    </v-col>
                    <v-col cols="12">
                      <h2
                        class="text-left"
                        style="max-width: 80%; font-family: Poppins !important; font-size: 32px; line-height: 48px; font-weight: 600; color: #000 !important;"
                      >
                        Feel Free to Contact Us
                      </h2>
                    </v-col>
                    <v-col cols="12">
                      <div>
                        <v-row>
                          <v-col cols="3">
                            <img src="/img/contact-call-icon.png" />
                          </v-col>
                          <v-col cols="9">
                            <p
                              style="font-size: 16px; font-family: Poppins !important;"
                            >
                              <span style="color: #6B6A7D; font-weight: 400;"
                                >Call Anytime</span
                              >
                              <br />
                              <span style="color: #2D2D2D; font-weight: 600;"
                                >+2348055257262</span
                              >
                              <br />
                              <span style="color: #2D2D2D; font-weight: 600;"
                                >+2347080593338</span
                              >
                            </p>
                          </v-col>
                        </v-row>
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <div>
                        <v-row>
                          <v-col cols="3">
                            <img src="/img/contact-email-icon.png" />
                          </v-col>
                          <v-col cols="9">
                            <p
                              style="font-size: 16px; font-family: Poppins !important;"
                            >
                              <!-- <span style="color: #6B6A7D; font-weight: 400;">Send Email</span>
                                                            <br >
                                                            <span style="color: #2D2D2D; font-weight: 600;">info@kylianerp.com</span>
                                                            <br >
                                                            <span style="color: #2D2D2D; font-weight: 600;">pricing@kylianerp.com</span>
                                                            <br > -->
                              <span style="color: #2D2D2D; font-weight: 600;"
                                >kylian@kylianerp.com</span
                              >
                            </p>
                          </v-col>
                        </v-row>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
              <v-col cols="12" md="8">
                <svg
                  style="position: absolute; right: -250px;"
                  width="348"
                  height="607"
                  viewBox="0 0 348 607"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M674.422 0.863501L368.581 606.615L0.712913 82.04L674.422 0.863501Z"
                    fill="#0B54C0"
                  />
                </svg>
                <div
                  class="card"
                  style="background: #FFFFFF; box-shadow: 2px 6px 50px rgba(0, 0, 43, 0.15); border-radius: 20px;"
                >
                  <div
                    class="card-body"
                    :style="
                      $vuetify.breakpoint.name == 'xs' ||
                      $vuetify.breakpoint.name == 'sm' ||
                      $vuetify.breakpoint.name == 'md'
                        ? 'padding: 3.25rem 1.25rem;'
                        : 'padding: 4.25rem 6.25rem;'
                    "
                  >
                    <div class="text-left">
                      <v-row
                        :class="
                          $vuetify.breakpoint.name == 'xs' ||
                          $vuetify.breakpoint.name == 'sm' ||
                          $vuetify.breakpoint.name == 'md'
                            ? 'px-4'
                            : null
                        "
                      >
                        <v-col cols="12" md="6">
                          <h4
                            style="font-size: 20px; font-weight: 400; color: #2D2D2D !important;"
                          >
                            Please enter your information
                          </h4>
                        </v-col>
                        <v-col cols="12" md="6" class="text-left text-md-right">
                          <i
                            class="fal fa-asterisk"
                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                          ></i
                          ><span
                            style="color: #2D2D2D; font-size: 13px; font-weight: 400;"
                            >Required information</span
                          >
                        </v-col>
                        <v-col cols="12" class="pb-0">
                          <v-text-field
                            dense
                            v-model="formData.name"
                            label="Contact Name *"
                            outlined
                            placeholder="Enter the contact full name"
                            class="remove-bottom-hint"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="pb-0">
                          <v-text-field
                            dense
                            v-model="formData.organization"
                            label="Company Name *"
                            outlined
                            placeholder="Enter the company name"
                            class="remove-bottom-hint"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="pb-0">
                          <v-text-field
                            dense
                            v-model="formData.email"
                            label="Email Address *"
                            outlined
                            placeholder="Enter your email address"
                            class="remove-bottom-hint"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="pb-0">
                          <v-text-field
                            dense
                            v-model="formData.phone"
                            label="Phone Number *"
                            outlined
                            placeholder="Enter the phone number"
                            class="remove-bottom-hint"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="pb-0">
                          <v-select
                            :items="[
                              'Oil & Gas',
                              'Law',
                              'Real Estate',
                              'Education',
                              'Agriculture',
                              'Technology',
                              'Marketing',
                              'Production',
                              'Finance',
                              'SME',
                              'HR',
                              'Investment',
                              'Research',
                              'Entrepreneurship',
                            ]"
                            label="Industry *"
                            placeholder="Select your industry"
                            outlined
                            dense
                            class="remove-bottom-hint"
                            v-model="formData.source"
                          >
                          </v-select>
                        </v-col>

                        <v-col cols="12" class="pb-0">
                          <v-select
                            :items="[
                              'Instagram',
                              'Facebook',
                              'LinkedIn',
                              'X',
                              'YouTube',
                              'Billboards',
                              'eTenders',
                              'Others',
                            ]"
                            label="How did you hear about us *"
                            placeholder="How did you hear about us?"
                            outlined
                            dense
                            class="remove-bottom-hint"
                            v-model="formData.social"
                          >
                          </v-select>
                        </v-col>

                        <v-col cols="12" class="pb-0">
                          <v-text-field
                            dense
                            type="number"
                            v-model="formData.company_size"
                            label="Number of Staff *"
                            outlined
                            placeholder="Enter the number of staff to onboard"
                            class="remove-bottom-hint"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" class="pb-0">
                          <v-row class="pb-6">
                            <v-col cols="12" class="pb-0 pt-4">
                              <h4
                                style="margin: 0; font-size: 18px; font-weight: 500; color: #000 !important;"
                              >
                                Select your preferred services
                              </h4>
                            </v-col>
                            <v-col
                              cols="12"
                              md="4"
                              v-for="(service, index) in allServices"
                              :key="index"
                            >
                              <div>
                                <div
                                  class="card"
                                  style="background: #FFFFFF; box-shadow: 0px 2px 10px -3px rgba(0, 0, 0, 0.2); border-radius: 5px;"
                                >
                                  <div class="card-body px-2 py-2">
                                    <v-row>
                                      <v-col
                                        :style="
                                          formData.services.indexOf(
                                            service.service_name
                                          ) !== -1
                                            ? 'opacity: 0.5'
                                            : ''
                                        "
                                        cols="9"
                                        style="align-self: center"
                                      >
                                        <span
                                          style="color: #333333; font-weight: 400; font-size: 14px;"
                                          >{{ service.service_name }}</span
                                        >
                                      </v-col>
                                      <v-col
                                        cols="1"
                                        offset="1"
                                        offset-md="0"
                                        md="3"
                                        style="align-self: center"
                                      >
                                        <v-checkbox
                                          v-model="formData.services"
                                          :disabled="service.tier <= tier"
                                          class="py-0 my-0"
                                          density="compact"
                                          dense
                                          color="#0B54C0"
                                          :value="service.service_name"
                                          hide-details
                                        ></v-checkbox>
                                      </v-col>
                                    </v-row>
                                  </div>
                                </div>
                              </div>
                            </v-col>
                          </v-row>
                        </v-col>

                        <v-col cols="12" class="pb-0">
                          <v-textarea
                            dense
                            v-model="formData.message"
                            label="Message *"
                            outlined
                            placeholder="Enter message"
                            class="remove-bottom-hint"
                          ></v-textarea>
                        </v-col>

                        <v-col class="pb-6 text-left" cols="12" sm="12">
                          <v-btn
                            @click.prevent="isLoading ? null : request_demo()"
                            type="submit"
                            x-large
                            dark
                            class="new-create-submit"
                            style="background: #0B54C0 !important; color: #FFF;height: 48px; font-size: 16px; border-radius: 5px !important;"
                            :style="
                              $vuetify.breakpoint.name == 'xs' ||
                              $vuetify.breakpoint.name == 'sm' ||
                              $vuetify.breakpoint.name == 'md'
                                ? 'width: 100% !important;'
                                : 'width: 106px !important;'
                            "
                            :class="
                              $vuetify.breakpoint.name == 'xs' ||
                              $vuetify.breakpoint.name == 'sm' ||
                              $vuetify.breakpoint.name == 'md'
                                ? 'w-100'
                                : 'w-50'
                            "
                          >
                            {{ btnTxt }}
                            <v-icon :style="!isLoading ? 'display: none' : ''"
                              >fas fa-circle-notch fa-spin
                            </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
            <svg
              class="d-none d-md-block"
              width="281"
              height="606"
              viewBox="0 0 281 606"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style="
                            position: absolute;
                            top: 600px;
                            left: 0px;
                        "
            >
              <path
                d="M280.788 0.366758L-26.4899 605.391L-393.112 79.944L280.788 0.366758Z"
                fill="#0B54C0"
              />
            </svg>
          </v-container>
        </v-col>

        <GuestNewsletter />

        <GuestFooter />
      </v-row>

      <div class="text-center">
        <v-dialog v-model="dialog" width="500">
          <v-card>
            <v-card-text class="pt-5">
              <div class="col-md-8 mx-auto text-center pb-5">
                <img
                  src="@/assets/graphics/check.png"
                  alt=""
                  class="img-fluid"
                />
              </div>
              <h3 class="msg pl-5 pr-5 text-center" style="display: block;">
                {{ responseForDialog }}
              </h3>
            </v-card-text>

            <v-divider class="mb-0"></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="#069" text @click="dialog = false">
                <strong>Close</strong>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <div class="text-center">
        <v-dialog v-model="dialog_2" width="500">
          <v-card>
            <v-card-text class="pt-5">
              <div class="col-md-8 mx-auto text-center pb-5">
                <img
                  src="@/assets/graphics/cross-check.png"
                  alt=""
                  class="img-fluid"
                />
              </div>
              <h3 class="msg pl-5 pr-5 text-center" style="display: block;">
                {{ responseForDialog }}
              </h3>
            </v-card-text>

            <v-divider class="mb-0"></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="#069" text @click="dialog_2 = false">
                <strong>Close</strong>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-app>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import axios from "axios";
import { BASE_URL } from "@/main";
import GuestFooter from "@/components/GuestFooter";
import GuestNewsletter from "@/components/GuestNewsletter";
export default {
  components: {
    GuestFooter,
    GuestNewsletter,
  },
  data() {
    return {
      menu: false,
      dialog: false,
      dialog_2: false,
      responseForDialog: "",
      formData: {
        name: "",
        organization: "",
        email: "",
        phone: "",
        date: "",
        source: "",
        company_size: "",
        message: "",
        services: [
          "Onboarding",
          // 'Recruitment'
        ],
        industry: "",
        social: "",
      },
      btnTxt: "Submit",
      isLoading: false,
    };
  },
  computed: {
    ...mapState(["captchaSiteKey"]),
    ...mapGetters([
      "authCompanyData",
      "authUserData",
      "isAuth",
      "authAllAvailableServices",
    ]),
    tier() {
      return this.$route.query.tier;
    },
    allServices() {
      let _this = this,
        humanResourcesServices = [],
        accountsServices = [],
        result = [];
      if (_this.authAllAvailableServices) {
        humanResourcesServices =
          _this.authAllAvailableServices.human_resources_services;
        accountsServices = _this.authAllAvailableServices.accounting_services;
        result = [...humanResourcesServices, ...accountsServices];
      }
      return result;
    },
    servicesNames() {
      let _this = this,
        humanResourcesServices = [],
        humanResourcesServicesNames = [],
        accountsServices = [],
        accountsServicesNames = [],
        result = [];
      if (_this.authAllAvailableServices) {
        humanResourcesServices =
          _this.authAllAvailableServices.human_resources_services;
        accountsServices = _this.authAllAvailableServices.accounting_services;
        humanResourcesServicesNames = humanResourcesServices.map(
          (service) => service.service_name
        );
        accountsServicesNames = accountsServices.map(
          (service) => service.service_name
        );
        result = [...humanResourcesServicesNames, ...accountsServicesNames];
      }
      return result;
    },
    productFocus() {
      let _this = this,
        value = "";
      _this.formData.services.forEach((service, index) => {
        value +=
          index === _this.formData.services.length - 1
            ? `${service}.`
            : `${service}, `;
      });
      return value;
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  methods: {
    ...mapActions(["fetch_available_services"]),
    save(date) {
      this.$refs.menu.save(date);
    },
    async request_demo() {
      let _this = this;
      await window.grecaptcha.ready(function() {
        if (_this.isLoading) {
          return;
        }
        _this.btnTxt = "";
        _this.isLoading = true;
        window.grecaptcha
          .execute(_this.captchaSiteKey, { action: "submit" })
          .then(function(token) {
            axios
              .post(`${BASE_URL}demo`, {
                contact_person: _this.formData.name,
                organization: _this.formData.organization,
                email: _this.formData.email,
                phone: _this.formData.phone,
                date: _this.formData.date,
                source: _this.formData.source,
                company_size: _this.formData.company_size,
                message: _this.formData.message,
                services: _this.productFocus,
                social: _this.formData.social,
                token,
              })
              .then(function() {
                _this.responseForDialog =
                  "Your demo request has been sent and received successfully. You'll be contacted shortly";
                _this.dialog = true;
                if (_this.isAuth === false) {
                  _this.formData.name = "";
                  _this.formData.organization = "";
                  _this.formData.email = "";
                  _this.formData.phone = "";
                  _this.formData.company_size = "";
                }
                _this.formData.date = "";
                _this.formData.source = "";
                _this.formData.message = "";
                _this.formData.services = [];
                _this.isLoading = false;
                _this.formData.social = "";
                _this.btnTxt = "Submit";
              })
              .catch(function() {
                _this.isLoading = false;
                _this.btnTxt = "Submit";
                _this.responseForDialog = "Your request couldn't be sent";
                _this.dialog_2 = true;
              })
              .then(function() {
                _this.isLoading = false;
                _this.btnTxt = "Submit";
              });
          })
          .catch(function(error) {
            _this.isLoading = false;
            _this.btnTxt = "Submit";
            console.error("reCAPTCHA error:", error);
          });
      });
    },
  },
  mounted() {
    const _this = this;
    document.title = "Kylian ERP - Request Demo";
    _this.fetch_available_services().then(() => {
      _this.allServices.forEach((service) => {
        if (_this.tier >= service.tier) {
          _this.formData.services.splice(
            _this.formData.services.length,
            0,
            service.service_name
          );
        }
      });
    });
  },
};
</script>

<style lang="scss">
$new-button-color: #225ba6;
$primary-color: #069;
$text-field-color: #4e4e52;

.new-create-submit {
  span {
    font-family: Inter !important;
  }
}

.text-new-primary {
  color: $new-button-color !important;
}

.v-application .primary--text {
  color: $primary-color !important;
}

.fa-eye,
.fa-eyeslash {
  color: $primary-color !important;
}

// .v-text-field__suffix, .v-text-field__prefix, input {
//     color: $text-field-color !important;
//     background-color: #00669914;
//     padding: 10px;
//     height: 48px;
// }
.v-text-field__suffix {
  border-radius: 0px 5px 5px 0px;
  padding-top: 13px;
  padding-left: 8px !important;
}

.v-text-field__prefix {
  border-radius: 5px 0px 0px 5px;
  padding-top: 13px;
  padding-right: 8px !important;
  text-decoration: underline;
}

.company-name .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) {
  label {
    margin-left: 2%;
  }

  input {
    text-indent: 3%;
  }
}

.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: 0px 0px 50px 0px #00000014 !important;
}

.v-text-field__details {
  padding-left: 0 !important;
}

.theme--light.v-messages {
  color: $primary-color !important;
}

.company-name
  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  padding: 0px !important;
}

.remove-bottom-hint div .v-text-field__details {
  display: none;
}
</style>
<style lang="scss" scoped>
$new-button-color: #2081ff;
$primary-color: #069;

@media (max-width: 991px) {
  .create-submit {
    height: 45px !important;
  }

  .v-form {
    margin: 0 !important;
  }
}

.v-form {
  margin: 0 20%;
}

.svg-login {
  position: absolute;
  top: 8%;
  right: 22%;
}

#small-link {
  color: $primary-color !important;
}

.space-top {
  padding-top: 40px;
}

.create-submit {
  width: 100%;
  height: 60px;
  border-radius: 10px !important;
  background-color: #069 !important;
  text-transform: initial !important;
  font-weight: 600 !important;

  span {
    text-transform: initial !important;
  }
}

.new-create-submit {
  width: 100%;
  height: 60px;
  border-radius: 10px !important;
  background-color: $new-button-color !important;
  text-transform: initial !important;
  font-weight: 600 !important;

  span {
    text-transform: initial !important;
    font-family: Inter !important;
  }
}

h3 {
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.6252632141113281px;
  text-align: center;
  color: #111d5e;
}

label {
  color: #999 !important;
}
</style>

<style lang="scss">
.home-page-subscriber .v-text-field--outlined .v-label,
.home-page-subscriber .theme--light.v-input input,
.theme--light.v-input textarea {
  color: #ffffff !important;
}

.home-page-subscriber
  .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
  color: #ffffff !important;
}
</style>
