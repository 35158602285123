<template>
    <div class="col-12 col-lg-2 col-xl-2 col-md-12">
        <div class="accordion" id="accordionRightIcon">
            <div class="card">
                <div class="card-header header-elements-inline">
                    <span
                        style="font-weight: 100; font-size: 0.813rem;"
                        class="card-title ul-collapse__icon--size ul-collapse__right-icon mb-0"
                    >
                        <a
                            style="color: #111d5e !important;"
                            class="text-default collapsed"
                            data-toggle="collapse"
                            href="#accordion-item-icon-right-security"
                            aria-expanded="false"
                        >
                            Security
                        </a>
                    </span>
                </div>
                <div
                    class="collapse"
                    id="accordion-item-icon-right-security"
                    data-parent="#accordionRightIcon"
                    style=""
                >
                    <div class="list-group" id="list-tab" role="tablist">
                        <a
                            style="border-radius: 0;"
                            class="list-group-item list-group-item-action"
                            id="list-2-fa-list"
                            data-toggle="list"
                            href="#list-2-fa"
                            role="tab"
                            aria-controls="list-2-fa"
                            aria-selected="false"
                            @click="check_class('Two-Factor Authentication')"
                        >
                            Two-Factor Authentication
                        </a>
                    </div>
                </div>
            </div>

            <div v-if="userType == 1" class="card">
                <div class="card-header header-elements-inline">
                    <span
                        style="font-weight: 100; font-size: 0.813rem;"
                        class="card-title ul-collapse__icon--size ul-collapse__right-icon mb-0"
                    >
                        <a
                            style="color: #111d5e !important;"
                            class="text-default collapsed"
                            data-toggle="collapse"
                            href="#accordion-item-icon-right-general"
                            aria-expanded="false"
                        >
                            General Settings
                        </a>
                    </span>
                </div>
                <div
                    class="collapse"
                    id="accordion-item-icon-right-general"
                    data-parent="#accordionRightIcon"
                    style=""
                >
                    <div class="list-group" id="list-tab" role="tablist">
                        <a
                            style="border-radius: 0;"
                            class="list-group-item list-group-item-action"
                            id="list-2-signature-list"
                            data-toggle="list"
                            href="#list-2-signature"
                            role="tab"
                            aria-controls="list-2-signature"
                            aria-selected="false"
                            @click="check_class('Company Signature')"
                        >
                            Company Signature
                        </a>
                    </div>
                </div>
            </div>
            <div
                v-if="
                    (userType == 1 ||
                        permissionIsAssigned('attendance-view')) &&
                        check_all_services('Attendance')
                "
                class="card"
            >
                <div class="card-header header-elements-inline">
                    <span
                        style="font-weight: 100; font-size: 0.813rem;"
                        class="card-title ul-collapse__icon--size ul-collapse__right-icon mb-0"
                    >
                        <a
                            style="color: #111d5e !important;"
                            class="text-default collapsed"
                            data-toggle="collapse"
                            href="#accordion-item-icon-right-1"
                            aria-expanded="false"
                        >
                            Attendance
                        </a>
                    </span>
                </div>
                <div
                    class="collapse"
                    id="accordion-item-icon-right-1"
                    data-parent="#accordionRightIcon"
                    style=""
                >
                    <div class="list-group" id="list-tab" role="tablist">
                        <a
                            v-if="permissionIsAssigned('ip-address-read')"
                            style="border-radius: 0;"
                            class="list-group-item list-group-item-action"
                            id="list-home-list"
                            data-toggle="list"
                            href="#list-home"
                            role="tab"
                            aria-controls="list-home"
                            aria-selected="false"
                            @click="check_class('IP Management')"
                        >
                            IP Management
                        </a>
                        <a
                            v-if="
                                check_all_services('Attendance') &&
                                    permissionIsAssigned(
                                        'attendance-config-read'
                                    )
                            "
                            class="list-group-item list-group-item-action"
                            id="list-settings-list12"
                            data-toggle="list"
                            href="#list-settings12"
                            role="tab"
                            aria-controls="list-settings12"
                            aria-selected="false"
                            @click="check_class('Configurations')"
                        >
                            Configurations
                        </a>
                        <a
                            v-if="
                                check_all_services('Attendance') &&
                                    permissionIsAssigned('ip-address-read')
                            "
                            class="list-group-item list-group-item-action"
                            id="list-settings-list122"
                            data-toggle="list"
                            href="#list-settings122"
                            role="tab"
                            aria-controls="list-settings122"
                            aria-selected="false"
                            @click="check_class('Geolocation Management')"
                        >
                            Geolocation Management
                        </a>
                        <a
                            v-if="
                                userType == 1 ||
                                    permissionIsAssigned('attendance-view')
                            "
                            class="list-group-item list-group-item-action"
                            id="list-settings-list13"
                            data-toggle="list"
                            href="#list-settings13"
                            role="tab"
                            aria-controls="list-settings13"
                            aria-selected="true"
                            @click="check_class('Holiday Management')"
                        >
                            Holiday Management
                        </a>
                        <a
                            v-if="
                                userType == 1 ||
                                    permissionIsAssigned('attendance-view')
                            "
                            class="list-group-item list-group-item-action"
                            id="list-settings-list14"
                            data-toggle="list"
                            href="#list-settings14"
                            role="tab"
                            aria-controls="list-settings14"
                            aria-selected="true"
                            @click="check_class('Deductions Management')"
                        >
                            Deductions Management
                        </a>
                    </div>
                </div>
            </div>
            <div
                v-if="
                    (userType == 1 ||
                        permissionIsAssigned('leave-type-read')) &&
                        check_all_services('Absence or Leave')
                "
                class="card"
            >
                <div class="card-header header-elements-inline">
                    <span
                        style="font-weight: 100; font-size: 0.813rem;"
                        class="card-title ul-collapse__icon--size ul-collapse__right-icon mb-0"
                    >
                        <a
                            style="color: #111d5e !important;"
                            class="text-default collapsed"
                            data-toggle="collapse"
                            href="#accordion-item-icon-right-2"
                            aria-expanded="false"
                        >
                            Absence/Leave
                        </a>
                    </span>
                </div>
                <div
                    v-if="permissionIsAssigned('leave-type-read')"
                    class="collapse"
                    id="accordion-item-icon-right-2"
                    data-parent="#accordionRightIcon"
                    style=""
                >
                    <div class="list-group" id="list-tab" role="tablist">
                        <a
                            class="list-group-item list-group-item-action"
                            id="list-messages-list"
                            data-toggle="list"
                            href="#list-messages"
                            role="tab"
                            aria-controls="list-messages"
                            aria-selected="false"
                            @click="check_class('Leave Policies')"
                        >
                            Leave Policies
                        </a>
                        <a
                            class="list-group-item list-group-item-action"
                            id="list-messages-list-reset"
                            data-toggle="list"
                            href="#list-messages-reset"
                            role="tab"
                            aria-controls="list-messages-reset"
                            aria-selected="false"
                            @click="check_class('Leave Reset')"
                        >
                            Leave Reset
                        </a>
                    </div>
                </div>
            </div>
            <div
                v-if="
                    check_all_services('Onboarding') &&
                        (userType == 1 || permissionIsAssigned('employee-read'))
                "
                class="card"
            >
                <div class="card-header header-elements-inline">
                    <span
                        style="font-weight: 100; font-size: 0.813rem;"
                        class="card-title ul-collapse__icon--size ul-collapse__right-icon mb-0"
                    >
                        <a
                            style="color: #111d5e !important;"
                            class="text-default collapsed"
                            data-toggle="collapse"
                            href="#accordion-item-icon-right-3"
                            aria-expanded="false"
                        >
                            Assets
                        </a>
                    </span>
                </div>
                <div
                    class="collapse"
                    id="accordion-item-icon-right-3"
                    data-parent="#accordionRightIcon"
                    style=""
                >
                    <div class="list-group" id="list-tab" role="tablist">
                        <a
                            style="border-radius: 0;"
                            class="list-group-item list-group-item-action"
                            id="list-profile-list"
                            data-toggle="list"
                            href="#list-profile"
                            role="tab"
                            aria-controls="list-profile"
                            aria-selected="false"
                            @click="check_class('Asset Types')"
                        >
                            Asset Types
                        </a>
                        <a
                            style="border-radius: 0;"
                            class="list-group-item list-group-item-action"
                            id="list-checklists-list"
                            data-toggle="list"
                            href="#list-checklists"
                            role="tab"
                            aria-controls="list-checklists"
                            aria-selected="false"
                            @click="check_class('Checklists')"
                        >
                            Checklists
                        </a>
                    </div>
                </div>
            </div>
            <div
                v-if="
                    check_all_services('Assets') &&
                        permissionIsAssigned('vendor-read')
                "
                class="card"
            >
                <div class="card-header header-elements-inline">
                    <span
                        style="font-weight: 100; font-size: 0.813rem;"
                        class="card-title ul-collapse__icon--size ul-collapse__right-icon mb-0"
                    >
                        <a
                            style="color: #111d5e !important;"
                            class="text-default collapsed"
                            data-toggle="collapse"
                            href="#accordion-item-icon-right-30"
                            aria-expanded="false"
                        >
                            Vendors
                        </a>
                    </span>
                </div>
                <div
                    class="collapse"
                    id="accordion-item-icon-right-30"
                    data-parent="#accordionRightIcon"
                    style=""
                >
                    <div class="list-group" id="list-tab" role="tablist">
                        <a
                            style="border-radius: 0;"
                            class="list-group-item list-group-item-action"
                            id="list-profile-list0"
                            data-toggle="list"
                            href="#list-profile0"
                            role="tab"
                            aria-controls="list-profile0"
                            aria-selected="false"
                            @click="check_class('Vendors Management')"
                        >
                            Vendors Management
                        </a>
                    </div>
                </div>
            </div>

            <div
                v-if="/* Should be based on permissions */ userType == 1"
                class="card"
            >
                <div class="card-header header-elements-inline">
                    <span
                        style="font-weight: 100; font-size: 0.813rem;"
                        class="card-title ul-collapse__icon--size ul-collapse__right-icon mb-0"
                    >
                        <a
                            style="color: #111d5e !important;"
                            class="text-default collapsed"
                            data-toggle="collapse"
                            href="#accordion-item-icon-branches"
                            aria-expanded="false"
                        >
                            Branches
                        </a>
                    </span>
                </div>
                <div
                    class="collapse"
                    id="accordion-item-icon-branches"
                    data-parent="#accordionRightIcon"
                    style=""
                >
                    <div class="list-group" id="list-tab" role="tablist">
                        <a
                            v-if="
                                /* Should be based on permissions */ userType ==
                                    1
                            "
                            style="border-radius: 0;"
                            class="list-group-item list-group-item-action"
                            id="list-branches-list"
                            data-toggle="list"
                            href="#list-settings-branches"
                            role="tab"
                            aria-controls="list-settings-branches"
                            aria-selected="false"
                            @click="check_class('Manage Branches')"
                        >
                            Manage Branches
                        </a>
                        <a
                            v-if="
                                /* Should be based on permissions */ userType ==
                                    1
                            "
                            style="border-radius: 0;"
                            class="list-group-item list-group-item-action"
                            id="list-branch-status-list"
                            data-toggle="list"
                            href="#list-settings-branch-status"
                            role="tab"
                            aria-controls="list-settings-branch-status"
                            aria-selected="false"
                            @click="check_class('Branch Status')"
                        >
                            Branch Status
                        </a>
                    </div>
                </div>
            </div>
            <div
                v-if="
                    permissionIsAssigned('department-read') ||
                        permissionIsAssigned('designation-read')
                "
                class="card"
            >
                <div class="card-header header-elements-inline">
                    <span
                        style="font-weight: 100; font-size: 0.813rem;"
                        class="card-title ul-collapse__icon--size ul-collapse__right-icon mb-0"
                    >
                        <a
                            style="color: #111d5e !important;"
                            class="text-default collapsed"
                            data-toggle="collapse"
                            href="#accordion-item-icon-right-4"
                            aria-expanded="false"
                        >
                            Departments
                        </a>
                    </span>
                </div>
                <div
                    class="collapse"
                    id="accordion-item-icon-right-4"
                    data-parent="#accordionRightIcon"
                    style=""
                >
                    <div class="list-group" id="list-tab" role="tablist">
                        <a
                            v-if="permissionIsAssigned('department-read')"
                            style="border-radius: 0;"
                            class="list-group-item list-group-item-action"
                            id="list-settings-list"
                            data-toggle="list"
                            href="#list-settings"
                            role="tab"
                            aria-controls="list-settings"
                            aria-selected="false"
                            @click="check_class('Manage Departments')"
                        >
                            Manage Departments
                        </a>
                        <a
                            v-if="permissionIsAssigned('department-read')"
                            style="border-radius: 0;"
                            class="list-group-item list-group-item-action"
                            id="list-settings-committees-list"
                            data-toggle="list"
                            href="#list-settings-committees"
                            role="tab"
                            aria-controls="list-settings-committees"
                            aria-selected="false"
                            @click="check_class('Manage Committees')"
                        >
                            Manage Committees
                        </a>
                        <a
                            v-if="permissionIsAssigned('designation-read')"
                            style="border-radius: 0;"
                            class="list-group-item list-group-item-action"
                            id="list-settings-designations-list"
                            data-toggle="list"
                            href="#list-settings-designations"
                            role="tab"
                            aria-controls="list-settings-designations"
                            aria-selected="false"
                            @click="check_class('Manage Designations')"
                        >
                            Manage Designations
                        </a>
                    </div>
                </div>
            </div>
            <div v-if="userType == 1" class="card">
                <div class="card-header header-elements-inline">
                    <span
                        style="font-weight: 100; font-size: 0.813rem;"
                        class="card-title ul-collapse__icon--size ul-collapse__right-icon mb-0"
                    >
                        <a
                            style="color: #111d5e !important;"
                            class="text-default collapsed"
                            data-toggle="collapse"
                            href="#accordion-item-icon-right-46"
                            aria-expanded="false"
                        >
                            LMS
                        </a>
                    </span>
                </div>
                <div
                    class="collapse"
                    id="accordion-item-icon-right-46"
                    data-parent="#accordionRightIcon"
                    style=""
                >
                    <div class="list-group" id="list-tab" role="tablist">
                        <a
                            style="border-radius: 0;"
                            class="list-group-item list-group-item-action"
                            id="list-settings-course-categories-list"
                            data-toggle="list"
                            href="#list-settings-course-categories"
                            role="tab"
                            aria-controls="list-settings-course-categories"
                            aria-selected="false"
                            @click="check_class('Course Categories')"
                        >
                            Course Categories
                        </a>
                    </div>
                </div>
            </div>
            <div v-if="userType == 1" class="card">
                <div class="card-header header-elements-inline">
                    <span
                        style="font-weight: 100; font-size: 0.813rem;"
                        class="card-title ul-collapse__icon--size ul-collapse__right-icon mb-0"
                    >
                        <a
                            style="color: #111d5e !important;"
                            class="text-default collapsed"
                            data-toggle="collapse"
                            href="#accordion-item-icon-right-workflow"
                            aria-expanded="false"
                        >
                            Workflow
                        </a>
                    </span>
                </div>
                <div
                    class="collapse"
                    id="accordion-item-icon-right-workflow"
                    data-parent="#accordionRightIcon"
                    style=""
                >
                    <div class="list-group" id="list-tab" role="tablist">
                        <a
                            style="border-radius: 0;"
                            class="list-group-item list-group-item-action"
                            id="list-workflow-settings-list"
                            data-toggle="list"
                            href="#list-workflow-settings"
                            role="tab"
                            aria-controls="list-workflow-settings"
                            aria-selected="false"
                            @click="check_class('Workflow Settings')"
                        >
                            Workflow Settings
                        </a>
                    </div>
                </div>
            </div>
            <div
                v-if="check_all_services('Trainings') && userType == 1"
                class="card"
            >
                <div class="card-header header-elements-inline">
                    <span
                        style="font-weight: 100; font-size: 0.813rem;"
                        class="card-title ul-collapse__icon--size ul-collapse__right-icon mb-0"
                    >
                        <a
                            style="color: #111d5e !important;"
                            class="text-default collapsed"
                            data-toggle="collapse"
                            href="#accordion-item-icon-right-trainings"
                            aria-expanded="false"
                        >
                            Trainings
                        </a>
                    </span>
                </div>
                <div
                    class="collapse"
                    id="accordion-item-icon-right-trainings"
                    data-parent="#accordionRightIcon"
                    style=""
                >
                    <div class="list-group" id="list-tab" role="tablist">
                        <a
                            style="border-radius: 0;"
                            class="list-group-item list-group-item-action"
                            id="list-trainings-settings-list"
                            data-toggle="list"
                            href="#list-trainings-settings"
                            role="tab"
                            aria-controls="list-trainings-settings"
                            aria-selected="false"
                            @click="check_class('Training Providers')"
                        >
                            Training Providers
                        </a>
                    </div>
                </div>
            </div>
            <div
                v-if="check_all_services('Outsourcing') && userType == 1"
                class="card"
            >
                <div class="card-header header-elements-inline">
                    <span
                        style="font-weight: 100; font-size: 0.813rem;"
                        class="card-title ul-collapse__icon--size ul-collapse__right-icon mb-0"
                    >
                        <a
                            style="color: #111d5e !important;"
                            class="text-default collapsed"
                            data-toggle="collapse"
                            href="#accordion-item-icon-right-outsourcing-companies"
                            aria-expanded="false"
                        >
                            Outsourcing
                        </a>
                    </span>
                </div>
                <div
                    class="collapse"
                    id="accordion-item-icon-right-outsourcing-companies"
                    data-parent="#accordionRightIcon"
                    style=""
                >
                    <div class="list-group" id="list-tab" role="tablist">
                        <a
                            style="border-radius: 0;"
                            class="list-group-item list-group-item-action"
                            id="list-outsourcing-companies-list"
                            data-toggle="list"
                            href="#list-outsourcing-companies"
                            role="tab"
                            aria-controls="list-outsourcing-companies"
                            aria-selected="false"
                            @click="check_class('Manage Companies')"
                        >
                            Manage Companies
                        </a>
                    </div>
                </div>
            </div>
            <div v-if="check_all_services('File Management')" class="card">
                <div class="card-header header-elements-inline">
                    <span
                        style="font-weight: 100; font-size: 0.813rem;"
                        class="card-title ul-collapse__icon--size ul-collapse__right-icon mb-0"
                    >
                        <a
                            style="color: #111d5e !important;"
                            class="text-default collapsed"
                            data-toggle="collapse"
                            href="#accordion-item-icon-right-45"
                            aria-expanded="false"
                        >
                            File Management
                        </a>
                    </span>
                </div>
                <div
                    class="collapse"
                    id="accordion-item-icon-right-45"
                    data-parent="#accordionRightIcon"
                    style=""
                >
                    <div class="list-group" id="list-tab" role="tablist">
                        <a
                            style="border-radius: 0;"
                            class="list-group-item list-group-item-action"
                            id="list-settings-list15"
                            data-toggle="list"
                            href="#list-settings15"
                            role="tab"
                            aria-controls="list-settings15"
                            aria-selected="false"
                            @click="check_class('File Types')"
                        >
                            File Types
                        </a>
                    </div>
                </div>
            </div>
            <div
                v-if="
                    (userType == 1 ||
                        permissionIsAssigned('paycalendar-read')) &&
                        check_all_services('Payroll')
                "
                class="card"
            >
                <div class="card-header header-elements-inline">
                    <span
                        style="font-weight: 100; font-size: 0.813rem;"
                        class="card-title ul-collapse__icon--size ul-collapse__right-icon mb-0"
                    >
                        <a
                            style="color: #111d5e !important;"
                            class="text-default collapsed"
                            data-toggle="collapse"
                            href="#accordion-item-icon-right-50"
                            aria-expanded="false"
                        >
                            Payroll
                        </a>
                    </span>
                </div>
                <div
                    class="collapse"
                    id="accordion-item-icon-right-50"
                    data-parent="#accordionRightIcon"
                    style=""
                >
                    <div class="list-group" id="list-tab" role="tablist">
                        <a
                            v-if="permissionIsAssigned('payroll-approve')"
                            style="border-radius: 0;"
                            class="list-group-item list-group-item-action"
                            id="list-settings-list-payments"
                            data-toggle="list"
                            href="#list-settings-payments"
                            role="tab"
                            aria-controls="list-settings-payments"
                            aria-selected="false"
                            @click="check_class('Payment Account')"
                        >
                            Payment Account
                        </a>
                        <a
                            v-if="
                                permissionIsAssigned('payroll-read') &&
                                    permissionIsAssigned('payroll-approve')
                            "
                            style="border-radius: 0;"
                            class="list-group-item list-group-item-action"
                            id="list-settings-list500"
                            data-toggle="list"
                            href="#list-settings500"
                            role="tab"
                            aria-controls="list-settings500"
                            aria-selected="false"
                            @click="check_class('Pay Grade Management')"
                        >
                            Pay Grade Management
                        </a>
                        <a
                            v-if="
                                permissionIsAssigned('payroll-read') &&
                                    permissionIsAssigned('payroll-approve')
                            "
                            style="border-radius: 0;"
                            class="list-group-item list-group-item-action"
                            id="list-settings-list600"
                            data-toggle="list"
                            href="#list-settings600"
                            role="tab"
                            aria-controls="list-settings600"
                            aria-selected="false"
                            @click="check_class('Grade Level Management')"
                        >
                            Grade Level Management
                        </a>
                        <a
                            style="border-radius: 0;"
                            class="list-group-item list-group-item-action"
                            id="list-settings-list56"
                            data-toggle="list"
                            href="#list-settings56"
                            role="tab"
                            aria-controls="list-settings56"
                            aria-selected="false"
                            @click="check_class('Pay Items')"
                        >
                            Pay Items
                        </a>
                        <a
                            v-if="permissionIsAssigned('paycalendar-read')"
                            style="border-radius: 0;"
                            class="list-group-item list-group-item-action"
                            id="list-settings-list50"
                            data-toggle="list"
                            href="#list-settings50"
                            role="tab"
                            aria-controls="list-settings50"
                            aria-selected="false"
                            @click="check_class('Pay Calendar Management')"
                        >
                            Pay Calendar Management
                        </a>
                        <a
                            v-if="
                                permissionIsAssigned('payroll-read') &&
                                    permissionIsAssigned('payroll-approve')
                            "
                            style="border-radius: 0;"
                            class="list-group-item list-group-item-action"
                            id="list-settings-list501"
                            data-toggle="list"
                            href="#list-settings501"
                            role="tab"
                            aria-controls="list-settings501"
                            aria-selected="false"
                            @click="check_class('Salary Ratio')"
                        >
                            Salary Ratio
                        </a>
                    </div>
                </div>
            </div>
            <div
                v-if="
                    (userType == 1 ||
                        permissionIsAssigned('medicals-approve') ||
                        permissionIsAssigned('medicals-create')) &&
                        check_all_services('Medicals')
                "
                class="card"
            >
                <div class="card-header header-elements-inline">
                    <span
                        style="font-weight: 100; font-size: 0.813rem;"
                        class="card-title ul-collapse__icon--size ul-collapse__right-icon mb-0"
                    >
                        <a
                            style="color: #111d5e !important;"
                            class="text-default collapsed"
                            data-toggle="collapse"
                            href="#accordion-item-icon-right-51"
                            aria-expanded="false"
                        >
                            Medicals
                        </a>
                    </span>
                </div>
                <div
                    class="collapse"
                    id="accordion-item-icon-right-51"
                    data-parent="#accordionRightIcon"
                    style=""
                >
                    <div class="list-group" id="list-tab" role="tablist">
                        <a
                            style="border-radius: 0;"
                            class="list-group-item list-group-item-action"
                            id="list-settings-list60"
                            data-toggle="list"
                            href="#list-settings60"
                            role="tab"
                            aria-controls="list-settings60"
                            aria-selected="false"
                            @click="check_class('Hospitals Management')"
                        >
                            Hospitals Management
                        </a>
                    </div>
                </div>
            </div>
            <div v-if="userType == 1" class="card">
                <div class="card-header header-elements-inline">
                    <span
                        style="font-weight: 100; font-size: 0.813rem;"
                        class="card-title ul-collapse__icon--size ul-collapse__right-icon mb-0"
                    >
                        <a
                            style="color: #111d5e !important;"
                            class="text-default collapsed"
                            data-toggle="collapse"
                            href="#accordion-item-icon-right-41"
                            aria-expanded="false"
                        >
                            Roles &amp; Permissions
                        </a>
                    </span>
                </div>
                <div
                    class="collapse"
                    id="accordion-item-icon-right-41"
                    data-parent="#accordionRightIcon"
                    style=""
                >
                    <div class="list-group" id="list-tab" role="tablist">
                        <a
                            style="border-radius: 0;"
                            class="list-group-item list-group-item-action"
                            id="list-settings-list11"
                            data-toggle="list"
                            href="#list-settings11"
                            role="tab"
                            aria-controls="list-settings11"
                            aria-selected="false"
                            @click="check_class('User Roles')"
                        >
                            User Roles
                        </a>
                        <a
                            style="border-radius: 0;"
                            class="list-group-item list-group-item-action"
                            id="list-settings-list11-role-mgt"
                            data-toggle="list"
                            href="#list-settings11-role-mgt"
                            role="tab"
                            aria-controls="list-settings11-role-mgt"
                            aria-selected="false"
                            @click="check_class('Role Management')"
                        >
                            Roles Management
                        </a>
                    </div>
                </div>
            </div>
            <div v-if="userType == 1" class="card">
                <div class="card-header header-elements-inline">
                    <span
                        style="font-weight: 100; font-size: 0.813rem;"
                        class="card-title ul-collapse__icon--size ul-collapse__right-icon mb-0"
                    >
                        <a
                            style="color: #111d5e !important;"
                            class="text-default collapsed"
                            data-toggle="collapse"
                            href="#accordion-item-icon-right-logs"
                            aria-expanded="false"
                        >
                            Logs
                        </a>
                    </span>
                </div>
                <div
                    class="collapse"
                    id="accordion-item-icon-right-logs"
                    data-parent="#accordionRightIcon"
                    style=""
                >
                    <div class="list-group" id="list-tab" role="tablist">
                        <a
                            style="border-radius: 0;"
                            class="list-group-item list-group-item-action"
                            id="list-settings-list-logs"
                            data-toggle="list"
                            href="#list-settings-logs"
                            role="tab"
                            aria-controls="list-settings-logs"
                            aria-selected="false"
                            @click="check_class('Audit Trail')"
                        >
                            Audit Trail
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    export default {
        computed: {
            ...mapGetters(['userType']),
        },
        methods: {
            check_class(innerHTML) {
                document
                    .querySelectorAll('.list-group-item-action')
                    .forEach((el) => {
                        if (el.innerHTML.indexOf(innerHTML) == -1) {
                            el.classList.remove('active');
                        }
                    });
            },
        },
    };
</script>

<style></style>
