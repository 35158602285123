var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
            _vm.userType == 1 ||
                (_vm.userType != 1 &&
                    !_vm.employeeView &&
                    _vm.permissionIsAssigned('employee-read'))
        )?_c('div',{staticClass:"main-content-wrap sidenav-open d-flex flex-column"},[_c('div',{staticClass:"main-header pl-4",class:_vm.$vuetify.breakpoint.name == 'xs' ||
                _vm.$vuetify.breakpoint.name == 'sm'
                    ? 'pr-1'
                    : 'pr-5',staticStyle:{"z-index":"60"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"logo col-md-1 col-lg-1 col-12 py-0",staticStyle:{"align-self":"center"}},[_c('div',{staticClass:"dropdown animate__animated animate__fadeIn",staticStyle:{"font-size":"120%"}},[_c('i',{staticClass:"fal fa-arrow-alt-circle-left mr-1",staticStyle:{"cursor":"pointer","position":"relative","top":"2px","color":"#069"},on:{"click":function($event){_vm.hasHistory()
                                    ? _vm.$router.go(-1)
                                    : _vm.$router.push('/')}}}),_c('span',{staticClass:"ml-2",staticStyle:{"top":"4%","position":"relative"}},[_vm._v("Back")])])]),(
                        _vm.userType != 1 &&
                            _vm.permissionIsAssigned('employee-read')
                    )?_c('div',{staticClass:"input-group-prepend"},[_c('button',{staticClass:"btn dropdown-toggle w-100",attrs:{"type":"button","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_c('span',{style:(_vm.$vuetify.breakpoint.name == 'xs'
                                    ? 'margin-left: 1.5rem;'
                                    : _vm.$vuetify.breakpoint.name == 'sm'
                                    ? 'margin-left: 5rem;'
                                    : null)},[_vm._v(_vm._s(_vm.employeeView ? 'Your Exit Requests' : 'All Exit Requests'))])]),(_vm.permissionIsAssigned('employee-read'))?_c('div',{staticClass:"dropdown-menu",staticStyle:{"min-width":"13rem"}},[_c('a',{staticClass:"dropdown-item",staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.employeeView = !_vm.employeeView}}},[_vm._v(" "+_vm._s(_vm.employeeView ? 'All Exit Requests' : 'Your Exit Requests')+" ")])]):_vm._e()]):_vm._e()]),_c('div',{staticStyle:{"margin":"auto"}}),_c('div',{staticClass:"header-part-right",class:_vm.$vuetify.breakpoint.name == 'xs' ||
                    _vm.$vuetify.breakpoint.name == 'sm'
                        ? null
                        : 'pr-5'},[(
                        _vm.userType == 1 ||
                            (_vm.userType != 1 &&
                                !_vm.employeeView &&
                                _vm.permissionIsAssigned('employee-read'))
                    )?_c('div',{staticClass:"dropdown"},[_c('button',{staticClass:"btn btn-outline-secondary m-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.push({ name: 'BillingHistory' })}}},[_vm._v(" Billing History ")])]):_vm._e(),_c('div',{staticClass:"dropdown"},[(_vm.userType != '1')?_c('button',{staticClass:"btn btn-outline-secondary m-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.push({ name: 'LogReport' })}}},[_vm._v(" Log Time ")]):_vm._e(),(_vm.userType == '1')?_c('button',{staticClass:"btn btn-outline-secondary m-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.push({ name: 'Attendance' })}}},[_vm._v(" Log History ")]):_vm._e()])])]),_c('div',{staticClass:"main-content pt-0",class:_vm.$vuetify.breakpoint.name == 'xs' ||
                _vm.$vuetify.breakpoint.name == 'sm'
                    ? 'px-3'
                    : null},[_c('div',{staticClass:"breadcrumb",staticStyle:{"margin-top":"1rem"}},[_c('div',{staticClass:"row",style:(_vm.$vuetify.breakpoint.name == 'xs'
                            ? 'width: 110%;'
                            : 'width: 80%;')},[_c('h1',{staticClass:"my-2 row pb-0"},[(
                                _vm.employee_name.toLowerCase() !=
                                    'all exit requests'
                            )?_c('span',{staticClass:"col-lg-5 col-md-5 col-12"},[_vm._v("Exit Requests for "+_vm._s(_vm.employee_name))]):_c('span',{staticClass:"col-lg-5 col-md-5 col-12"},[_vm._v("All Exit Requests")]),_c('v-row',{staticClass:"col-md-7 col-lg-7 col-12",staticStyle:{"float":"right"}},[_c('v-col',{staticClass:"pt-0 text-center",attrs:{"offset-lg":_vm.employee_name.toLowerCase() !=
                                    'all exit requests'
                                        ? null
                                        : '1',"offset-md":_vm.employee_name.toLowerCase() !=
                                    'all exit requests'
                                        ? null
                                        : '1',"cols":"6","lg":"3","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                        var on = ref.on;
                                        var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"color":"#069","prepend-inner-icon":"mdi-calendar-month-outline","placeholder":"Select dates","solo":"","dense":"","readonly":"","messages":_vm.date_range.length == 0
                                                    ? '*Select date range'
                                                    : _vm.date_range.length == 1
                                                    ? '*Please select an end date'
                                                    : '*Showing results for ' +
                                                      new Date(
                                                          _vm.date_range[0]
                                                      )
                                                          .toUTCString()
                                                          .substring(
                                                              0,
                                                              16
                                                          ) +
                                                      ' to ' +
                                                      new Date(
                                                          _vm.date_range[1]
                                                      )
                                                          .toUTCString()
                                                          .substring(0, 16)},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}],null,false,2435895871)},[_c('v-date-picker',{attrs:{"color":"#069","range":"","min":new Date(
                                                new Date().getTime() -
                                                    3.156e10
                                            )
                                                .toISOString()
                                                .substring(0, 10),"max":new Date(
                                                new Date().getTime() +
                                                    86400000000
                                            )
                                                .toISOString()
                                                .substring(0, 10)},model:{value:(_vm.date_range),callback:function ($$v) {_vm.date_range=$$v},expression:"date_range"}})],1)],1),_c('v-col',{staticClass:"pt-0 text-right",attrs:{"cols":"6","lg":"4","md":"4"}},[_c('v-combobox',{staticClass:"remove-mb",attrs:{"items":_vm.allEmployees,"label":"All Travels","dense":"","solo":"","messages":"*Select Employees","full-width":true},model:{value:(_vm.employee_name),callback:function ($$v) {_vm.employee_name=$$v},expression:"employee_name"}})],1),(
                                    _vm.userType == 1 ||
                                        (_vm.userType != 1 &&
                                            !_vm.employeeView &&
                                            _vm.permissionIsAssigned(
                                                'employee-read'
                                            ))
                                )?_c('v-col',{staticClass:"pt-0",class:_vm.$vuetify.breakpoint.name == 'xs' ||
                                    _vm.$vuetify.breakpoint.name == 'sm'
                                        ? 'text-left'
                                        : 'text-right',attrs:{"cols":"6","lg":"3","xl":"3","md":"3"}},[(
                                        _vm.excel_filtered_exit_requests.length >=
                                            1
                                    )?_c('download-excel',{staticClass:"btn btn-raised ripple btn-raised-secondary m-1 text-white",class:_vm.$vuetify.breakpoint.name == 'xs' ||
                                        _vm.$vuetify.breakpoint.name == 'sm'
                                            ? 'w-100'
                                            : null,attrs:{"data":_vm.json_data,"fields":_vm.json_fields,"worksheet":_vm.employee_name.toLowerCase() !=
                                        'all exit requests'
                                            ? _vm.employee_name
                                            : 'All Employees',"name":'Exit Requests for ' +
                                            (_vm.employee_name.toLowerCase() !=
                                            'all exit requests'
                                                ? _vm.employee_name
                                                : 'All Employees') +
                                            '.xls'}},[_vm._v(" Export to Excel ")]):_c('button',{staticClass:"btn btn-raised ripple btn-raised-secondary m-1 text-white",class:_vm.$vuetify.breakpoint.name == 'xs' ||
                                        _vm.$vuetify.breakpoint.name == 'sm'
                                            ? 'w-100'
                                            : null,staticStyle:{"cursor":"disabled","opacity":"0.5"}},[_vm._v(" Export to Excel ")])],1):_vm._e(),(
                                    (_vm.employee_name.toLowerCase() !=
                                        'all exit requests' &&
                                        _vm.userType == 1) ||
                                        (_vm.userType != 1 &&
                                            !_vm.employeeView &&
                                            _vm.permissionIsAssigned(
                                                'employee-read'
                                            ))
                                )?_c('v-col',{staticClass:"pt-0",attrs:{"cols":"6","lg":"1","xl":"1","md":"1"}},[_c('button',{staticClass:"btn btn-raised ripple btn-raised-secondary m-1 text-white",class:_vm.$vuetify.breakpoint.name == 'xs' ||
                                        _vm.$vuetify.breakpoint.name == 'sm'
                                            ? 'w-100'
                                            : null,on:{"click":function($event){return _vm.reset_filter()}}},[_vm._v(" Reset ")])]):_vm._e()],1)],1),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card",staticStyle:{"border-radius":"10px"}},[_c('div',{staticClass:"card-body px-1 py-1 pt-0 pr-0"},[_c('div',{staticClass:"table-responsive",staticStyle:{"border-radius":"10px","height":"75vh","overflow-y":"scroll","padding-top":"0","padding-bottom":"0"}},[_c('table',{staticClass:"table table-hover"},[_vm._m(0),_c('tbody',_vm._l((_vm.filtered_exit_requests),function(exitRequest){return _c('tr',{key:exitRequest.id},[_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.get_employee_name( exitRequest.employee_id ))+" ")]),_c('td',{staticClass:"text-left",attrs:{"scope":"row"}},[_vm._v(" "+_vm._s(exitRequest.reason)+" ")]),_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(new Date( exitRequest.created_at ) .toUTCString() .substring( 0, 16 ))+" ")]),(
                                                        exitRequest.status ==
                                                            0
                                                    )?_c('td',{staticClass:"text-left"},[_c('span',{staticClass:"badge badge-warning text-center text-white",staticStyle:{"width":"60px"}},[_vm._v("PENDING")])]):(
                                                        exitRequest.status ==
                                                            1
                                                    )?_c('td',{staticClass:"text-left"},[_c('span',{staticClass:"badge text-center badge-success",staticStyle:{"width":"60px"}},[_vm._v("APPROVED")])]):_c('td',{staticClass:"text-left"},[_c('span',{staticClass:"badge text-center badge-danger",staticStyle:{"width":"60px"}},[_vm._v("REJECTED")])]),_c('td',{staticClass:"employee-options",staticStyle:{"padding-top":"25px"}},[_c('div',{staticClass:"row"},[(
                                                                _vm.permissionIsAssigned(
                                                                    'travel-approve'
                                                                )
                                                            )?_c('i',{staticClass:"col-2 py-0 text-left fal fa-check-circle",staticStyle:{"color":"#069 !important","font-size":"125%","cursor":"pointer"},on:{"click":function($event){return _vm.approveExitRequest(
                                                                    exitRequest
                                                                )}}}):_vm._e(),(
                                                                _vm.permissionIsAssigned(
                                                                    'travel-approve'
                                                                )
                                                            )?_c('i',{staticClass:"col-2 py-0 text-left fal fa-times-circle",staticStyle:{"color":"#069 !important","font-size":"125%","cursor":"pointer"},on:{"click":function($event){return _vm.disapproveExitRequest(
                                                                    exitRequest
                                                                )}}}):_vm._e()])])])}),0)])])])])])])]),_c('div',{staticClass:"row"})]),_c('div',{staticClass:"flex-grow-1"})]):_c('div',{staticClass:"main-content-wrap sidenav-open d-flex flex-column"},[_c('div',{staticClass:"main-header pl-4",class:_vm.$vuetify.breakpoint.name == 'xs' ||
                _vm.$vuetify.breakpoint.name == 'sm'
                    ? 'pr-1'
                    : 'pr-5',staticStyle:{"z-index":"60"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"logo col-md-1 col-lg-1 col-12 py-0",staticStyle:{"align-self":"center"}},[_c('div',{staticClass:"dropdown animate__animated animate__fadeIn",staticStyle:{"font-size":"120%"}},[_c('i',{staticClass:"fal fa-arrow-alt-circle-left mr-1",staticStyle:{"cursor":"pointer","position":"relative","top":"2px","color":"#069"},on:{"click":function($event){_vm.hasHistory()
                                    ? _vm.$router.go(-1)
                                    : _vm.$router.push('/')}}}),_c('span',{staticClass:"ml-2",staticStyle:{"top":"4%","position":"relative"}},[_vm._v("Back")])])]),(
                        _vm.userType != 1 &&
                            _vm.permissionIsAssigned('employee-read')
                    )?_c('div',{staticClass:"input-group-prepend"},[_c('button',{staticClass:"btn dropdown-toggle w-100",attrs:{"type":"button","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_c('span',{style:(_vm.$vuetify.breakpoint.name == 'xs'
                                    ? 'margin-left: 1.5rem;'
                                    : _vm.$vuetify.breakpoint.name == 'sm'
                                    ? 'margin-left: 5rem;'
                                    : null)},[_vm._v(_vm._s(_vm.employeeView ? 'Your Exit Requests' : 'All Exit Requests'))])]),(_vm.permissionIsAssigned('employee-read'))?_c('div',{staticClass:"dropdown-menu",staticStyle:{"min-width":"13rem"}},[_c('a',{staticClass:"dropdown-item",staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.employeeView = !_vm.employeeView}}},[_vm._v(" "+_vm._s(_vm.employeeView ? 'All Exit Requests' : 'Your Exit Requests')+" ")])]):_vm._e()]):_vm._e()]),_c('div',{staticStyle:{"margin":"auto"}}),_c('div',{staticClass:"header-part-right pr-lg-5 pr-md-5"},[(_vm.userType == 1)?_c('div',{staticClass:"dropdown"},[_c('button',{staticClass:"btn btn-outline-secondary m-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.push({ name: 'BillingHistory' })}}},[_vm._v(" Billing History ")])]):_vm._e(),_c('div',{staticClass:"dropdown"},[(_vm.userType != 1)?_c('button',{staticClass:"btn btn-outline-secondary m-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.push({ name: 'LogReport' })}}},[_vm._v(" Log Time ")]):_vm._e(),(_vm.userType == '1')?_c('button',{staticClass:"btn btn-outline-secondary m-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.push({ name: 'Attendance' })}}},[_vm._v(" Log History ")]):_vm._e()])])]),_c('div',{staticClass:"main-content pt-0",class:_vm.$vuetify.breakpoint.name == 'xs' ||
                _vm.$vuetify.breakpoint.name == 'sm'
                    ? 'px-2'
                    : null},[_c('div',{staticClass:"breadcrumb",staticStyle:{"margin-top":"1rem"}},[_c('div',{staticClass:"row",style:(_vm.$vuetify.breakpoint.name == 'xs'
                            ? 'width: 110%;'
                            : 'width: 80%;')},[_c('h1',{staticClass:"my-2 row pb-0"},[_c('span',{staticClass:"col-lg-5 col-md-5 col-12"},[_vm._v("Your Exit Requests")]),_c('v-row',{staticClass:"col-md-7 col-lg-7 col-12"},[_c('v-col',{staticClass:"pt-0 pr-0",class:_vm.$vuetify.breakpoint.name == 'xs' ||
                                    _vm.$vuetify.breakpoint.name == 'sm'
                                        ? 'text-left'
                                        : 'text-right',attrs:{"cols":"5","lg":"4","md":"4"}}),_c('v-col',{staticClass:"pt-0 pr-0",class:_vm.$vuetify.breakpoint.name == 'xs' ||
                                    _vm.$vuetify.breakpoint.name == 'sm'
                                        ? 'text-left'
                                        : 'text-right',attrs:{"cols":"7","lg":"4","md":"4"}}),_c('v-col',{staticClass:"text-right pt-0",attrs:{"cols":"6","lg":"4","md":"4"}},[_c('button',{staticClass:"btn btn-raised ripple btn-raised-secondary m-1 mt-0 text-white",attrs:{"type":"button"},on:{"click":function($event){_vm.initiateExitRequest = true}}},[_vm._v(" Create Exit Request ")])])],1)],1),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card",staticStyle:{"border-radius":"10px"}},[_c('div',{staticClass:"card-body px-1 py-1 pt-0 pr-0"},[_c('div',{staticClass:"table-responsive",staticStyle:{"border-radius":"10px","height":"75vh","overflow-y":"scroll","padding-top":"0","padding-bottom":"0"}},[_c('table',{staticClass:"table table-hover"},[_vm._m(1),_c('tbody',_vm._l((_vm.filtered_exit_requests),function(exitRequest){return _c('tr',{key:exitRequest.id},[_c('td',{staticClass:"text-left",attrs:{"scope":"row"}},[_vm._v(" "+_vm._s(exitRequest.reason)+" ")]),_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(new Date( exitRequest.created_at ) .toUTCString() .substring( 0, 16 ))+" ")]),(
                                                        exitRequest.status ==
                                                            0
                                                    )?_c('td',{staticClass:"text-left"},[_c('span',{staticClass:"badge badge-warning text-center text-white",staticStyle:{"width":"60px"}},[_vm._v("PENDING")])]):(
                                                        exitRequest.status ==
                                                            1
                                                    )?_c('td',{staticClass:"text-left"},[_c('span',{staticClass:"badge text-center badge-success",staticStyle:{"width":"60px"}},[_vm._v("APPROVED")])]):_c('td',{staticClass:"text-left"},[_c('span',{staticClass:"badge text-center badge-danger",staticStyle:{"width":"60px"}},[_vm._v("REJECTED")])]),_c('td',{staticClass:"employee-options",staticStyle:{"padding-top":"25px"}},[_c('div',{staticClass:"row"},[(
                                                                exitRequest.status ==
                                                                    1
                                                            )?_c('i',{staticClass:"col-3 py-0 text-center fal fa-edit",staticStyle:{"color":"#069 !important","font-size":"125%","opacity":"0.3","cursor":"default"}}):_vm._e(),(
                                                                exitRequest.status ==
                                                                    1
                                                            )?_c('i',{staticClass:"col-3 py-0 text-center fal fa-trash-alt",staticStyle:{"color":"#069 !important","font-size":"125%","opacity":"0.3","cursor":"default"}}):_c('i',{staticClass:"col-3 py-0 text-center fal fa-trash-alt",staticStyle:{"color":"#069 !important","font-size":"125%","cursor":"pointer"},on:{"click":function($event){return _vm.deleteExitRequest(
                                                                    exitRequest
                                                                )}}})])])])}),0)])])])])])])]),_c('div',{staticClass:"row"})]),_c('div',{staticClass:"flex-grow-1"})]),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.editExitRequest),callback:function ($$v) {_vm.editExitRequest=$$v},expression:"editExitRequest"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Edit Exit Request")])]),_c('v-card-text',[_c('v-container',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"container-fluid"},[_c('v-row',[_c('v-col',{staticClass:"remove-hint",attrs:{"cols":"12"}},[_c('span',{staticStyle:{"color":"#111d5e"}},[_vm._v("Exit Reason "),_c('i',{staticClass:"fas fa-asterisk",staticStyle:{"font-size":"8px","color":"#f00","position":"relative","bottom":"5px"}})]),_c('v-text-field',{staticClass:"mt-2",attrs:{"placeholder":"Enter exit reason","solo":"","dense":""},model:{value:(_vm.editFormData.reason),callback:function ($$v) {_vm.$set(_vm.editFormData, "reason", $$v)},expression:"editFormData.reason"}})],1),_c('v-col',{staticClass:"remove-hint",attrs:{"cols":"12","sm":"4","md":"4","offset-md":"4","offset-sm":"4"}},[_c('button',{staticClass:"btn btn-secondary m-1 text-white btn-raised ripple",staticStyle:{"width":"99%"},attrs:{"type":"button"},on:{"click":function($event){_vm.exitRequestUpdating
                                                ? null
                                                : _vm.updateExitRequest()}}},[_vm._v(" "+_vm._s(_vm.exitRequestUpdateText)+" "),_c('v-icon',{style:(!_vm.exitRequestUpdating
                                                    ? 'display: none;'
                                                    : 'color: #fff; padding: 0px 10px; font-size: 16px;')},[_vm._v(" fal fa-circle-notch fa-spin ")])],1)])],1)],1)])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.editExitRequest = false}}},[_vm._v(" Close ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.initiateExitRequest),callback:function ($$v) {_vm.initiateExitRequest=$$v},expression:"initiateExitRequest"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Create Exit Request")])]),_c('v-card-text',[_c('v-container',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"container-fluid"},[_c('v-row',[_c('v-col',{staticClass:"remove-hint",attrs:{"cols":"12"}},[_c('span',{staticStyle:{"color":"#111d5e"}},[_vm._v("Exit Reason "),_c('i',{staticClass:"fas fa-asterisk",staticStyle:{"font-size":"8px","color":"#f00","position":"relative","bottom":"5px"}})]),_c('v-text-field',{staticClass:"mt-2",attrs:{"placeholder":"Enter exit reason","solo":"","dense":""},model:{value:(_vm.formData.reason),callback:function ($$v) {_vm.$set(_vm.formData, "reason", $$v)},expression:"formData.reason"}})],1),_c('v-col',{staticClass:"remove-hint",attrs:{"cols":"12","sm":"4","md":"4","offset-md":"4","offset-sm":"4"}},[_c('button',{staticClass:"btn btn-secondary m-1 text-white btn-raised ripple",staticStyle:{"width":"99%"},attrs:{"type":"button"},on:{"click":function($event){_vm.exitRequestCreating
                                                ? null
                                                : _vm.createExitRequest()}}},[_vm._v(" "+_vm._s(_vm.exitRequestCreateText)+" "),_c('v-icon',{style:(!_vm.exitRequestCreating
                                                    ? 'display: none;'
                                                    : 'color: #fff; padding: 0px 10px; font-size: 16px;')},[_vm._v(" fal fa-circle-notch fa-spin ")])],1)])],1)],1)])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.initiateExitRequest = false}}},[_vm._v(" Close ")])],1)],1)],1),_c('div',{staticClass:"flex-grow-1"}),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.commentDialog),callback:function ($$v) {_vm.commentDialog=$$v},expression:"commentDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.approval ? 'Approve' : 'Disapprove')+" Exit Request with Comment")])]),_c('v-card-text',[_c('v-container',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"container-fluid"},[_c('v-row',[_c('v-col',{staticClass:"remove-hint",attrs:{"cols":"12"}},[_c('span',{staticStyle:{"color":"#111d5e"}},[_vm._v("Comment "),_c('i',{staticClass:"fas fa-asterisk",staticStyle:{"font-size":"8px","color":"#f00","position":"relative","bottom":"5px"}})]),_c('v-textarea',{staticClass:"mt-2",attrs:{"placeholder":_vm.userType == 1 ||
                                            (_vm.userType != 1 &&
                                                !_vm.employeeView &&
                                                _vm.permissionIsAssigned(
                                                    'employee-read'
                                                ))
                                                ? 'Comment'
                                                : 'No comment',"solo":"","disabled":_vm.userType != 1 ? true : false},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1),(
                                        _vm.userType == 1 ||
                                            (_vm.userType != 1 &&
                                                !_vm.employeeView &&
                                                _vm.permissionIsAssigned(
                                                    'employee-read'
                                                ))
                                    )?_c('v-col',{staticClass:"remove-hint",attrs:{"cols":"12","sm":"4","md":"4","offset-md":"4","offset-sm":"4"}},[_c('button',{staticClass:"btn btn-secondary m-1 text-white btn-raised ripple",staticStyle:{"width":"99%"},attrs:{"type":"button"},on:{"click":function($event){_vm.commentCreating
                                                ? null
                                                : _vm.exitRequestAction()}}},[_vm._v(" "+_vm._s(_vm.commentText)+" "),_c('v-icon',{style:(!_vm.commentCreating
                                                    ? 'display: none;'
                                                    : 'color: #fff; padding: 0px 10px; font-size: 16px;')},[_vm._v(" fal fa-circle-notch fa-spin ")])],1)]):_vm._e()],1)],1)])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.commentDialog = false}}},[_vm._v(" Close ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.allCommentsDialog),callback:function ($$v) {_vm.allCommentsDialog=$$v},expression:"allCommentsDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Comments")])]),_c('v-card-text',[_c('v-container',[_c('table',{staticClass:"table table-hover"},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Date Sent")]),_c('th',{staticClass:"text-left"},[_vm._v("Comment")])])]),_c('tbody',_vm._l((_vm.allComments),function(comment){return _c('tr',{key:comment.id},[_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(new Date(comment.created_at) .toUTCString() .substring(0, 25))+" ")]),_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(comment.comments)+" ")])])}),0)])])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.allCommentsDialog = false}}},[_vm._v(" Close ")])],1)],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',{staticStyle:{"border":"2px solid #4440"}},[_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Employee ")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Reason ")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Application Date ")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Status ")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Actions ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',{staticStyle:{"border":"2px solid #4440"}},[_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Reason ")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Application Date ")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Status ")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Actions ")])])])}]

export { render, staticRenderFns }