<template>
    <div>
        <!-- <SideMenu /> -->
        <div
            class="main-content-wrap sidenav-open d-flex flex-column pb-4"
            style="margin-top:60px;"
        >
            <div class="main-header pl-4 mt-3" style="z-index: 60;">
                <div class="row">
                    <div
                        style="align-self: center;"
                        class="logo col-md-1 col-lg-1 col-12 py-0"
                    >
                        <div
                            class="dropdown animate__animated animate__fadeIn"
                            style="font-size: 120%;"
                        >
                            <i
                                @click="
                                    hasHistory()
                                        ? $router.go(-1)
                                        : $router.push('/')
                                "
                                style="cursor: pointer; position: relative; top: 2px; color: #069;"
                                class="fal fa-arrow-alt-circle-left mr-1"
                            ></i>
                            <span
                                class="ml-2"
                                style="top: 4%; position: relative;"
                                >Back</span
                            >
                        </div>
                    </div>
                </div>
                <div style="margin: auto"></div>
                <div
                    class="header-part-right"
                    :class="
                        $vuetify.breakpoint.name == 'xs' ||
                        $vuetify.breakpoint.name == 'sm'
                            ? null
                            : 'pr-5'
                    "
                >
                    <div
                        v-if="
                            userType ==
                                1 /* || permissionIsAssigned('outsourcing-create')*/
                        "
                        class="dropdown animate__animated animate__fadeIn"
                    >
                        <template v-if="create_outsourcing != 2">
                            <button
                                v-if="
                                    userType ==
                                        1 /* || permissionIsAssigned('outsourcing-create')*/
                                "
                                @click="create_outsourcing = 2"
                                style="float: right;"
                                type="button"
                                class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple"
                            >
                                View Outsourcings
                            </button>
                        </template>
                    </div>
                    <!-- Notificaiton End -->
                    <!-- User avatar dropdown -->
                </div>
            </div>
            <!-- ======== Body content start ========= -->
            <div
                v-if="create_outsourcing == 1"
                class="main-content"
                :class="
                    $vuetify.breakpoint.name == 'xs' ||
                    $vuetify.breakpoint.name == 'sm'
                        ? 'px-2'
                        : null
                "
            >
                <div
                    class="breadcrumb mb-0"
                    :style="
                        $vuetify.breakpoint.name == 'xs' ||
                        $vuetify.breakpoint.name == 'sm'
                            ? null
                            : null
                    "
                    style="margin-top: 1rem;"
                >
                    <h1 class="my-2 row pb-0">
                        <span class="col-lg-5 col-md-5 col-12"
                            >Create Outsourcing</span
                        >
                    </h1>
                </div>
                <v-form @submit.prevent>
                    <div class="container-fluid">
                        <v-row>
                            <v-col cols="12" sm="6" md="6" class="remove-hint">
                                <span style="color: #111d5e;"
                                    >Company
                                    <i
                                        class="fal fa-asterisk"
                                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                                    ></i
                                ></span>
                                <v-select
                                    v-model="formData.organization_id"
                                    item-color="#069"
                                    item-text="organization_name"
                                    item-value="id"
                                    :items="authCompanyOutsourcingCompanies"
                                    placeholder="Select the company"
                                    solo
                                    class="mt-2"
                                    dense
                                    color="#069"
                                ></v-select>
                            </v-col>

                            <v-col cols="12" sm="6" md="6" class="remove-hint">
                                <span style="color: #111d5e;"
                                    >Employee
                                    <i
                                        class="fas fa-asterisk"
                                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                                    ></i
                                ></span>
                                <v-combobox
                                    v-model="formData.employee_id"
                                    :items="allEmployees"
                                    :menu-props="{ maxHeight: '400' }"
                                    label="Select"
                                    solo
                                    dense
                                    item-value="id"
                                    item-text="name"
                                    hint="Select Employee"
                                    persistent-hint
                                ></v-combobox>
                            </v-col>

                            <v-col cols="12" sm="6" md="6" class="remove-hint">
                                <span style="color: #111d5e;"
                                    >Outsourcing Type
                                    <i
                                        class="fal fa-asterisk"
                                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                                    ></i
                                ></span>
                                <v-select
                                    v-model="formData.outsourcing_type"
                                    item-color="#069"
                                    :items="[
                                        'Contract',
                                        'Permanent',
                                        'Freelancing',
                                    ]"
                                    placeholder="Select the outsourcing type"
                                    solo
                                    class="mt-2"
                                    dense
                                    color="#069"
                                ></v-select>
                            </v-col>

                            <v-col cols="12">
                                <span style="color: #111d5e;"
                                    >Outsourcing Description
                                    <i
                                        class="fas fa-asterisk"
                                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                                    ></i
                                ></span>
                                <v-textarea
                                    v-model="formData.description"
                                    placeholder="Enter the outsourcing description"
                                    solo
                                    class="mt-2"
                                >
                                </v-textarea>
                            </v-col>

                            <v-col cols="12" sm="6" md="6" class="remove-hint">
                                <span style="color: #111d5e;"
                                    >Start Date
                                    <i
                                        class="fas fa-asterisk"
                                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                                    ></i
                                ></span>
                                <v-menu
                                    ref="fromDateMenuForCreation"
                                    v-model="fromDateMenuForCreation"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            class="mt-1"
                                            color="#069"
                                            prepend-inner-icon="mdi-calendar-month-outline"
                                            v-model="formData.from_date"
                                            placeholder="Start Date"
                                            dense
                                            solo
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        color="#069"
                                        ref="picker"
                                        :min="
                                            new Date().getFullYear().toString()
                                        "
                                        v-model="formData.from_date"
                                        @change="saveFromDateForCreation"
                                    >
                                    </v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col cols="12" sm="6" md="6" class="remove-hint">
                                <span style="color: #111d5e;"
                                    >End Date
                                    <i
                                        class="fas fa-asterisk"
                                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                                    ></i
                                ></span>
                                <v-menu
                                    ref="toDateMenuForCreation"
                                    v-model="toDateMenuForCreation"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            class="mt-1"
                                            color="#069"
                                            prepend-inner-icon="mdi-calendar-month-outline"
                                            v-model="formData.to_date"
                                            placeholder="End Date"
                                            dense
                                            solo
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        color="#069"
                                        ref="picker"
                                        :min="
                                            new Date().getFullYear().toString()
                                        "
                                        v-model="formData.to_date"
                                        @change="saveToDateForCreation"
                                    >
                                    </v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col cols="12" class="remove-hint text-center">
                                <button
                                    @click="
                                        create_outsourcing_submit
                                            ? null
                                            : add_employees_to_outsourcing()
                                    "
                                    type="button"
                                    class="btn btn-secondary m-1 ripple w-25"
                                >
                                    {{ create_outsourcing_text }}
                                    <v-icon
                                        :style="
                                            !create_outsourcing_submit
                                                ? 'display: none;'
                                                : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                                        "
                                    >
                                        fas fa-circle-notch fa-spin
                                    </v-icon>
                                </button>
                                <button
                                    @click="create_outsourcing = 2"
                                    type="button"
                                    class="btn btn-outline-secondary m-1"
                                >
                                    Cancel
                                </button>
                            </v-col>
                        </v-row>
                    </div>
                </v-form>
            </div>
            <div
                v-else-if="create_outsourcing == 2"
                class="main-content"
                :class="
                    $vuetify.breakpoint.name == 'xs' ||
                    $vuetify.breakpoint.name == 'sm'
                        ? 'px-2'
                        : null
                "
            >
                <div
                    class="breadcrumb mb-0"
                    :style="
                        $vuetify.breakpoint.name == 'xs' ||
                        $vuetify.breakpoint.name == 'sm'
                            ? null
                            : null
                    "
                    style="margin-top: 1rem;"
                >
                    <div class="row pb-0 my-2">
                        <div class="col-12 col-md-6">
                            <h1>All Outsourcings</h1>
                        </div>
                        <div class="col-12 col-md-6">
                            <v-row>
                                <v-col
                                    cols="6"
                                    md="3"
                                    offset-md="7"
                                    class="pt-0 text-center"
                                >
                                    <v-menu
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-text-field
                                                color="#069"
                                                prepend-inner-icon="mdi-calendar-month-outline"
                                                v-model="dateRangeText"
                                                placeholder="Select dates"
                                                solo
                                                dense
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                :messages="
                                                    date_range.length == 0
                                                        ? '*Select date range'
                                                        : date_range.length == 1
                                                        ? '*Please select an end date'
                                                        : '*Showing results for ' +
                                                          new Date(
                                                              date_range[0]
                                                          )
                                                              .toUTCString()
                                                              .substring(
                                                                  0,
                                                                  16
                                                              ) +
                                                          ' to ' +
                                                          new Date(
                                                              date_range[1]
                                                          )
                                                              .toUTCString()
                                                              .substring(0, 16)
                                                "
                                            >
                                            </v-text-field>
                                        </template>
                                        <v-date-picker
                                            color="#069"
                                            range
                                            v-model="date_range"
                                            :min="
                                                new Date(
                                                    new Date().getTime() -
                                                        3.156e10
                                                )
                                                    .toISOString()
                                                    .substring(0, 10)
                                            "
                                            :max="
                                                new Date(
                                                    new Date().getTime() +
                                                        86400000000
                                                )
                                                    .toISOString()
                                                    .substring(0, 10)
                                            "
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="6" md="2" class="pt-0 text-center">
                                    <div class="dropdown">
                                        <button
                                            class="btn btn-secondary dropdown-toggle"
                                            type="button"
                                            data-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            Actions
                                        </button>
                                        <div
                                            class="dropdown-menu"
                                            x-placement="bottom-start"
                                            style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 34px, 0px);"
                                        >
                                            <a
                                                v-if="
                                                    userType ==
                                                        1 /* || permissionIsAssigned('outsourcing-create')*/
                                                "
                                                @click="tweak_display()"
                                                class="dropdown-item"
                                                href="#"
                                            >
                                                {{
                                                    create_outsourcing == 1
                                                        ? 'View Outsourcings'
                                                        : create_outsourcing ==
                                                          2
                                                        ? 'Create Outsourcing'
                                                        : 'View Outsourcings'
                                                }}
                                            </a>
                                            <a
                                                v-if="userType == 1"
                                                @click="print()"
                                                class="dropdown-item"
                                                href="#"
                                                >Export to PDF</a
                                            >
                                            <a
                                                v-if="userType == 1"
                                                class="dropdown-item"
                                                href="#"
                                            >
                                                <download-excel
                                                    :data="json_data"
                                                    :fields="json_fields"
                                                    worksheet="All Outsourcings"
                                                    name="Outsourcings.xls"
                                                >
                                                    Export to Excel
                                                </download-excel>
                                            </a>
                                            <a
                                                @click="reset_filter()"
                                                href="#"
                                                class="dropdown-item"
                                                >Reset Filter</a
                                            >
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </div>
                <div
                    class="main-content mt-0 row pr-0"
                    :class="
                        $vuetify.breakpoint.name == 'xs' ||
                        $vuetify.breakpoint.name == 'sm'
                            ? 'px-2'
                            : null
                    "
                >
                    <div
                        id="printMe"
                        class="card w-100"
                        style="border-radius: 10px;"
                    >
                        <div class="card-header px-3 d-print-block d-none">
                            <h4 v-if="excel_filtered_trainings.length >= 1">
                                Outsourcings
                            </h4>
                        </div>
                        <div class="card-body px-1 py-1 pt-0 pr-0">
                            <div class="table-responsive table-scroller">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th
                                                class="text-left"
                                                style="border-top: none;"
                                                scope="col"
                                            >
                                                Organization
                                            </th>
                                            <th
                                                class="text-left"
                                                style="border-top: none;"
                                                scope="col"
                                            >
                                                Employee
                                            </th>
                                            <th
                                                class="text-left"
                                                style="border-top: none;"
                                                scope="col"
                                            >
                                                Outsourcing Type
                                            </th>
                                            <th
                                                class="text-left"
                                                style="border-top: none;"
                                                scope="col"
                                            >
                                                Description
                                            </th>
                                            <th
                                                class="text-left"
                                                style="border-top: none;"
                                                scope="col"
                                            >
                                                Start Date
                                            </th>
                                            <th
                                                class="text-left"
                                                style="border-top: none;"
                                                scope="col"
                                            >
                                                End Date
                                            </th>
                                            <th
                                                v-if="
                                                    userType ==
                                                        1 /* || (permissionIsAssigned('outsourcing-update') || permissionIsAssigned('outsourcing-delete'))*/
                                                "
                                                class="text-center d-print-none"
                                                style="border-top: none;"
                                                scope="col"
                                            >
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="outsourcing in filtered_outsourcings"
                                            :key="outsourcing.id"
                                        >
                                            <td class="text-left">
                                                {{
                                                    outsourcing.organization_name
                                                }}
                                            </td>
                                            <td class="text-left">
                                                {{
                                                    outsourcing.employee_fristname +
                                                        ' ' +
                                                        outsourcing.employee_lastname
                                                }}
                                            </td>
                                            <td class="text-left">
                                                {{
                                                    outsourcing.outsourcing_type
                                                }}
                                            </td>
                                            <td
                                                v-if="outsourcing.description"
                                                class="text-left"
                                            >
                                                {{ outsourcing.description }}
                                            </td>
                                            <td v-else class="text-left">
                                                <i>No description</i>
                                            </td>
                                            <td class="text-left">
                                                {{
                                                    new Date(
                                                        outsourcing.from_date
                                                    )
                                                        .toUTCString()
                                                        .substring(0, 16)
                                                }}
                                            </td>
                                            <td class="text-left">
                                                {{
                                                    new Date(
                                                        outsourcing.to_date
                                                    )
                                                        .toUTCString()
                                                        .substring(0, 16)
                                                }}
                                            </td>
                                            <td
                                                v-if="
                                                    userType ==
                                                        1 /* || (permissionIsAssigned('outsourcing-update') || permissionIsAssigned('outsourcing-delete'))*/
                                                "
                                                class="pt-1 d-print-none"
                                            >
                                                <div
                                                    class="ul-widget4__actions"
                                                >
                                                    <button
                                                        class="btn _r_btn border-0"
                                                        type="button"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                    >
                                                        <span
                                                            class="_dot _inline-dot bg-primary"
                                                        ></span>
                                                        <span
                                                            class="_dot _inline-dot bg-primary"
                                                        ></span>
                                                        <span
                                                            class="_dot _inline-dot bg-primary"
                                                        ></span>
                                                    </button>
                                                    <div
                                                        class="dropdown-menu"
                                                        x-placement="top-start"
                                                        style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(598px, 57px, 0px);"
                                                    >
                                                        <a
                                                            class="dropdown-item"
                                                            style="cursor: pointer;"
                                                            @click="
                                                                edit_outsourcing(
                                                                    outsourcing
                                                                )
                                                            "
                                                        >
                                                            <i
                                                                class="fal fa-edit"
                                                                style="color: inherit !important;"
                                                            ></i>
                                                            Edit
                                                        </a>
                                                        <a
                                                            class="dropdown-item"
                                                            style="cursor: pointer;"
                                                            @click="
                                                                remove_employee(
                                                                    outsourcing.id
                                                                )
                                                            "
                                                        >
                                                            <i
                                                                class="fal fa-trash"
                                                            ></i>
                                                            Delete
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-else
                class="main-content"
                :class="
                    $vuetify.breakpoint.name == 'xs' ||
                    $vuetify.breakpoint.name == 'sm'
                        ? 'px-2'
                        : null
                "
            >
                <div
                    class="breadcrumb mb-0"
                    style="margin-top: 1rem;"
                    :style="$vuetify.breakpoint.name != 'xs' ? null : null"
                >
                    <h1 class="my-2 row pb-0">
                        <span class="col-lg-5 col-md-5 col-12"
                            >Edit Outsourcing</span
                        >
                    </h1>
                </div>
                <v-form @submit.prevent>
                    <div class="container-fluid">
                        <v-row>
                            <v-col cols="12" sm="6" md="6" class="remove-hint">
                                <span style="color: #111d5e;"
                                    >Company
                                    <i
                                        class="fal fa-asterisk"
                                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                                    ></i
                                ></span>
                                <v-select
                                    v-model="editFormData.organization_id"
                                    item-color="#069"
                                    item-text="organization_name"
                                    item-value="id"
                                    :items="authCompanyOutsourcingCompanies"
                                    placeholder="Select the company"
                                    solo
                                    class="mt-2"
                                    dense
                                    color="#069"
                                ></v-select>
                            </v-col>

                            <v-col cols="12" sm="6" md="6" class="remove-hint">
                                <span style="color: #111d5e;"
                                    >Employee
                                    <i
                                        class="fas fa-asterisk"
                                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                                    ></i
                                ></span>
                                <v-combobox
                                    v-model="editFormData.employee_id"
                                    :items="allEmployees"
                                    :menu-props="{ maxHeight: '400' }"
                                    label="Select"
                                    solo
                                    dense
                                    item-value="id"
                                    item-text="name"
                                    hint="Select Employee"
                                    persistent-hint
                                ></v-combobox>
                            </v-col>

                            <v-col cols="12" sm="6" md="6" class="remove-hint">
                                <span style="color: #111d5e;"
                                    >Outsourcing Type
                                    <i
                                        class="fal fa-asterisk"
                                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                                    ></i
                                ></span>
                                <v-select
                                    v-model="editFormData.outsourcing_type"
                                    item-color="#069"
                                    :items="[
                                        'Contract',
                                        'Permanent',
                                        'Freelancing',
                                    ]"
                                    placeholder="Select the outsourcing type"
                                    solo
                                    class="mt-2"
                                    dense
                                    color="#069"
                                ></v-select>
                            </v-col>

                            <v-col cols="12">
                                <span style="color: #111d5e;"
                                    >Outsourcing Description
                                    <i
                                        class="fas fa-asterisk"
                                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                                    ></i
                                ></span>
                                <v-textarea
                                    v-model="editFormData.description"
                                    placeholder="Enter the outsourcing description"
                                    solo
                                    class="mt-2"
                                >
                                </v-textarea>
                            </v-col>

                            <v-col cols="12" sm="6" md="6" class="remove-hint">
                                <span style="color: #111d5e;"
                                    >Start Date
                                    <i
                                        class="fas fa-asterisk"
                                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                                    ></i
                                ></span>
                                <v-menu
                                    ref="fromDateMenuForCreation"
                                    v-model="fromDateMenuForCreation"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            class="mt-1"
                                            color="#069"
                                            prepend-inner-icon="mdi-calendar-month-outline"
                                            v-model="editFormData.from_date"
                                            placeholder="Start Date"
                                            dense
                                            solo
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        color="#069"
                                        ref="picker"
                                        :min="
                                            new Date().getFullYear().toString()
                                        "
                                        v-model="editFormData.from_date"
                                        @change="saveFromDateForEdit"
                                    >
                                    </v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col cols="12" sm="6" md="6" class="remove-hint">
                                <span style="color: #111d5e;"
                                    >End Date
                                    <i
                                        class="fas fa-asterisk"
                                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                                    ></i
                                ></span>
                                <v-menu
                                    ref="toDateMenuForCreation"
                                    v-model="toDateMenuForCreation"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            class="mt-1"
                                            color="#069"
                                            prepend-inner-icon="mdi-calendar-month-outline"
                                            v-model="editFormData.to_date"
                                            placeholder="End Date"
                                            dense
                                            solo
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        color="#069"
                                        ref="picker"
                                        :min="
                                            new Date().getFullYear().toString()
                                        "
                                        v-model="editFormData.to_date"
                                        @change="saveToDateForEdit"
                                    >
                                    </v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col cols="12" class="remove-hint text-center">
                                <button
                                    @click="
                                        update_outsourcing_submit
                                            ? null
                                            : update_outsourcing()
                                    "
                                    type="button"
                                    class="btn btn-secondary m-1 ripple w-25"
                                >
                                    {{ update_outsourcing_text }}
                                    <v-icon
                                        :style="
                                            !update_outsourcing_submit
                                                ? 'display: none;'
                                                : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                                        "
                                    >
                                        fas fa-circle-notch fa-spin
                                    </v-icon>
                                </button>
                                <button
                                    @click="create_outsourcing = 2"
                                    type="button"
                                    class="btn btn-outline-secondary m-1"
                                >
                                    Cancel
                                </button>
                            </v-col>
                        </v-row>
                    </div>
                </v-form>
            </div>
            <div class="flex-grow-1"></div>
        </div>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
    import axios from 'axios';
    import { ASSETS_URL, BASE_URL } from '@/main';
    import { mapActions, mapState, mapGetters } from 'vuex';
    export default {
        data() {
            return {
                overlay: false,
                current_outsourcing: {},
                selectedEmployees: [],
                date_range: [],
                current_outsourcing_id: null,
                fromDateMenuForCreation: false,
                toDateMenuForCreation: false,
                fromDateMenuForEdit: false,
                toDateMenuForEdit: false,
                create_outsourcing_text: 'Submit',
                create_outsourcing_submit: false,
                update_outsourcing_text: 'Update',
                update_outsourcing_submit: false,
                create_outsourcing: 2,
                formData: {
                    company_id: '',
                    organization_id: '',
                    employee_id: '',
                    outsourcing_type: '',
                    from_date: '',
                    to_date: '',
                    description: '',
                },
                editFormData: {
                    id: '',
                    company_id: '',
                    organization_id: '',
                    employee_id: '',
                    outsourcing_type: '',
                    from_date: '',
                    to_date: '',
                    description: '',
                },
            };
        },
        computed: {
            ...mapState(['weekDays', 'userToken']),
            ...mapGetters([
                'validEmployeeId',
                'authCompanyData',
                'userType',
                'authCompanyOutsourcingCompanies',
                'authCompanyOutsourcingEmployeesByEmployee',
                'authCompanyOutsourcingEmployeesByCompany',
            ]),
            allEmployees() {
                const _this = this;
                return _this
                    .mixinBranchEmployees(_this.$route.name)
                    .map((EMPLOYEE) => {
                        return {
                            name: `${EMPLOYEE.employee_fristname} ${EMPLOYEE.employee_lastname}`,
                            id: EMPLOYEE.id,
                            department_id: EMPLOYEE.department_id,
                        };
                    });
            },
            employeesToAdd() {
                let _this = this,
                    result = [];
                result = _this.selectedEmployees.map((SE) => {
                    return {
                        company_id: _this.authCompanyData.id,
                        employee_id: SE.id,
                        outsourcing_id: _this.current_outsourcing_id,
                        department_id: SE.department_id,
                    };
                });
                return result;
            },
            assets_url() {
                return ASSETS_URL;
            },
            dateRangeText() {
                if (this.date_range) {
                    return this.date_range.join(' ~ ');
                }
                return null;
            },
            json_data: function() {
                return this.excel_filtered_trainings;
            },
            json_fields: function() {
                return {
                    COMPANY: 'organization_name',
                    'EMPLOYEE NAME': 'employee_name',
                    'OUTSOURCING TYPE': 'outsourcing_type',
                    DESCRIPTION: 'description',
                    'START DATE': 'from_date',
                    'END DATE': 'to_date',
                };
            },
            filtered_outsourcings() {
                let _this = this,
                    outsourcings = [];
                if (_this.userType == 1) {
                    outsourcings =
                        _this.authCompanyOutsourcingEmployeesByCompany;
                } else {
                    outsourcings =
                        _this.authCompanyOutsourcingEmployeesByEmployee;
                }

                if (_this.date_range.length == 2) {
                    outsourcings = outsourcings.filter((outsourcing) => {
                        return (
                            new Date(outsourcing.from_date).getTime() >=
                                new Date(_this.date_range[0]).getTime() &&
                            new Date(outsourcing.from_date).getTime() <=
                                new Date(_this.date_range[1]).getTime()
                        );
                    });
                }

                return outsourcings;
            },
            excel_filtered_trainings() {
                let _this = this;
                return _this.filtered_outsourcings.map((outsourcing) => {
                    return {
                        organization_id: outsourcing.organization_id,
                        employee_name: `${outsourcing.employee_fristname} ${outsourcing.employee_lastname}`,
                        outsourcing_type: outsourcing.outsourcing_type,
                        description: outsourcing.description,
                        from_date: outsourcing.from_date,
                        to_date: outsourcing.to_date,
                    };
                });
            },
        },
        methods: {
            ...mapActions([
                'fetch_company_outsourcing_employees_by_company',
                'fetch_company_outsourcing_employees_by_employee',
                'fetch_company_outsourcing_companies',
            ]),
            async print() {
                // Pass the element id here
                await this.$htmlToPaper(
                    'printMe',
                    this.mixinPrintPortraitOptions
                );
            },
            hasHistory: function() {
                return window.history.length > 2;
            },
            reset_filter() {
                this.date_range = [];
            },
            saveFromDateForCreation: function(t) {
                this.$refs.fromDateMenuForCreation.save(t);
            },
            saveToDateForCreation: function(t) {
                this.$refs.toDateMenuForCreation.save(t);
            },
            saveFromDateForEdit: function(t) {
                this.$refs.fromDateMenuForEdit.save(t);
            },
            saveToDateForEdit: function(t) {
                this.$refs.toDateMenuForEdit.save(t);
            },
            add_employees_to_outsourcing() {
                let _this = this;
                _this.create_outsourcing_text = '';
                _this.create_outsourcing_submit = true;

                const Toast = _this.$swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3500,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener(
                            'mouseenter',
                            _this.$swal.stopTimer
                        );
                        toast.addEventListener(
                            'mouseleave',
                            _this.$swal.resumeTimer
                        );
                    },
                });
                axios
                    .post(
                        `${BASE_URL}storeoutsourcingemployee`,
                        {
                            company_id: _this.authCompanyData.id,
                            organization_id: _this.formData.organization_id,
                            employee_id: _this.formData.employee_id,
                            outsourcing_type: _this.formData.outsourcing_type,
                            from_date: _this.formData.from_date,
                            to_date: _this.formData.to_date,
                            description: _this.formData.description,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${_this.userToken}`,
                            },
                        }
                    )
                    .then(function() {
                        Toast.fire({
                            icon: 'success',
                            title: 'Employee added successfully',
                        });
                        _this.create_outsourcing = 2;
                        _this.formData.organization_id = '';
                        _this.formData.employee_id = '';
                        _this.formData.outsourcing_type = '';
                        _this.formData.from_date = '';
                        _this.formData.to_date = '';
                        _this.formData.description = '';
                        _this.fetch_company_outsourcing_employees_by_company();
                        _this.fetch_company_outsourcing_employees_by_employee();
                    })
                    .catch(function() {
                        // let errors = error.response.data.error || null;
                        Toast.fire({
                            icon: 'error',
                            title: "Employee couldn't be added",
                        });
                    })
                    .then(function() {
                        _this.create_outsourcing_submit = false;
                        _this.create_outsourcing_text = 'Submit';
                    });
            },
            remove_employee(outsourcing_id) {
                let _this = this;

                const Toast = _this.$swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener(
                            'mouseenter',
                            _this.$swal.stopTimer
                        );
                        toast.addEventListener(
                            'mouseleave',
                            _this.$swal.resumeTimer
                        );
                    },
                });

                _this.$swal
                    .fire({
                        title: 'Are you sure you want to remove this employee?',
                        showCancelButton: true,
                        confirmButtonText: `Yes, Remove`,
                    })
                    .then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            axios
                                .post(
                                    `${BASE_URL}removeemployeefromoutsourcingcompany `,
                                    {
                                        company_id: _this.authCompanyData.id,
                                        idtoremove: outsourcing_id,
                                    },
                                    {
                                        headers: {
                                            Authorization: `Bearer ${_this.userToken}`,
                                        },
                                    }
                                )
                                .then(function() {
                                    Toast.fire({
                                        icon: 'success',
                                        title: 'Employee removed successfully',
                                    });
                                    _this.fetch_company_outsourcing_employees_by_company();
                                })
                                .catch(function(error) {
                                    Toast.fire({
                                        icon: 'error',
                                        title: 'Could not remove the employee',
                                    });
                                    _this.fetch_company_outsourcing_employees_by_company();
                                    _this.fetch_company_outsourcing_employees_by_employee();
                                    console.log(error);
                                })
                                .then(function() {
                                    //
                                });
                        }
                    });
            },
            tweak_display() {
                if (this.create_outsourcing == 1) {
                    this.create_outsourcing = 2;
                } else if (this.create_outsourcing == 2) {
                    this.create_outsourcing = 1;
                } else {
                    ('');
                }
            },
            edit_outsourcing(outsourcing) {
                let _this = this;
                _this.current_outsourcing = outsourcing;
                _this.create_outsourcing = 3;
                _this.editFormData.id = outsourcing.id;
                _this.editFormData.company_id = outsourcing.company_id;
                _this.editFormData.organization_id =
                    outsourcing.organization_id;
                _this.editFormData.employee_id = outsourcing.employee_id;
                _this.editFormData.outsourcing_type =
                    outsourcing.outsourcing_type;
                _this.editFormData.from_date = outsourcing.from_date;
                _this.editFormData.to_date = outsourcing.to_date;
                _this.editFormData.description = outsourcing.description;
            },
            update_outsourcing() {
                let _this = this;
                _this.update_outsourcing_text = '';
                _this.update_outsourcing_submit = true;
                const Toast = _this.$swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener(
                            'mouseenter',
                            _this.$swal.stopTimer
                        );
                        toast.addEventListener(
                            'mouseleave',
                            _this.$swal.resumeTimer
                        );
                    },
                });

                axios
                    .post(
                        `${BASE_URL}updateoutsourcingemployee`,
                        {
                            company_id: _this.authCompanyData.id,
                            employeeoutsourcing_id: _this.editFormData.id,
                            organization_id: _this.editFormData.organization_id,
                            employee_id: _this.editFormData.employee_id,
                            outsourcing_type:
                                _this.editFormData.outsourcing_type,
                            from_date: _this.editFormData.from_date,
                            to_date: _this.editFormData.to_date,
                            description: _this.editFormData.description,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${_this.userToken}`,
                            },
                        }
                    )
                    .then(function() {
                        Toast.fire({
                            icon: 'success',
                            title: 'Outsourcing updated successfully',
                        });
                        _this.create_outsourcing = 2;
                        _this.fetch_company_outsourcing_employees_by_company();
                        _this.fetch_company_outsourcing_employees_by_employee();
                    })
                    .catch(function(error) {
                        let errors = error.response.data.error || null;
                        Toast.fire({
                            icon: 'error',
                            title: errors
                                ? typeof errors === 'string'
                                    ? errors
                                    : null || errors.provider_id
                                    ? errors.provider_id[0]
                                    : null || errors.training_title
                                    ? errors.training_title[0]
                                    : null || errors.training_description
                                    ? errors.training_description[0]
                                    : null || errors.training_option
                                    ? errors.training_option[0]
                                    : null || errors.training_cost
                                    ? errors.training_cost[0]
                                    : null || errors.from_date
                                    ? errors.from_date[0]
                                    : null || errors.to_date
                                    ? errors.to_date[0]
                                    : null
                                : null ||
                                  error.response.data.error ||
                                  error.response.data.message ||
                                  'Outsourcing could not be updated',
                        });
                    })
                    .then(function() {
                        _this.update_outsourcing_text = 'Update';
                        _this.update_outsourcing_submit = false;
                    });
            },
        },
        async mounted() {
            document.title = 'Kylian ERP - Outsourcing';
            let _this = this;
            if (
                !_this.authCompanyOutsourcingEmployeesByCompany.length ||
                !_this.authCompanyOutsourcingEmployeesByEmployee
            ) {
                _this.overlay = true;
            }
            await _this.fetch_company_outsourcing_employees_by_company();
            await _this.fetch_company_outsourcing_employees_by_employee();
            _this.overlay = false;
            await _this.fetch_company_outsourcing_companies();
        },
    };
</script>

<style>
    .ql-toolbar.ql-snow {
        border: none;
        border-bottom: 1px solid #ccc;
    }

    .editor-opener {
        border-radius: 5px;
        box-shadow: 0px 0px 50px 0px #00000014 !important;
    }

    .ql-container.ql-snow {
        border: none !important;
    }

    .remove-hint div div div.v-text-field__details {
        display: none !important;
    }

    .educationField .v-text-field__details {
        display: none !important;
    }

    .educationField .v-input__slot {
        margin: 0;
    }

    .supportingDocs div div div .v-file-input__text {
        cursor: pointer;
    }
</style>
