var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
            _vm.userType == 1 ||
                (_vm.userType != 1 &&
                    !_vm.employeeView &&
                    _vm.permissionIsAssigned('tasksheets-read'))
        )?_c('div',{staticClass:"main-content-wrap sidenav-open d-flex flex-column",class:_vm.$vuetify.breakpoint.name == 'xs' ||
            _vm.$vuetify.breakpoint.name == 'sm'
                ? 'px-0'
                : null},[_c('div',{staticClass:"main-header pl-4",class:_vm.$vuetify.breakpoint.name == 'xs' ||
                _vm.$vuetify.breakpoint.name == 'sm'
                    ? 'px-0'
                    : null,staticStyle:{"z-index":"60"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"logo col-md-1 col-lg-1 col-12 py-0",staticStyle:{"align-self":"center"}},[_c('div',{staticClass:"dropdown animate__animated animate__fadeIn",staticStyle:{"font-size":"120%"}},[_c('i',{staticClass:"fal fa-arrow-alt-circle-left mr-1",staticStyle:{"cursor":"pointer","position":"relative","top":"2px","color":"#069"},on:{"click":function($event){_vm.hasHistory()
                                    ? _vm.$router.go(-1)
                                    : _vm.$router.push('/')}}}),_c('span',{staticClass:"ml-2",staticStyle:{"top":"4%","position":"relative"}},[_vm._v("Back")])])]),(
                        _vm.userType != 1 &&
                            _vm.permissionIsAssigned('tasksheets-read')
                    )?_c('div',{staticClass:"input-group-prepend"},[_c('button',{staticClass:"btn dropdown-toggle w-100",attrs:{"type":"button","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_c('span',{style:(_vm.$vuetify.breakpoint.name == 'xs'
                                    ? 'margin-left: 1.5rem;'
                                    : _vm.$vuetify.breakpoint.name == 'sm'
                                    ? 'margin-left: 5rem;'
                                    : null)},[_vm._v(_vm._s(_vm.employeeView ? 'Your Tasksheets' : "All Employees' Tasksheets"))])]),(_vm.permissionIsAssigned('tasksheets-read'))?_c('div',{staticClass:"dropdown-menu",staticStyle:{"min-width":"13rem"}},[_c('a',{staticClass:"dropdown-item",staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.employeeView = !_vm.employeeView}}},[_vm._v(" "+_vm._s(_vm.employeeView ? "All Employees' Tasksheets" : 'Your Tasksheets')+" ")])]):_vm._e()]):_vm._e()]),_c('div',{staticStyle:{"margin":"auto"}}),_c('div',{staticClass:"header-part-right",class:_vm.$vuetify.breakpoint.name == 'xs' ? 'pr-0' : 'pr-5'},[(_vm.userType == 1)?_c('div',{staticClass:"dropdown"},[_c('button',{staticClass:"btn btn-outline-secondary m-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.push({ name: 'BillingHistory' })}}},[_vm._v(" Billing History ")])]):_vm._e(),_c('div',{staticClass:"dropdown"},[(_vm.userType != '1')?_c('button',{staticClass:"btn btn-outline-secondary m-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.push({ name: 'LogReport' })}}},[_vm._v(" Log Time ")]):_vm._e(),(_vm.userType == '1')?_c('button',{staticClass:"btn btn-outline-secondary m-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.push({ name: 'Attendance' })}}},[_vm._v(" Log History ")]):_vm._e()])])]),_c('div',{staticClass:"main-content pt-0",class:_vm.$vuetify.breakpoint.name == 'xs' ||
                _vm.$vuetify.breakpoint.name == 'sm'
                    ? 'px-2'
                    : null},[_c('div',{staticClass:"breadcrumb",staticStyle:{"margin-top":"1rem"}},[_c('div',{staticClass:"row",style:(_vm.$vuetify.breakpoint.name == 'xs'
                            ? 'width: 105%;'
                            : 'width: 80%;')},[_c('h1',{staticClass:"my-2 row pb-0"},[_c('span',{staticClass:"col-12 col-lg-4 col-md-4"},[_vm._v("Employee Tasksheets")]),_c('v-row',{staticClass:"col-lg-8 col-md-8 col-12",staticStyle:{"float":"right"}},[_c('v-col',{staticClass:"pt-0",attrs:{"offset":_vm.filtered_tasksheets.length >= 1 ? 1 : 3,"cols":"6","lg":"2","md":"2"}},[_c('v-select',{staticClass:"remove-mb",attrs:{"items":_vm.allYears,"placeholder":"Year","dense":"","solo":"","messages":" *Filter by year","full-width":true},model:{value:(_vm.yearSelected),callback:function ($$v) {_vm.yearSelected=$$v},expression:"yearSelected"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"6","lg":"3","md":"3"}},[_c('v-select',{staticClass:"remove-mb",attrs:{"items":_vm.monthNames,"placeholder":"Month","dense":"","solo":"","messages":"*Filter by month","full-width":true},model:{value:(_vm.monthSelected),callback:function ($$v) {_vm.monthSelected=$$v},expression:"monthSelected"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"6","lg":"3","md":"3"}},[_c('v-combobox',{staticClass:"remove-mb",attrs:{"items":_vm.allEmployees,"placeholder":"Employee","dense":"","solo":"","messages":"*Select Employee","full-width":true},model:{value:(_vm.employee_name),callback:function ($$v) {_vm.employee_name=$$v},expression:"employee_name"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"6","lg":"1","md":"1"}},[_c('button',{staticClass:"btn btn-raised ripple btn-raised-secondary m-1 text-white",class:_vm.$vuetify.breakpoint.name == 'sm' ||
                                        _vm.$vuetify.breakpoint.name == 'xs'
                                            ? 'w-100'
                                            : null,attrs:{"type":"button"},on:{"click":function($event){return _vm.reset()}}},[_vm._v(" Reset ")])]),(_vm.filtered_tasksheets.length >= 1)?_c('v-col',{staticClass:"pt-0",attrs:{"cols":"2"}},[_c('download-excel',{staticClass:"btn btn-raised ripple btn-raised-secondary m-1 text-white",attrs:{"data":_vm.json_data,"fields":_vm.json_fields,"worksheet":_vm.employee_name
                                            ? _vm.employee_name +
                                              ' - ' +
                                              _vm.monthSelected +
                                              ' ' +
                                              _vm.yearSelected
                                            : 'Employees\' tasksheets ' +
                                              (_vm.monthSelected +
                                                  ' ' +
                                                  _vm.yearSelected),"name":'Tasksheet for ' +
                                            (_vm.employee_name
                                                ? _vm.employee_name
                                                : 'all employees') +
                                            ' (' +
                                            _vm.monthSelected +
                                            ' ' +
                                            _vm.yearSelected +
                                            ').xls'}},[_vm._v(" Export to Excel ")])],1):_vm._e()],1)],1),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card",style:(_vm.employee_name &&
                                _vm.monthSelected &&
                                _vm.yearSelected &&
                                _vm.monthly_status == 'approved'
                                    ? 'border-radius: 10px; border: 4px solid #4caf50d4;'
                                    : _vm.employee_name &&
                                      _vm.monthSelected &&
                                      _vm.yearSelected &&
                                      _vm.monthly_status == 'disapproved'
                                    ? 'border-radius: 10px; border: 4px solid #f44336e0;'
                                    : _vm.employee_name &&
                                      _vm.monthSelected &&
                                      _vm.yearSelected &&
                                      _vm.monthly_status == 'pending'
                                    ? 'border-radius: 10px; border: 4px solid #ffc107db;'
                                    : '')},[(
                                    _vm.employee_name &&
                                        _vm.monthSelected &&
                                        _vm.yearSelected
                                )?_c('div',{staticClass:"card-header bg-white"},[_c('span',{},[_vm._v(" Status: "),_c('span',{class:_vm.monthly_status == 'approved'
                                                ? 'badge badge-square badge-square-opacity-success m-1 sm'
                                                : _vm.monthly_status ==
                                                  'disapproved'
                                                ? 'badge badge-square badge-square-opacity-danger m-1 sm'
                                                : 'badge badge-square badge-square-opacity-warning m-1 sm'},[(
                                                _vm.monthly_status == 'approved'
                                            )?_c('i',{staticClass:"fal fa-check"}):(
                                                _vm.monthly_status ==
                                                    'disapproved'
                                            )?_c('i',{staticClass:"fal fa-times"}):_c('i',{staticClass:"fal fa-hourglass"})])]),_c('span',{staticStyle:{"top":"0px","position":"relative","margin-left":"10px"}},[_vm._v(" Days Worked: "+_vm._s(_vm.filtered_tasksheets.length)+" ")]),(
                                        (_vm.employee_name &&
                                            _vm.monthSelected &&
                                            _vm.yearSelected &&
                                            _vm.monthly_status == 'pending') ||
                                            (_vm.employee_name &&
                                                _vm.monthSelected &&
                                                _vm.yearSelected &&
                                                _vm.monthly_status ==
                                                    'disapproved')
                                    )?_c('div',{staticClass:"float-right"},[(
                                            _vm.permissionIsAssigned(
                                                'tasksheets-approve'
                                            ) &&
                                                (_vm.monthly_status ==
                                                    'disapproved' ||
                                                    (_vm.monthly_status ==
                                                        'pending' &&
                                                        _vm.filtered_tasksheets.length >=
                                                            1))
                                        )?_c('button',{staticClass:"btn btn-raised ripple btn-raised-secondary m-1 text-white float-right",attrs:{"type":"button"},on:{"click":function($event){_vm.approve_tasksheet_submit
                                                ? null
                                                : _vm.approve_tasksheets()}}},[_vm._v(" "+_vm._s(_vm.approve_tasksheet_text)+" "),_c('v-icon',{style:(!_vm.approve_tasksheet_submit
                                                    ? 'display: none;'
                                                    : 'color: #fff; padding: 0px 10px; font-size: 16px;')},[_vm._v(" fal fa-circle-notch fa-spin ")])],1):_vm._e(),(
                                            _vm.permissionIsAssigned(
                                                'tasksheets-approve'
                                            ) &&
                                                (_vm.monthly_status ==
                                                    'approved' ||
                                                    (_vm.monthly_status ==
                                                        'pending' &&
                                                        _vm.filtered_tasksheets.length >=
                                                            1))
                                        )?_c('button',{staticClass:"btn btn-raised ripple btn-raised-secondary m-1 text-white float-right",attrs:{"type":"button"},on:{"click":function($event){_vm.disapprove_tasksheet_submit
                                                ? null
                                                : _vm.disapprove_tasksheets()}}},[_vm._v(" "+_vm._s(_vm.disapprove_tasksheet_text)+" "),_c('v-icon',{style:(!_vm.disapprove_tasksheet_submit
                                                    ? 'display: none;'
                                                    : 'color: #fff; padding: 0px 10px; font-size: 16px;')},[_vm._v(" fal fa-circle-notch fa-spin ")])],1):_vm._e()]):_vm._e()]):_vm._e(),_c('div',{staticClass:"card-body px-1 py-1 pt-0 pr-0"},[_c('div',{staticClass:"table-responsive",staticStyle:{"border-radius":"10px","height":"75vh","overflow-y":"scroll","padding-top":"0","padding-bottom":"0"}},[_c('table',{staticClass:"table table-hover"},[_vm._m(0),_c('tbody',[_vm._l((_vm.filtered_tasksheets),function(tasksheet){return _c('tr',{key:tasksheet.id},[_c('th',{staticClass:"underline text-left",attrs:{"scope":"row"},on:{"click":function($event){return _vm.set_date(
                                                            tasksheet.date
                                                        )}}},[_vm._v(" "+_vm._s(new Date( tasksheet.date ) .toUTCString() .substring( 0, 16 ))+" ")]),_c('th',{staticClass:"underline text-left",attrs:{"scope":"row"},on:{"click":function($event){_vm.employee_name =
                                                            tasksheet.employee_fristname +
                                                            ' ' +
                                                            tasksheet.employee_lastname}}},[_vm._v(" "+_vm._s(tasksheet.employee_fristname + ' ' + tasksheet.employee_lastname)+" ")]),(
                                                        _vm.getDesignationById(
                                                            tasksheet.designation_id
                                                        )
                                                    )?_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.getDesignationById( tasksheet.designation_id ).title)+" ")]):_c('td',{staticClass:"text-left"},[_c('i',[_vm._v("Unspecified designation")])]),_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(tasksheet.project_title)+" ")]),_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(tasksheet.work_done)+" ")]),_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(tasksheet.hour)+" ")]),_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(tasksheet.minutes)+" ")]),(
                                                        _vm.getDesignationById(
                                                            tasksheet.designation_id
                                                        )
                                                    )?_c('td',{staticClass:"text-left"},[_vm._v(" $"+_vm._s(_vm._f("number_with_commas")((_vm.getDesignationById( tasksheet.designation_id ).rate || 0)))+" ")]):_c('td',{staticClass:"text-left"},[_c('i',[_vm._v("No hourly rate specified")])]),(
                                                        _vm.getDesignationById(
                                                            tasksheet.designation_id
                                                        )
                                                    )?_c('td',{staticClass:"text-left"},[_vm._v(" $"+_vm._s(_vm._f("number_with_commas")((_vm.getAmountDue( _vm.getDesignationById( tasksheet.designation_id ).rate, tasksheet.minutes ) || 0)))+" ")]):_c('td',{staticClass:"text-left"},[_c('i',[_vm._v("No hourly rate specified")])])])}),(
                                                    _vm.filtered_tasksheets.length
                                                )?_c('tr',[_c('td',{staticClass:"text-left"}),_c('td',{staticClass:"text-left"}),_c('td',{staticClass:"text-left"}),_c('td',{staticClass:"text-left"}),_c('td',{staticClass:"text-left"}),_c('td',{staticClass:"text-left"}),_c('td',{staticClass:"text-left"}),_c('td',{staticClass:"text-left",staticStyle:{"font-weight":"bold"}},[_vm._v(" TOTAL PROFESSIONAL FEES ")]),_c('td',{staticClass:"text-left",staticStyle:{"font-weight":"bold"}},[_vm._v(" $"+_vm._s(_vm._f("number_with_commas")(_vm.totalAmountDue || 0))+" ")])]):_vm._e()],2)])])])])])])]),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.tasksheet_comment),callback:function ($$v) {_vm.tasksheet_comment=$$v},expression:"tasksheet_comment"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.approval ? 'Approve' : 'Disapprove')+" Tasksheet with Comment")])]),_c('v-card-text',[_c('v-container',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"container-fluid"},[_c('v-row',[_c('v-col',{staticClass:"remove-hint",attrs:{"cols":"12"}},[_c('span',{staticStyle:{"color":"#111d5e"}},[_vm._v("Comment for "+_vm._s(_vm.employee_name)+" ("+_vm._s(_vm.monthSelected)+")")]),_c('v-textarea',{staticClass:"mt-2",attrs:{"placeholder":"Comment","solo":"","disabled":_vm.userType != 1 &&
                                                    !_vm.permissionIsAssigned(
                                                        'tasksheets-approve'
                                                    )
                                                        ? true
                                                        : false},model:{value:(_vm.comment_to_add),callback:function ($$v) {_vm.comment_to_add=$$v},expression:"comment_to_add"}})],1),_c('v-col',{staticClass:"remove-hint",attrs:{"cols":"12","sm":"4","md":"4","offset-md":"4","offset-sm":"4"}},[_c('button',{staticClass:"btn btn-secondary m-1 text-white btn-raised ripple",staticStyle:{"width":"99%"},attrs:{"type":"button"},on:{"click":function($event){_vm.add_tasksheet_comment_submit
                                                        ? null
                                                        : _vm.add_comment()}}},[_vm._v(" "+_vm._s(_vm.add_tasksheet_comment_text)+" "),_c('v-icon',{style:(!_vm.add_tasksheet_comment_submit
                                                            ? 'display: none;'
                                                            : 'color: #fff; padding: 0px 10px; font-size: 16px;')},[_vm._v(" fal fa-circle-notch fa-spin ")])],1)])],1)],1)])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.tasksheet_comment = false}}},[_vm._v(" Close ")])],1)],1)],1),_c('div',{staticClass:"row"})],1),_c('div',{staticClass:"flex-grow-1"})]):_c('div',{staticClass:"main-content-wrap sidenav-open d-flex flex-column",class:_vm.$vuetify.breakpoint.name == 'xs' ||
            _vm.$vuetify.breakpoint.name == 'sm'
                ? 'px-0'
                : null},[_c('div',{staticClass:"main-header pl-4",class:_vm.$vuetify.breakpoint.name == 'xs' ||
                _vm.$vuetify.breakpoint.name == 'sm'
                    ? 'pr-1'
                    : 'pr-5',staticStyle:{"z-index":"60"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"logo col-md-1 col-lg-1 col-12 py-0",staticStyle:{"align-self":"center"}},[_c('div',{staticClass:"dropdown animate__animated animate__fadeIn",staticStyle:{"font-size":"120%"}},[_c('i',{staticClass:"fal fa-arrow-alt-circle-left mr-1",staticStyle:{"cursor":"pointer","position":"relative","top":"2px","color":"#069"},on:{"click":function($event){_vm.hasHistory()
                                    ? _vm.$router.go(-1)
                                    : _vm.$router.push('/')}}}),_c('span',{staticClass:"ml-2",staticStyle:{"top":"4%","position":"relative"}},[_vm._v("Back")])])]),(
                        _vm.userType != 1 &&
                            _vm.permissionIsAssigned('tasksheets-read')
                    )?_c('div',{staticClass:"input-group-prepend"},[_c('button',{staticClass:"btn dropdown-toggle w-100",attrs:{"type":"button","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_c('span',{style:(_vm.$vuetify.breakpoint.name == 'xs'
                                    ? 'margin-left: 1.5rem;'
                                    : _vm.$vuetify.breakpoint.name == 'sm'
                                    ? 'margin-left: 5rem;'
                                    : null)},[_vm._v(_vm._s(_vm.employeeView ? 'Your Tasksheets' : "All Employees' Tasksheets"))])]),(_vm.permissionIsAssigned('tasksheets-read'))?_c('div',{staticClass:"dropdown-menu",staticStyle:{"min-width":"13rem"}},[_c('a',{staticClass:"dropdown-item",staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.employeeView = !_vm.employeeView}}},[_vm._v(" "+_vm._s(_vm.employeeView ? "All Employees' Tasksheets" : 'Your Tasksheets')+" ")])]):_vm._e()]):_vm._e()]),_c('div',{staticStyle:{"margin":"auto"}}),_c('div',{staticClass:"header-part-right",class:_vm.$vuetify.breakpoint.name == 'xs' ? 'pr-0' : 'pr-5'},[(_vm.userType == 1)?_c('div',{staticClass:"dropdown"},[_c('button',{staticClass:"btn btn-outline-secondary m-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.push({ name: 'BillingHistory' })}}},[_vm._v(" Billing History ")])]):_vm._e(),_c('div',{staticClass:"dropdown"},[(_vm.userType != '1')?_c('button',{staticClass:"btn btn-outline-secondary m-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.push({ name: 'LogReport' })}}},[_vm._v(" Log Time ")]):_vm._e(),(_vm.userType == '1')?_c('button',{staticClass:"btn btn-outline-secondary m-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.push({ name: 'Attendance' })}}},[_vm._v(" Log History ")]):_vm._e()])])]),_c('div',{staticClass:"main-content pt-0",class:_vm.$vuetify.breakpoint.name == 'xs' ||
                _vm.$vuetify.breakpoint.name == 'sm'
                    ? 'px-2'
                    : null},[_c('div',{staticClass:"breadcrumb",staticStyle:{"margin-top":"1rem"}},[_c('div',{staticClass:"row",style:(_vm.$vuetify.breakpoint.name == 'xs'
                            ? 'width: 105%;'
                            : 'width: 80%;')},[_c('h1',{staticClass:"my-0 row pb-0"},[_c('span',{staticClass:"col-12 col-lg-4 col-md-4"},[_vm._v("Your Tasksheets")]),(_vm.userType == 1)?_c('v-row',{staticClass:"col-lg-8 col-md-8 col-12",staticStyle:{"float":"right"}},[_c('v-col',{staticClass:"pt-0",attrs:{"offset-md":"1","offset-lg":"1","md":"3","lg":"3","cols":"6"}},[_c('v-select',{staticClass:"remove-mb",attrs:{"items":_vm.allYears,"placeholder":"Year","dense":"","solo":"","messages":" *Filter by year","full-width":true},model:{value:(_vm.yearSelected),callback:function ($$v) {_vm.yearSelected=$$v},expression:"yearSelected"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"lg":"3","md":"3","cols":"6"}},[_c('v-select',{staticClass:"remove-mb",attrs:{"items":_vm.monthNames,"placeholder":"Month","dense":"","solo":"","messages":"*Filter by month","full-width":true},model:{value:(_vm.monthSelected),callback:function ($$v) {_vm.monthSelected=$$v},expression:"monthSelected"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"lg":"3","md":"3","cols":"6"}},[_c('v-combobox',{staticClass:"remove-mb",attrs:{"items":_vm.allEmployees,"placeholder":"Employee","dense":"","solo":"","messages":"*Select Employee","full-width":true},model:{value:(_vm.employee_name),callback:function ($$v) {_vm.employee_name=$$v},expression:"employee_name"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"lg":"2","md":"2","cols":"12"}},[(
                                        (_vm.monthSelected &&
                                            _vm.yearSelected &&
                                            _vm.monthly_status == 'pending') ||
                                            (_vm.monthSelected &&
                                                _vm.yearSelected &&
                                                _vm.monthly_status ==
                                                    'disapproved')
                                    )?_c('button',{staticClass:"btn btn-raised ripple btn-raised-secondary m-1 text-white",attrs:{"type":"button"},on:{"click":function($event){_vm.add_tasksheet_dialog = true}}},[_vm._v(" Add Tasksheet ")]):_vm._e()])],1):_c('v-row',{staticClass:"col-lg-8 col-md-8 col-12",staticStyle:{"float":"right"}},[_c('v-col',{staticClass:"pt-0",attrs:{"offset-md":(_vm.monthSelected &&
                                        _vm.yearSelected &&
                                        _vm.monthly_status == 'pending') ||
                                    (_vm.monthSelected &&
                                        _vm.yearSelected &&
                                        _vm.monthly_status == 'disapproved')
                                        ? '4'
                                        : '6',"offset-lg":(_vm.monthSelected &&
                                        _vm.yearSelected &&
                                        _vm.monthly_status == 'pending') ||
                                    (_vm.monthSelected &&
                                        _vm.yearSelected &&
                                        _vm.monthly_status == 'disapproved')
                                        ? '4'
                                        : '6',"md":"3","lg":"3","cols":"6"}},[_c('v-select',{staticClass:"remove-mb",attrs:{"items":_vm.allYears,"placeholder":"Year","dense":"","solo":"","messages":" *Filter by year","full-width":true},model:{value:(_vm.yearSelected),callback:function ($$v) {_vm.yearSelected=$$v},expression:"yearSelected"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"lg":"3","md":"3","cols":"6"}},[_c('v-select',{staticClass:"remove-mb",attrs:{"items":_vm.monthNames,"placeholder":"Month","dense":"","solo":"","messages":"*Filter by month","full-width":true},model:{value:(_vm.monthSelected),callback:function ($$v) {_vm.monthSelected=$$v},expression:"monthSelected"}})],1),(
                                    (_vm.monthSelected &&
                                        _vm.yearSelected &&
                                        _vm.monthly_status == 'pending') ||
                                        (_vm.monthSelected &&
                                            _vm.yearSelected &&
                                            _vm.monthly_status == 'disapproved')
                                )?_c('v-col',{staticClass:"pt-0",attrs:{"lg":"2","md":"2","cols":"12"}},[_c('button',{staticClass:"btn btn-raised ripple btn-raised-secondary m-1 text-white",attrs:{"type":"button"},on:{"click":function($event){_vm.add_tasksheet_dialog = true}}},[_vm._v(" Add Tasksheet ")])]):_vm._e()],1)],1),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card",style:(_vm.authEmployeeDataForCompany.id &&
                                _vm.monthSelected &&
                                _vm.yearSelected &&
                                _vm.monthly_status == 'approved'
                                    ? 'border-radius: 10px; border: 4px solid #4caf50d4;'
                                    : _vm.authEmployeeDataForCompany.id &&
                                      _vm.monthSelected &&
                                      _vm.yearSelected &&
                                      _vm.monthly_status == 'disapproved'
                                    ? 'border-radius: 10px; border: 4px solid #f44336e0;'
                                    : _vm.authEmployeeDataForCompany.id &&
                                      _vm.monthSelected &&
                                      _vm.yearSelected &&
                                      _vm.monthly_status == 'pending'
                                    ? 'border-radius: 10px; border: 4px solid #ffc107db;'
                                    : '')},[_c('div',{staticClass:"card-header bg-white"},[_c('span',{},[_vm._v(" Status: "),_c('span',{class:_vm.monthly_status == 'approved'
                                                ? 'badge badge-square badge-square-opacity-success m-1 sm'
                                                : _vm.monthly_status ==
                                                  'disapproved'
                                                ? 'badge badge-square badge-square-opacity-danger m-1 sm'
                                                : 'badge badge-square badge-square-opacity-warning m-1 sm'},[(
                                                _vm.monthly_status == 'approved'
                                            )?_c('i',{staticClass:"fal fa-check"}):(
                                                _vm.monthly_status ==
                                                    'disapproved'
                                            )?_c('i',{staticClass:"fal fa-times"}):_c('i',{staticClass:"fal fa-hourglass"})])]),_c('span',{staticStyle:{"top":"0px","position":"relative","margin-left":"10px"}},[_vm._v(" Days Worked: "+_vm._s(_vm.filtered_tasksheets.length)+" ")]),(
                                        _vm.filtered_tasksheets.length >= 1 &&
                                            _vm.submitted == false
                                    )?_c('div',{staticClass:"float-right"},[(
                                            (_vm.monthSelected &&
                                                _vm.yearSelected &&
                                                _vm.monthly_status ==
                                                    'pending') ||
                                                (_vm.monthSelected &&
                                                    _vm.yearSelected &&
                                                    _vm.monthly_status ==
                                                        'disapproved')
                                        )?_c('button',{staticClass:"btn btn-secondary m-1 float-right",attrs:{"type":"button"},on:{"click":function($event){_vm.submit_tasksheet_submit
                                                ? null
                                                : _vm.submit_tasksheet()}}},[_vm._v(" "+_vm._s(_vm.submit_tasksheet_text)+" "),_c('v-icon',{style:(!_vm.submit_tasksheet_submit
                                                    ? 'display: none;'
                                                    : 'color: #fff; padding: 0px 10px; font-size: 16px;')},[_vm._v(" fal fa-circle-notch fa-spin ")])],1):_vm._e()]):_vm._e(),(
                                        _vm.filtered_tasksheets.length >= 1 &&
                                            _vm.submitted == true
                                    )?_c('div',{staticClass:"float-right"},[_c('button',{staticClass:"btn btn-secondary m-1 float-right disabled",staticStyle:{"cursor":"not-allowed"},attrs:{"type":"button"}},[_vm._v(" Submitted ")])]):_vm._e(),(_vm.comment_for_month.length >= 1)?_c('div',{staticClass:"float-right"},[_c('button',{staticClass:"btn btn-outline-secondary m-1 float-right",attrs:{"type":"button"},on:{"click":function($event){_vm.tasksheet_comment = true}}},[_vm._v(" View Comment ")])]):_vm._e()]),_c('div',{staticClass:"card-body px-1 py-1 pt-0 pr-0"},[_c('div',{staticClass:"table-responsive",staticStyle:{"border-radius":"10px","height":"75vh","overflow-y":"scroll","padding-top":"0","padding-bottom":"0"}},[_c('table',{staticClass:"table table-hover"},[_c('thead',[_c('tr',{staticStyle:{"border":"2px solid #4440"}},[_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Date ")]),(_vm.userType == 1)?_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Name of Counsel ")]):_vm._e(),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Status ")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Service Title ")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Service Description ")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Time Spent in Hour(s)/Min(s) ")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Time Spent in Min(s) ")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Hourly Rate ($) ")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Amount Due ($) ")]),(
                                                        _vm.userType != 1 &&
                                                            ((_vm.monthSelected &&
                                                                _vm.yearSelected &&
                                                                _vm.monthly_status ==
                                                                    'pending') ||
                                                                (_vm.monthSelected &&
                                                                    _vm.yearSelected &&
                                                                    _vm.monthly_status ==
                                                                        'disapproved'))
                                                    )?_c('th',{attrs:{"scope":"col"}},[_vm._v(" Edit ")]):_vm._e(),(
                                                        _vm.userType != 1 &&
                                                            ((_vm.monthSelected &&
                                                                _vm.yearSelected &&
                                                                _vm.monthly_status ==
                                                                    'pending') ||
                                                                (_vm.monthSelected &&
                                                                    _vm.yearSelected &&
                                                                    _vm.monthly_status ==
                                                                        'disapproved'))
                                                    )?_c('th',{attrs:{"scope":"col"}},[_vm._v(" Delete ")]):_vm._e()])]),_c('tbody',[_vm._l((_vm.filtered_tasksheets),function(tasksheet){return _c('tr',{key:tasksheet.id},[_c('td',{staticClass:"text-left",attrs:{"scope":"row"},on:{"click":function($event){return _vm.set_date(
                                                            tasksheet.date
                                                        )}}},[_vm._v(" "+_vm._s(new Date( tasksheet.date ) .toUTCString() .substring( 0, 16 ))+" ")]),(
                                                        _vm.getDesignationById(
                                                            tasksheet.designation_id
                                                        )
                                                    )?_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.getDesignationById( tasksheet.designation_id ).title)+" ")]):_c('td',{staticClass:"text-left"},[_c('i',[_vm._v("Unspecified designation")])]),_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(tasksheet.project_title)+" ")]),_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(tasksheet.work_done)+" ")]),_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(tasksheet.hour)+" ")]),_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(tasksheet.minutes)+" ")]),(
                                                        _vm.getDesignationById(
                                                            tasksheet.designation_id
                                                        )
                                                    )?_c('td',{staticClass:"text-left"},[_vm._v(" $"+_vm._s(_vm._f("number_with_commas")((_vm.getDesignationById( tasksheet.designation_id ).rate || 0)))+" ")]):_c('td',{staticClass:"text-left"},[_c('i',[_vm._v("No hourly rate specified")])]),(
                                                        _vm.getDesignationById(
                                                            tasksheet.designation_id
                                                        )
                                                    )?_c('td',{staticClass:"text-left"},[_vm._v(" $"+_vm._s(_vm._f("number_with_commas")((_vm.getAmountDue( _vm.getDesignationById( tasksheet.designation_id ).rate, tasksheet.minutes ) || 0)))+" ")]):_c('td',{staticClass:"text-left"},[_c('i',[_vm._v("No hourly rate specified")])]),(
                                                        _vm.userType != 1 &&
                                                            ((_vm.monthSelected &&
                                                                _vm.yearSelected &&
                                                                _vm.monthly_status ==
                                                                    'pending') ||
                                                                (_vm.monthSelected &&
                                                                    _vm.yearSelected &&
                                                                    _vm.monthly_status ==
                                                                        'disapproved'))
                                                    )?_c('td',{staticClass:"pl-0 text-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                            var on = ref.on;
                                                            var attrs = ref.attrs;
return [_c('i',_vm._g(_vm._b({staticClass:"py-0 text-center fal fa-edit",staticStyle:{"color":"#069 !important","font-size":"125%","cursor":"pointer"},on:{"click":function($event){return _vm.launch_edit_tasksheet(
                                                                        tasksheet
                                                                    )}}},'i',
                                                                    attrs
                                                                ,false),on))]}}],null,true)},[_c('span',[_vm._v("Edit Tasksheet")])])],1):_vm._e(),(
                                                        _vm.userType != 1 &&
                                                            ((_vm.monthSelected &&
                                                                _vm.yearSelected &&
                                                                _vm.monthly_status ==
                                                                    'pending') ||
                                                                (_vm.monthSelected &&
                                                                    _vm.yearSelected &&
                                                                    _vm.monthly_status ==
                                                                        'disapproved'))
                                                    )?_c('td',{staticClass:"pl-0 text-left"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                            var on = ref.on;
                                                            var attrs = ref.attrs;
return [_c('i',_vm._g(_vm._b({staticClass:"col-3 offset-3 py-0 text-center fal fa-trash",staticStyle:{"color":"#069 !important","font-size":"125%","cursor":"pointer"},on:{"click":function($event){return _vm.delete_tasksheet(
                                                                        tasksheet
                                                                    )}}},'i',
                                                                    attrs
                                                                ,false),on))]}}],null,true)},[_c('span',[_vm._v("Delete Tasksheet")])])],1):_vm._e()])}),(
                                                    _vm.filtered_tasksheets.length
                                                )?_c('tr',[_c('td',{staticClass:"text-left"}),_c('td',{staticClass:"text-left"}),_c('td',{staticClass:"text-left"}),_c('td',{staticClass:"text-left"}),_c('td',{staticClass:"text-left"}),_c('td',{staticClass:"text-left"}),(
                                                        _vm.userType != 1 &&
                                                            ((_vm.monthSelected &&
                                                                _vm.yearSelected &&
                                                                _vm.monthly_status ==
                                                                    'pending') ||
                                                                (_vm.monthSelected &&
                                                                    _vm.yearSelected &&
                                                                    _vm.monthly_status ==
                                                                        'disapproved'))
                                                    )?_c('td',{staticClass:"text-left"}):_vm._e(),(
                                                        _vm.userType != 1 &&
                                                            ((_vm.monthSelected &&
                                                                _vm.yearSelected &&
                                                                _vm.monthly_status ==
                                                                    'pending') ||
                                                                (_vm.monthSelected &&
                                                                    _vm.yearSelected &&
                                                                    _vm.monthly_status ==
                                                                        'disapproved'))
                                                    )?_c('td',{staticClass:"text-left"}):_vm._e(),_c('td',{staticClass:"text-left",staticStyle:{"font-weight":"bold"}},[_vm._v(" TOTAL PROFESSIONAL FEES ")]),_c('td',{staticClass:"text-left",staticStyle:{"font-weight":"bold"}},[_vm._v(" $"+_vm._s(_vm._f("number_with_commas")(_vm.totalAmountDue || 0))+" ")])]):_vm._e()],2)])])])])])])]),_c('v-dialog',{attrs:{"max-width":"700px"},model:{value:(_vm.add_tasksheet_dialog),callback:function ($$v) {_vm.add_tasksheet_dialog=$$v},expression:"add_tasksheet_dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Add Tasksheet")])]),_c('v-card-text',[_c('v-container',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"container-fluid"},[_c('v-row',[_c('v-col',{staticClass:"remove-hint",attrs:{"cols":"12","sm":"6","md":"6"}},[_c('span',{staticStyle:{"color":"#111d5e"}},[_vm._v("Date "),_c('i',{staticClass:"fas fa-asterisk",staticStyle:{"font-size":"8px","color":"#f00","position":"relative","bottom":"5px"}})]),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                    var on = ref.on;
                                                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mt-2",attrs:{"color":"#069","prepend-inner-icon":"mdi-calendar-month-outline","placeholder":"Select a date","solo":"","dense":"","readonly":""},model:{value:(
                                                            _vm.formData.date
                                                        ),callback:function ($$v) {_vm.$set(_vm.formData, "date", $$v)},expression:"\n                                                            formData.date\n                                                        "}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{ref:"picker",attrs:{"color":"#069","min":new Date(
                                                            _vm.firstDayOfLastMonth.getTime()
                                                        )
                                                            .toISOString()
                                                            .substring(
                                                                0,
                                                                10
                                                            ),"max":new Date(
                                                            new Date().getTime()
                                                        )
                                                            .toISOString()
                                                            .substring(
                                                                0,
                                                                10
                                                            )},on:{"change":_vm.save},model:{value:(_vm.formData.date),callback:function ($$v) {_vm.$set(_vm.formData, "date", $$v)},expression:"formData.date"}})],1)],1),_c('v-col',{staticClass:"remove-hint",attrs:{"cols":"12","sm":"6","md":"6"}},[_c('span',{staticStyle:{"color":"#111d5e"}},[_vm._v("Project Title "),_c('i',{staticClass:"fas fa-asterisk",staticStyle:{"font-size":"8px","color":"#f00","position":"relative","bottom":"5px"}})]),_c('v-text-field',{staticClass:"mt-2",attrs:{"placeholder":"Enter project title","solo":"","dense":""},model:{value:(
                                                    _vm.formData.project_title
                                                ),callback:function ($$v) {_vm.$set(_vm.formData, "project_title", $$v)},expression:"\n                                                    formData.project_title\n                                                "}})],1),_c('v-col',{staticClass:"remove-hint",attrs:{"cols":"12","sm":"6","md":"6"}},[_c('span',{staticStyle:{"color":"#111d5e"}},[_vm._v("Place of Performance "),_c('i',{staticClass:"fas fa-asterisk",staticStyle:{"font-size":"8px","color":"#f00","position":"relative","bottom":"5px"}})]),_c('v-text-field',{staticClass:"mt-2",attrs:{"placeholder":"Enter place of performance","solo":"","dense":""},model:{value:(
                                                    _vm.formData.place_performance
                                                ),callback:function ($$v) {_vm.$set(_vm.formData, "place_performance", $$v)},expression:"\n                                                    formData.place_performance\n                                                "}})],1),_c('v-col',{staticClass:"remove-hint",attrs:{"cols":"12","sm":"6","md":"6"}},[_c('span',{staticStyle:{"color":"#111d5e"}},[_vm._v("Designation "),_c('i',{staticClass:"fas fa-asterisk",staticStyle:{"font-size":"8px","color":"#f00","position":"relative","bottom":"5px"}})]),_c('v-select',{staticClass:"mt-2",attrs:{"item-color":"#069","items":_vm.trueDesignations,"placeholder":"Designation","solo":"","dense":"","color":"#069"},model:{value:(
                                                    _vm.formData.employee_designation
                                                ),callback:function ($$v) {_vm.$set(_vm.formData, "employee_designation", $$v)},expression:"\n                                                    formData.employee_designation\n                                                "}})],1),_c('v-col',{staticClass:"remove-hint",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('span',{staticStyle:{"color":"#111d5e"}},[_vm._v("Work Done "),_c('i',{staticClass:"fas fa-asterisk",staticStyle:{"font-size":"8px","color":"#f00","position":"relative","bottom":"5px"}})]),_c('v-textarea',{staticClass:"mt-2",attrs:{"placeholder":"Enter work done","solo":""},model:{value:(_vm.formData.work_done),callback:function ($$v) {_vm.$set(_vm.formData, "work_done", $$v)},expression:"formData.work_done"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('h2',[_vm._v("Start:")]),_c('v-time-picker',{model:{value:(_vm.formData.start),callback:function ($$v) {_vm.$set(_vm.formData, "start", $$v)},expression:"formData.start"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('h2',[_vm._v("End:")]),_c('v-time-picker',{model:{value:(_vm.formData.end),callback:function ($$v) {_vm.$set(_vm.formData, "end", $$v)},expression:"formData.end"}})],1),_c('v-col',{staticClass:"remove-hint",attrs:{"cols":"12","sm":"4","md":"4","offset-md":"4","offset-sm":"4"}},[_c('button',{staticClass:"btn btn-secondary m-1 text-white btn-raised ripple",staticStyle:{"width":"99%"},attrs:{"type":"button"},on:{"click":function($event){_vm.add_tasksheet_submit
                                                        ? null
                                                        : _vm.add_tasksheet()}}},[_vm._v(" "+_vm._s(_vm.add_tasksheet_text)+" "),_c('v-icon',{style:(!_vm.add_tasksheet_submit
                                                            ? 'display: none;'
                                                            : 'color: #fff; padding: 0px 10px; font-size: 16px;')},[_vm._v(" fal fa-circle-notch fa-spin ")])],1)])],1)],1)])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.add_tasksheet_dialog = false}}},[_vm._v(" Close ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"700px"},model:{value:(_vm.edit_tasksheet_dialog),callback:function ($$v) {_vm.edit_tasksheet_dialog=$$v},expression:"edit_tasksheet_dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Edit Tasksheet")])]),_c('v-card-text',[_c('v-container',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"container-fluid"},[_c('v-row',[_c('v-col',{staticClass:"remove-hint",attrs:{"cols":"12","sm":"6","md":"6"}},[_c('span',{staticStyle:{"color":"#111d5e"}},[_vm._v("Date "),_c('i',{staticClass:"fas fa-asterisk",staticStyle:{"font-size":"8px","color":"#f00","position":"relative","bottom":"5px"}})]),_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                    var on = ref.on;
                                                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mt-2",attrs:{"color":"#069","prepend-inner-icon":"mdi-calendar-month-outline","placeholder":"Date","disabled":false,"dense":"","solo":"","readonly":""},model:{value:(
                                                            _vm.editFormData.date
                                                        ),callback:function ($$v) {_vm.$set(_vm.editFormData, "date", $$v)},expression:"\n                                                            editFormData.date\n                                                        "}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{ref:"picker2",attrs:{"color":"#069","disabled":false,"min":new Date(
                                                            _vm.firstDayOfLastMonth.getTime()
                                                        )
                                                            .toISOString()
                                                            .substring(
                                                                0,
                                                                10
                                                            ),"max":new Date(
                                                            new Date().getTime()
                                                        )
                                                            .toISOString()
                                                            .substring(
                                                                0,
                                                                10
                                                            )},on:{"change":_vm.save2},model:{value:(
                                                        _vm.editFormData.date
                                                    ),callback:function ($$v) {_vm.$set(_vm.editFormData, "date", $$v)},expression:"\n                                                        editFormData.date\n                                                    "}})],1)],1),_c('v-col',{staticClass:"remove-hint",attrs:{"cols":"12","sm":"6","md":"6"}},[_c('span',{staticStyle:{"color":"#111d5e"}},[_vm._v("Project Title "),_c('i',{staticClass:"fas fa-asterisk",staticStyle:{"font-size":"8px","color":"#f00","position":"relative","bottom":"5px"}})]),_c('v-text-field',{staticClass:"mt-2",attrs:{"placeholder":"Project Title","solo":"","dense":""},model:{value:(
                                                    _vm.editFormData.project_title
                                                ),callback:function ($$v) {_vm.$set(_vm.editFormData, "project_title", $$v)},expression:"\n                                                    editFormData.project_title\n                                                "}})],1),_c('v-col',{staticClass:"remove-hint",attrs:{"cols":"12","sm":"6","md":"6"}},[_c('span',{staticStyle:{"color":"#111d5e"}},[_vm._v("Place of Performance "),_c('i',{staticClass:"fas fa-asterisk",staticStyle:{"font-size":"8px","color":"#f00","position":"relative","bottom":"5px"}})]),_c('v-text-field',{staticClass:"mt-2",attrs:{"placeholder":"Place of Performance","solo":"","dense":""},model:{value:(
                                                    _vm.editFormData.place_performance
                                                ),callback:function ($$v) {_vm.$set(_vm.editFormData, "place_performance", $$v)},expression:"\n                                                    editFormData.place_performance\n                                                "}})],1),_c('v-col',{staticClass:"remove-hint",attrs:{"cols":"12","sm":"6","md":"6"}},[_c('span',{staticStyle:{"color":"#111d5e"}},[_vm._v("Designation "),_c('i',{staticClass:"fas fa-asterisk",staticStyle:{"font-size":"8px","color":"#f00","position":"relative","bottom":"5px"}})]),_c('v-select',{staticClass:"mt-2",attrs:{"item-color":"#069","items":_vm.trueDesignations,"placeholder":"Designation","solo":"","dense":"","color":"#069"},model:{value:(
                                                    _vm.editFormData.employee_designation
                                                ),callback:function ($$v) {_vm.$set(_vm.editFormData, "employee_designation", $$v)},expression:"\n                                                    editFormData.employee_designation\n                                                "}})],1),_c('v-col',{staticClass:"remove-hint",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('span',{staticStyle:{"color":"#111d5e"}},[_vm._v("Work Done "),_c('i',{staticClass:"fas fa-asterisk",staticStyle:{"font-size":"8px","color":"#f00","position":"relative","bottom":"5px"}})]),_c('v-textarea',{staticClass:"mt-2",attrs:{"placeholder":"Work Done","solo":""},model:{value:(
                                                    _vm.editFormData.work_done
                                                ),callback:function ($$v) {_vm.$set(_vm.editFormData, "work_done", $$v)},expression:"\n                                                    editFormData.work_done\n                                                "}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('h2',[_vm._v("Start:")]),_c('v-time-picker',{model:{value:(_vm.editFormData.start),callback:function ($$v) {_vm.$set(_vm.editFormData, "start", $$v)},expression:"editFormData.start"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('h2',[_vm._v("End:")]),_c('v-time-picker',{model:{value:(_vm.editFormData.end),callback:function ($$v) {_vm.$set(_vm.editFormData, "end", $$v)},expression:"editFormData.end"}})],1),_c('v-col',{staticClass:"remove-hint",attrs:{"cols":"12","sm":"4","md":"4","offset-md":"4","offset-sm":"4"}},[_c('button',{staticClass:"btn btn-secondary m-1 text-white btn-raised ripple",staticStyle:{"width":"99%"},attrs:{"type":"button"},on:{"click":function($event){_vm.update_tasksheet_submit
                                                        ? null
                                                        : _vm.update_tasksheet()}}},[_vm._v(" "+_vm._s(_vm.update_tasksheet_text)+" "),_c('v-icon',{style:(!_vm.update_tasksheet_submit
                                                            ? 'display: none;'
                                                            : 'color: #fff; padding: 0px 10px; font-size: 16px;')},[_vm._v(" fal fa-circle-notch fa-spin ")])],1)])],1)],1)])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.edit_tasksheet_dialog = false}}},[_vm._v(" Close ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.tasksheet_comment),callback:function ($$v) {_vm.tasksheet_comment=$$v},expression:"tasksheet_comment"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.approval ? 'Approve' : 'Disapprove')+" Tasksheet with Comment")])]),_c('v-card-text',[_c('v-container',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"container-fluid"},[_c('v-row',[_c('v-col',{staticClass:"remove-hint",attrs:{"cols":"12"}},[_c('v-textarea',{staticClass:"mt-2",attrs:{"placeholder":_vm.userType == 1 ||
                                                    _vm.permissionIsAssigned(
                                                        'tasksheets-approve'
                                                    )
                                                        ? 'Comment for ' +
                                                          _vm.employee_name +
                                                          ' (' +
                                                          _vm.monthSelected +
                                                          ')'
                                                        : '',"solo":"","disabled":_vm.userType != 1 &&
                                                    !_vm.permissionIsAssigned(
                                                        'tasksheets-approve'
                                                    )
                                                        ? true
                                                        : false},model:{value:(_vm.comment_to_add),callback:function ($$v) {_vm.comment_to_add=$$v},expression:"comment_to_add"}})],1),_c('v-col',{staticClass:"remove-hint",attrs:{"cols":"12","sm":"4","md":"4","offset-md":"4","offset-sm":"4"}},[(
                                                    _vm.userType == 1 ||
                                                        _vm.permissionIsAssigned(
                                                            'tasksheets-approve'
                                                        )
                                                )?_c('button',{staticClass:"btn btn-secondary m-1 text-white btn-raised ripple",staticStyle:{"width":"99%"},attrs:{"type":"button"},on:{"click":function($event){_vm.add_tasksheet_comment_submit
                                                        ? null
                                                        : _vm.add_comment()}}},[_vm._v(" "+_vm._s(_vm.add_tasksheet_comment_text)+" "),_c('v-icon',{style:(!_vm.add_tasksheet_comment_submit
                                                            ? 'display: none;'
                                                            : 'color: #fff; padding: 0px 10px; font-size: 16px;')},[_vm._v(" fal fa-circle-notch fa-spin ")])],1):_vm._e()])],1)],1)])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.tasksheet_comment = false}}},[_vm._v(" Close ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.fileAttachmentDialog),callback:function ($$v) {_vm.fileAttachmentDialog=$$v},expression:"fileAttachmentDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("File Attachment")])]),_c('v-card-text',[_c('v-container',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"container-fluid"},[_c('v-row',[_c('v-col',{staticClass:"remove-hint",staticStyle:{"cursor":"pointer"},attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-file-input',{staticClass:"fileAttachment",staticStyle:{"text-indent":"5px"},attrs:{"prepend-icon":"","prepend-inner-icon":"mdi-attachment mdi-rotate-270","label":"Upload File Attachment","solo":"","color":"#069","messages":"File Attachment"}})],1),_c('v-col',{staticClass:"remove-hint",attrs:{"cols":"12","sm":"6","md":"6","offset-md":"3","offset-sm":"3"}},[_c('button',{staticClass:"btn btn-secondary m-1 ripple",staticStyle:{"width":"100%"},attrs:{"type":"button"},on:{"click":function($event){_vm.fileAttachmentBtnSubmit
                                                        ? null
                                                        : _vm.upload_file_attachment()}}},[_vm._v(" "+_vm._s(_vm.fileAttachmentBtnText)+" "),_c('v-icon',{style:(!_vm.fileAttachmentBtnSubmit
                                                            ? 'display: none;'
                                                            : 'color: #fff; padding: 0px 10px; font-size: 16px;')},[_vm._v(" fas fa-circle-notch fa-spin ")])],1)])],1)],1)])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.fileAttachmentDialog = false}}},[_vm._v(" Close ")])],1)],1)],1),_c('div',{staticClass:"row"})],1),_c('div',{staticClass:"flex-grow-1"})])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',{staticStyle:{"border":"2px solid #4440"}},[_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Date ")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Name of Counsel ")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Status ")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Service Title ")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Service Description ")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Time Spent in Hour(s)/Min(s) ")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Time Spent in Min(s) ")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Hourly Rate ($) ")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Amount Due ($) ")])])])}]

export { render, staticRenderFns }