<template>
    <div>
        <div class="main-content-wrap sidenav-open d-flex flex-column">
            <div class="main-header pl-4" style="z-index: 60;">
                <div class="row">
                    <div
                        style="align-self: center;"
                        class="logo col-md-1 col-lg-1 col-12 py-4"
                    >
                        <div
                            class="dropdown animate__animated animate__fadeIn"
                            style="font-size: 120%;"
                        >
                            <i
                                @click="
                                    hasHistory()
                                        ? $router.go(-1)
                                        : $router.push('/')
                                "
                                style="cursor: pointer; position: relative; top: 2px; color: #069;"
                                class="fal fa-arrow-alt-circle-left mr-1"
                            ></i>
                            <span
                                class="ml-2"
                                style="top: 4%; position: relative;"
                                >Back</span
                            >
                        </div>
                    </div>
                </div>
                <div style="margin: auto;"></div>
            </div>
            <!-- ======== Body content start ========= -->
            <div
                class="main-content pt-0"
                :class="
                    $vuetify.breakpoint.name == 'xs' ||
                    $vuetify.breakpoint.name == 'sm'
                        ? 'px-0'
                        : null
                "
            >
                <div class="breadcrumb" style="margin-top: 1rem;">
                    <div class="row">
                        <h1 class="col-md-12 pb-2">
                            <span>Settings</span>
                        </h1>
                    </div>
                </div>
                <div class="row">
                    <div class="text-left" style="width: 100%;">
                        <div class="card-body py-4">
                            <div class="row">
                                <ItemsList />
                                <div
                                    class="col-12 col-lg-10 col-xl-10 col-md-12"
                                >
                                    <div
                                        class="tab-content py-4"
                                        id="nav-tabContent"
                                    >
                                        <TwoFA :overlay="overlay" />
                                        <ManageSignature />
                                        <IPManagement
                                            :ips="ips"
                                            :fetch_ip_details="fetch_ip_details"
                                        />
                                        <Configurations
                                            :fetch_all_shifts="fetch_all_shifts"
                                            :fetch_company_attendance_configuration="
                                                fetch_company_attendance_configuration
                                            "
                                        />
                                        <GeolocationManagement />
                                        <HolidayManagement />
                                        <DeductionManagement />
                                        <LeavePolicies
                                            :fetch_all_leave_type="
                                                fetch_all_leave_type
                                            "
                                            :get_dept_name="get_dept_name"
                                            :all_leave_type="all_leave_type"
                                        />
                                        <LeaveReset />
                                        <Checklists />
                                        <!-- <AssetTypes /> -->
                                        <!-- <VendorsManagement /> -->
                                        <BranchesManagement />
                                        <BranchStatus />
                                        <ManageDepartments
                                            :fetch_company_departments="
                                                fetch_company_departments
                                            "
                                        />
                                        <ManageCommittees
                                            :get_dept_name="get_dept_name"
                                        />
                                        <ManageDesignations />
                                        <ManageCourseCategories />
                                        <TrainingProviders />
                                        <ManageCompanies />
                                        <WorkflowSettings />
                                        <PaymentAccount />
                                        <PayGradeManagement />
                                        <GradeLevelManagement />
                                        <PayItems />
                                        <PayCalendarManagement
                                            :get_dept_name="get_dept_name"
                                        />
                                        <HospitalsManagement />
                                        <AuditTrail />
                                        <UserRoles />
                                        <RolesManagement />
                                        <SalaryRatio />
                                        <div
                                            class="tab-pane fade"
                                            id="list-email-config"
                                            role="tabpanel"
                                            aria-labelledby="list-email-config-2"
                                        >
                                            <h4>
                                                <div class="row">
                                                    <div
                                                        class="col-lg-6"
                                                        style="align-self: center;"
                                                    >
                                                        Email Configuration
                                                    </div>
                                                </div>
                                            </h4>
                                            <div class="main-content mt-0 px-0">
                                                <div>
                                                    <v-form @submit.prevent>
                                                        <div
                                                            class="container-fluid"
                                                        >
                                                            <v-row>
                                                                <v-col
                                                                    cols="12"
                                                                    sm="6"
                                                                    md="6"
                                                                    class="remove-hint"
                                                                >
                                                                    <span
                                                                        style="color: #111d5e;"
                                                                        >Driver
                                                                        <i
                                                                            class="fal fa-asterisk"
                                                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                                                                        ></i
                                                                    ></span>
                                                                    <v-text-field
                                                                        v-model="
                                                                            email_config.driver
                                                                        "
                                                                        placeholder="Enter driver name"
                                                                        solo
                                                                        dense
                                                                        class="mt-2"
                                                                    ></v-text-field>
                                                                </v-col>
                                                                <v-col
                                                                    cols="12"
                                                                    sm="6"
                                                                    md="6"
                                                                    class="remove-hint"
                                                                >
                                                                    <span
                                                                        style="color: #111d5e;"
                                                                        >Host
                                                                        <i
                                                                            class="fal fa-asterisk"
                                                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                                                                        ></i
                                                                    ></span>
                                                                    <v-text-field
                                                                        v-model="
                                                                            email_config.host
                                                                        "
                                                                        placeholder="Enter host"
                                                                        solo
                                                                        dense
                                                                        class="mt-2"
                                                                    ></v-text-field>
                                                                </v-col>
                                                                <v-col
                                                                    cols="12"
                                                                    sm="6"
                                                                    md="6"
                                                                    class="remove-hint"
                                                                >
                                                                    <span
                                                                        style="color: #111d5e;"
                                                                        >Port
                                                                        <i
                                                                            class="fal fa-asterisk"
                                                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                                                                        ></i
                                                                    ></span>
                                                                    <v-text-field
                                                                        type="number"
                                                                        v-model="
                                                                            email_config.port
                                                                        "
                                                                        placeholder="Enter port"
                                                                        solo
                                                                        dense
                                                                        class="mt-2"
                                                                    ></v-text-field>
                                                                </v-col>
                                                                <v-col
                                                                    cols="12"
                                                                    sm="6"
                                                                    md="6"
                                                                    class="remove-hint"
                                                                >
                                                                    <span
                                                                        style="color: #111d5e;"
                                                                        >Encryption
                                                                        <i
                                                                            class="fal fa-asterisk"
                                                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                                                                        ></i
                                                                    ></span>
                                                                    <v-select
                                                                        class="mt-2"
                                                                        :items="[
                                                                            'TSL',
                                                                            'SSL',
                                                                        ]"
                                                                        placeholder="Enter encryption"
                                                                        solo
                                                                        dense
                                                                        :full-width="
                                                                            true
                                                                        "
                                                                        v-model="
                                                                            email_config.encryption
                                                                        "
                                                                    ></v-select>
                                                                </v-col>
                                                                <v-col
                                                                    cols="12"
                                                                    sm="6"
                                                                    md="6"
                                                                    class="remove-hint"
                                                                >
                                                                    <span
                                                                        style="color: #111d5e;"
                                                                        >Username
                                                                        <i
                                                                            class="fal fa-asterisk"
                                                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                                                                        ></i
                                                                    ></span>
                                                                    <v-text-field
                                                                        v-model="
                                                                            email_config.username
                                                                        "
                                                                        placeholder="Enter username"
                                                                        solo
                                                                        dense
                                                                        class="mt-2"
                                                                    ></v-text-field>
                                                                </v-col>
                                                                <v-col
                                                                    cols="12"
                                                                    sm="6"
                                                                    md="6"
                                                                    class="remove-hint"
                                                                >
                                                                    <span
                                                                        style="color: #111d5e;"
                                                                        >Password
                                                                        <i
                                                                            class="fal fa-asterisk"
                                                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                                                                        ></i
                                                                    ></span>
                                                                    <v-text-field
                                                                        :append-icon="
                                                                            show
                                                                                ? 'fal fa-eye'
                                                                                : 'fal fa-eye-slash'
                                                                        "
                                                                        :type="
                                                                            show
                                                                                ? 'text'
                                                                                : 'password'
                                                                        "
                                                                        @click:append="
                                                                            show = !show
                                                                        "
                                                                        v-model="
                                                                            email_config.password
                                                                        "
                                                                        placeholder="Enter password"
                                                                        solo
                                                                        dense
                                                                        class="mt-2"
                                                                    ></v-text-field>
                                                                </v-col>
                                                                <v-col
                                                                    cols="12"
                                                                    sm="6"
                                                                    md="6"
                                                                    class="remove-hint"
                                                                >
                                                                    <span
                                                                        style="color: #111d5e;"
                                                                        >Sender
                                                                        Name
                                                                        <i
                                                                            class="fal fa-asterisk"
                                                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                                                                        ></i
                                                                    ></span>
                                                                    <v-text-field
                                                                        v-model="
                                                                            email_config.sender_name
                                                                        "
                                                                        placeholder="Enter sender name"
                                                                        solo
                                                                        dense
                                                                        class="mt-2"
                                                                    ></v-text-field>
                                                                </v-col>
                                                                <v-col
                                                                    cols="12"
                                                                    sm="6"
                                                                    md="6"
                                                                    class="remove-hint"
                                                                >
                                                                    <span
                                                                        style="color: #111d5e;"
                                                                        >Sender
                                                                        E-mail
                                                                        <i
                                                                            class="fal fa-asterisk"
                                                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                                                                        ></i
                                                                    ></span>
                                                                    <v-text-field
                                                                        type="email"
                                                                        v-model="
                                                                            email_config.sender_email
                                                                        "
                                                                        placeholder="Enter sender e-mail"
                                                                        solo
                                                                        dense
                                                                        class="mt-2"
                                                                    ></v-text-field>
                                                                </v-col>
                                                                <v-col
                                                                    cols="12"
                                                                    sm="4"
                                                                    md="4"
                                                                    offset-md="4"
                                                                    offset-sm="4"
                                                                    class="remove-hint"
                                                                >
                                                                    <button
                                                                        style="width: 99%;"
                                                                        type="button"
                                                                        class="btn btn-secondary m-1 text-white btn-raised ripple"
                                                                    >
                                                                        <!-- BLOCK CLICK ON LOADING -->
                                                                        {{
                                                                            email_config_text
                                                                        }}
                                                                        <v-icon
                                                                            :style="
                                                                                !email_config_submit
                                                                                    ? 'display: none;'
                                                                                    : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                                                                            "
                                                                        >
                                                                            fal
                                                                            fa-circle-notch
                                                                            fa-spin
                                                                        </v-icon>
                                                                    </button>
                                                                </v-col>
                                                            </v-row>
                                                        </div>
                                                    </v-form>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            v-if="
                                                create_file_type_form &&
                                                    check_all_services(
                                                        'File Management'
                                                    )
                                            "
                                            class="tab-pane fade"
                                            id="list-settings15"
                                            role="tabpanel"
                                            aria-labelledby="list-settings-list15"
                                        >
                                            <h4>
                                                <div class="row">
                                                    <div
                                                        class="col-lg-6"
                                                        style="align-self: center;"
                                                    >
                                                        {{
                                                            create_file_type_form
                                                                ? 'Create File Type'
                                                                : 'File Types'
                                                        }}
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="row">
                                                            <div
                                                                v-if="
                                                                    create_file_type_form
                                                                "
                                                                class="col-lg-3 offset-9 py-4 text-right"
                                                            >
                                                                <button
                                                                    @click="
                                                                        create_file_type_form = false
                                                                    "
                                                                    style="float: right;"
                                                                    type="button"
                                                                    class="btn btn-sm btn-danger m-1 text-white btn-raised ripple"
                                                                >
                                                                    Cancel
                                                                </button>
                                                            </div>
                                                            <div
                                                                v-else
                                                                class="col-lg-3 offset-9 py-4"
                                                            >
                                                                <button
                                                                    @click="
                                                                        create_file_type_form = true
                                                                    "
                                                                    style="float: right;"
                                                                    type="button"
                                                                    class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple"
                                                                >
                                                                    Create File
                                                                    Type
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </h4>
                                            <div class="main-content mt-0 px-0">
                                                <div>
                                                    <v-form @submit.prevent>
                                                        <div
                                                            class="container-fluid"
                                                        >
                                                            <v-row>
                                                                <v-col
                                                                    cols="12"
                                                                    sm="6"
                                                                    md="6"
                                                                    class="remove-hint"
                                                                >
                                                                    <v-text-field
                                                                        v-model="
                                                                            file_type_form.filetype_name
                                                                        "
                                                                        placeholder="File Type Name"
                                                                        solo
                                                                        class="mt-2"
                                                                        dense
                                                                    ></v-text-field>
                                                                </v-col>
                                                                <v-col
                                                                    cols="12"
                                                                    sm="6"
                                                                    md="6"
                                                                    class="remove-hint"
                                                                >
                                                                    <v-text-field
                                                                        v-model="
                                                                            file_type_form.file_description
                                                                        "
                                                                        placeholder="File Description"
                                                                        solo
                                                                        class="mt-2"
                                                                        dense
                                                                    ></v-text-field>
                                                                </v-col>
                                                                <v-col
                                                                    cols="12"
                                                                    sm="6"
                                                                    md="6"
                                                                    class="remove-hint"
                                                                >
                                                                    <button
                                                                        @click="
                                                                            create_file_type_submit
                                                                                ? null
                                                                                : create_file_type()
                                                                        "
                                                                        style="width: 99%;"
                                                                        type="button"
                                                                        class="btn btn-secondary m-1 text-white btn-raised ripple"
                                                                    >
                                                                        {{
                                                                            create_file_type_text
                                                                        }}
                                                                        <v-icon
                                                                            :style="
                                                                                !create_file_type_submit
                                                                                    ? 'display: none;'
                                                                                    : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                                                                            "
                                                                        >
                                                                            fal
                                                                            fa-circle-notch
                                                                            fa-spin
                                                                        </v-icon>
                                                                    </button>
                                                                </v-col>
                                                            </v-row>
                                                        </div>
                                                    </v-form>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            v-if="
                                                !create_file_type_form &&
                                                    check_all_services(
                                                        'File Management'
                                                    )
                                            "
                                            class="tab-pane fade"
                                            id="list-settings15"
                                            role="tabpanel"
                                            aria-labelledby="list-settings-list15"
                                        >
                                            <h4>
                                                <div class="row">
                                                    <div
                                                        class="col-lg-6"
                                                        style="align-self: center;"
                                                    >
                                                        File Types
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="row">
                                                            <div
                                                                class="col-lg-3 offset-9 py-4"
                                                            >
                                                                <button
                                                                    @click="
                                                                        create_file_type_form = true
                                                                    "
                                                                    style="float: right;"
                                                                    type="button"
                                                                    class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple"
                                                                >
                                                                    Create File
                                                                    Type
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </h4>
                                            <div class="table-responsive">
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <!-- <th data-v-ea551cb4="" scope="col" style="padding: 0px 10px 20px 24px; width: 5%;">
                                                            <label data-v-ea551cb4="" class="checkbox checkbox-secondary">
                                                                <input data-v-ea551cb4="" type="checkbox" :checked="false">
                                                                <span data-v-ea551cb4="" class="checkmark"></span>
                                                            </label>
                                                        </th> -->
                                                            <th scope="col">
                                                                File Type Name
                                                            </th>
                                                            <th scope="col">
                                                                File Type
                                                                Description
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                style="width: 10px;"
                                                            >
                                                                Actions
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            v-for="file_type in auth_file_types"
                                                            :key="file_type.id"
                                                        >
                                                            <!-- <th scope="row" style="padding-left: 2.5%;">
                                                            <label class="checkbox checkbox-secondary">
                                                                <input type="checkbox" :checked="false">
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </th> -->
                                                            <td>
                                                                {{
                                                                    file_type.filetype_name
                                                                }}
                                                            </td>
                                                            <td>
                                                                {{
                                                                    file_type.file_description
                                                                }}
                                                            </td>
                                                            <td
                                                                style="padding-top: 13px;"
                                                                class="employee-options"
                                                            >
                                                                <div
                                                                    class="row"
                                                                >
                                                                    <i
                                                                        @click="
                                                                            launch_edit_file_type(
                                                                                file_type
                                                                            )
                                                                        "
                                                                        class="col-3 py-4 text-center fal fa-edit mr-2"
                                                                        style="color: #069 !important; font-size: 125%; cursor: pointer;"
                                                                    ></i>
                                                                    <i
                                                                        @click="
                                                                            delete_file_type(
                                                                                file_type
                                                                            )
                                                                        "
                                                                        class="col-3 py-4 text-center fal fa-trash ml-2"
                                                                        style="color: #069 !important; font-size: 125%; cursor: pointer;"
                                                                    ></i>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <v-row justify="center">
                    <v-dialog v-model="edit_file_type_dialog" max-width="600px">
                        <v-card>
                            <v-card-title>
                                <span class="headline">{{
                                    'Edit File Type'
                                }}</span>
                            </v-card-title>
                            <v-card-text>
                                <v-container>
                                    <v-form @submit.prevent>
                                        <div class="container-fluid">
                                            <v-row>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="6"
                                                    class="remove-hint"
                                                >
                                                    <v-text-field
                                                        dense
                                                        v-model="
                                                            edit_file_type_form.filetype_name
                                                        "
                                                        placeholder="File Type Name"
                                                        solo
                                                        class="mt-2"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="6"
                                                    class="remove-hint"
                                                >
                                                    <v-text-field
                                                        dense
                                                        v-model="
                                                            edit_file_type_form.file_description
                                                        "
                                                        placeholder="File Description"
                                                        solo
                                                        class="mt-2"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="6"
                                                    offset-md="3"
                                                    offset-sm="3"
                                                    class="remove-hint"
                                                >
                                                    <button
                                                        @click="
                                                            edit_file_type_submit
                                                                ? null
                                                                : update_file_type()
                                                        "
                                                        style="width: 100%;"
                                                        type="button"
                                                        class="btn btn-secondary m-1 ripple"
                                                    >
                                                        {{
                                                            edit_file_type_text
                                                        }}
                                                        <v-icon
                                                            :style="
                                                                !edit_file_type_submit
                                                                    ? 'display: none;'
                                                                    : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                                                            "
                                                        >
                                                            fal fa-circle-notch
                                                            fa-spin
                                                        </v-icon>
                                                    </button>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-form>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="edit_file_type_dialog = false"
                                >
                                    Close
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-row>
            </div>
            <!-- Footer Start -->
            <div class="flex-grow-1"></div>
            <!-- fotter end -->
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import { BASE_URL } from '@/main';
    import { mapActions, mapGetters } from 'vuex';
    import ItemsList from '@/components/Modules/Settings/ItemsList';
    import IPManagement from '@/components/Modules/Settings/Attendance/IPManagement';
    import Configurations from '@/components/Modules/Settings/Attendance/Configurations';
    import GeolocationManagement from '@/components/Modules/Settings/Attendance/GeolocationManagement';
    import HolidayManagement from '@/components/Modules/Settings/Attendance/HolidayManagement';
    import DeductionManagement from '@/components/Modules/Settings/Attendance/DeductionManagement';
    import LeavePolicies from '@/components/Modules/Settings/Leave/LeavePolicies';
    import LeaveReset from '@/components/Modules/Settings/Leave/LeaveReset';
    import Checklists from '@/components/Modules/Settings/Assets/Checklists';
    // import AssetTypes from "@/components/Modules/Settings/Assets/AssetTypes";
    // import VendorsManagement from "@/components/Modules/Settings/Vendors/VendorsManagement";
    import BranchesManagement from '@/components/Modules/Settings/Branches/BranchesManagement';
    import BranchStatus from '@/components/Modules/Settings/Branches/BranchStatus';
    import ManageDepartments from '@/components/Modules/Settings/Departments/ManageDepartments';
    import ManageCommittees from '@/components/Modules/Settings/Departments/ManageCommittees';
    import ManageDesignations from '@/components/Modules/Settings/Departments/ManageDesignations';
    import ManageCourseCategories from '@/components/Modules/Settings/LMS/ManageCourseCategories';
    import PaymentAccount from '@/components/Modules/Settings/Payroll/PaymentAccount';
    import PayGradeManagement from '@/components/Modules/Settings/Payroll/PayGradeManagement';
    import GradeLevelManagement from '@/components/Modules/Settings/Payroll/GradeLevelManagement';
    import PayItems from '@/components/Modules/Settings/Payroll/PayItems';
    import SalaryRatio from '@/components/Modules/Settings/Payroll/SalaryRatio';
    import PayCalendarManagement from '@/components/Modules/Settings/Payroll/PayCalendarManagement';
    import HospitalsManagement from '@/components/Modules/Settings/Medicals/HospitalsManagement';
    import AuditTrail from '@/components/Modules/Settings/Logs/AuditTrail';
    import UserRoles from '@/components/Modules/Settings/Roles/UserRoles';
    import RolesManagement from '@/components/Modules/Settings/Roles/RolesManagement';
    import TwoFA from '@/components/Modules/Settings/Security/TwoFA';
    import ManageSignature from '@/components/Modules/Settings/General/ManageSignature';
    import WorkflowSettings from '@/components/Modules/Settings/Workflow/Settings';
    import TrainingProviders from '@/components/Modules/Settings/Training/TrainingProviders';
    import ManageCompanies from '@/components/Modules/Settings/Outsourcing/ManageCompanies';

    export default {
        components: {
            ItemsList,
            IPManagement,
            Configurations,
            GeolocationManagement,
            HolidayManagement,
            LeavePolicies,
            LeaveReset,
            Checklists,
            // AssetTypes,
            // VendorsManagement,
            BranchesManagement,
            ManageDepartments,
            ManageCommittees,
            ManageDesignations,
            PaymentAccount,
            PayGradeManagement,
            GradeLevelManagement,
            PayItems,
            PayCalendarManagement,
            HospitalsManagement,
            AuditTrail,
            UserRoles,
            RolesManagement,
            TwoFA,
            DeductionManagement,
            ManageCourseCategories,
            ManageSignature,
            WorkflowSettings,
            TrainingProviders,
            ManageCompanies,
            SalaryRatio,
            BranchStatus,
        },
        data() {
            return {
                overlay: true,
                email_config_text: 'Submit',
                email_config_submit: false,
                show: false,
                email_config: {
                    driver: '',
                    host: '',
                    port: '',
                    encryption: '',
                    username: '',
                    password: '',
                    sender_name: '',
                    sender_email: '',
                },
                edit_file_type_dialog: false,
                ips: [],
                create_file_type_form: false,
                file_type_form: {
                    filetype_name: '',
                    file_description: '',
                },
                create_file_type_text: 'Submit',
                create_file_type_submit: false,
                all_leave_type: [],
                edit_file_type_text: 'Update',
                edit_file_type_submit: false,
                edit_file_type_form: {
                    id: null,
                    filetype_name: '',
                    file_description: '',
                },
            };
        },
        computed: {
            ...mapGetters([
                'authCompanyData',
                'authCompanyDepartments',
                'userType',
                'auth_file_types',
            ]),
        },
        methods: {
            ...mapActions([
                'fetch_company_departments',
                'fetch_company_attendance_configuration',
                'fetch_company_file_types',
                'fetch_all_shifts',
            ]),
            hasHistory: function() {
                return window.history.length > 2;
            },
            loadedAPIs() {
                document.getElementById('list-2-fa-list').click();
                // if (this.permissionIsAssigned("ip-address-read") && this.check_all_services("Attendance")) {
                //     document.getElementById("list-home-list").click();
                // } else if (this.permissionIsAssigned("attendance-config-read") && this.check_all_services("Attendance")) {
                //     document.getElementById("list-settings-list12").click();
                // } else if (this.permissionIsAssigned("attendance-view") && this.check_all_services("Attendance")) {
                //     document.getElementById("list-settings-list13").click();
                // } else if (this.permissionIsAssigned("department-read") && this.check_all_services("Onboarding")) {
                //     document.getElementById("list-settings-list").click();
                // } else if (this.permissionIsAssigned("designation-read") && this.check_all_services("Onboarding")) {
                //     document.getElementById("list-settings-designations-list").click();
                // } else if (this.permissionIsAssigned("vendor-read") && this.check_all_services("Assets")) {
                //     document.getElementById("list-profile-list0").click();
                // } else if (this.permissionIsAssigned("leave-type-update") && this.check_all_services("Absence or Leave")) {
                //     document.getElementById("list-messages-list-reset").click();
                // } else if (this.permissionIsAssigned("paycalendar-read") && this.check_all_services("Attendance")) {
                //     document.getElementById("list-settings-list50").click();
                // } else if (this.permissionIsAssigned("medicals-read") && this.check_all_services("Medicals")) {
                //     document.getElementById("list-settings-list51").click();
                // } else if (this.userType == 1 || this.userType != 1) {
                //     document.getElementById("list-email-config-2").click();
                // } else {
                //     console.log("E no work");
                // }
            },
            fetch_all_leave_type() {
                let _this = this;
                axios
                    .post(`${BASE_URL}fetchleavetype`, {
                        company_id: _this.authCompanyData.id,
                    })
                    .then(function(response) {
                        const RESPONSE = response.data;
                        let temp_leave_type = RESPONSE.Employees.map(
                            (leave) => {
                                return {
                                    company_id: leave.company_id,
                                    created_at: leave.created_at,
                                    department_id: leave.department_id,
                                    description: leave.description,
                                    id: leave.id,
                                    leave_duration: leave.leave_duration,
                                    leave_name: leave.leave_name,
                                    unpaid_timeoff: leave.unpaid_timeoff,
                                    updated_at: leave.updated_at,
                                    saveText: 'Save',
                                    saveLoading: false,
                                    deleteText: 'Delete',
                                    deleteLoading: false,
                                };
                            }
                        );
                        _this.all_leave_type = temp_leave_type;
                    })
                    .catch(function(error) {
                        console.log(error.response);
                    })
                    .then(function() {
                        //
                    });
            },
            create_file_type() {
                let _this = this;
                _this.create_file_type_text = '';
                _this.create_file_type_submit = true;

                const Toast = _this.$swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener(
                            'mouseenter',
                            _this.$swal.stopTimer
                        );
                        toast.addEventListener(
                            'mouseleave',
                            _this.$swal.resumeTimer
                        );
                    },
                });

                axios
                    .post(`${BASE_URL}createcompanyfiletype`, {
                        company_id: _this.authCompanyData.id,
                        company_unique_id:
                            _this.authCompanyData.company_unique_id,
                        filetype_name: _this.file_type_form.filetype_name,
                        file_description: _this.file_type_form.file_description,
                    })
                    .then(function() {
                        Toast.fire({
                            icon: 'success',
                            title:
                                _this.file_type_form.filetype_name +
                                ' was added successfully.',
                        });
                        _this.file_type_form.filetype_name = '';
                        _this.file_type_form.file_description = '';
                        _this.fetch_company_file_types();
                        _this.create_file_type_form = false;
                    })
                    .catch(function(error) {
                        let errors = error.response.data.error || null;
                        Toast.fire({
                            icon: 'error',
                            title: errors
                                ? typeof errors === 'string'
                                    ? errors
                                    : null || errors.filetype_name
                                    ? errors.filetype_name[0]
                                    : null || errors.file_description
                                    ? errors.file_description[0]
                                    : null
                                : null ||
                                  error.response.data.error ||
                                  error.response.data.message ||
                                  'The file type could not be created',
                        });
                    })
                    .then(function() {
                        _this.create_file_type_submit = false;
                        _this.create_file_type_text = 'Submit';
                    });
            },
            update_file_type() {
                let _this = this;
                _this.edit_file_type_text = '';
                _this.edit_file_type_submit = true;
                const Toast = _this.$swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener(
                            'mouseenter',
                            _this.$swal.stopTimer
                        );
                        toast.addEventListener(
                            'mouseleave',
                            _this.$swal.resumeTimer
                        );
                    },
                });
                axios
                    .post(`${BASE_URL}editcompanyfiletype`, {
                        company_id: _this.authCompanyData.id,
                        company_unique_id:
                            _this.authCompanyData.company_unique_id,
                        id: _this.edit_file_type_form.id,
                        filetype_name: _this.edit_file_type_form.filetype_name,
                        file_description:
                            _this.edit_file_type_form.file_description,
                    })
                    .then(function() {
                        Toast.fire({
                            icon: 'success',
                            title:
                                'The file type has been updated successfully',
                        });
                        _this.edit_file_type_form.id = null;
                        _this.edit_file_type_form.filetype_name = '';
                        _this.edit_file_type_form.file_description = '';
                        _this.fetch_company_file_types();
                        _this.edit_file_type_dialog = false;
                    })
                    .catch(function(error) {
                        let errors = error.response.data.error || null;
                        Toast.fire({
                            icon: 'error',
                            title: errors
                                ? typeof errors === 'string'
                                    ? errors
                                    : null || errors.id
                                    ? errors.id[0]
                                    : null || errors.filetype_name
                                    ? errors.filetype_name[0]
                                    : null || errors.file_description
                                    ? errors.file_description[0]
                                    : null
                                : null ||
                                  error.response.data.error ||
                                  error.response.data.message ||
                                  "The file type couldn't be updated",
                        });
                    })
                    .then(function() {
                        _this.edit_file_type_submit = false;
                        _this.edit_file_type_text = 'Update';
                    });
            },
            delete_file_type(file_type) {
                let _this = this;

                const Toast = _this.$swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener(
                            'mouseenter',
                            _this.$swal.stopTimer
                        );
                        toast.addEventListener(
                            'mouseleave',
                            _this.$swal.resumeTimer
                        );
                    },
                });

                _this.$swal
                    .fire({
                        title:
                            'Are you sure you want to delete this file type [' +
                            file_type.filetype_name +
                            ']?',
                        showCancelButton: true,
                        confirmButtonText: `Yes, Delete`,
                    })
                    .then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            // _this.$swal.fire(number >= 2 ? 'Appraisals deleted!' : 'Appraisal deleted!', '', 'success')
                            axios
                                .post(`${BASE_URL}deletecompanyfiletype`, {
                                    ids: [
                                        {
                                            id: file_type.id,
                                        },
                                    ],
                                })
                                .then(function() {
                                    _this.fetch_company_file_types();
                                    Toast.fire({
                                        icon: 'success',
                                        title:
                                            'File Type [' +
                                            file_type.filetype_name +
                                            '] deleted successfully',
                                    });
                                })
                                .catch(function() {
                                    Toast.fire({
                                        icon: 'error',
                                        title:
                                            'File Type [' +
                                            file_type.filetype_name +
                                            "] couldn't be deleted",
                                    });
                                })
                                .then(function() {
                                    // _this.fetch_company_departments();
                                });
                        }
                    });
            },
            launch_edit_file_type(file_type) {
                this.edit_file_type_form.id = file_type.id;
                this.edit_file_type_form.filetype_name =
                    file_type.filetype_name;
                this.edit_file_type_form.file_description =
                    file_type.file_description;
                this.edit_file_type_dialog = true;
            },
            get_dept_name(dept_id) {
                let _this = this,
                    dept;
                if (_this.authCompanyDepartments) {
                    dept = _this.authCompanyDepartments.filter((dept) => {
                        return dept.id == dept_id;
                    });
                }
                if (dept.length >= 1) {
                    return dept[0].department_name;
                }
                return null;
            },
            fetch_ip_details() {
                let _this = this;
                axios
                    .post(`${BASE_URL}fetchcompanyip`, {
                        company_id: _this.authCompanyData.id,
                    })
                    .then(function(response) {
                        const RESPONSE = response.data;
                        _this.ips = RESPONSE.company_ips.map((element) => {
                            return {
                                id: element.id,
                                ip: element.ips,
                                edit_mode: false,
                                saveText: 'Save',
                                saveLoading: false,
                                deleteText: 'Delete',
                                deleteLoading: false,
                            };
                        });
                    })
                    .catch(function(error) {
                        console.log(error.response);
                    })
                    .then(function() {
                        _this.overlay = false;
                    });
            },
        },
        mounted() {
            let _this = this;
            document.title = 'Kylian ERP - Settings';
            _this.loadedAPIs();
            _this.fetch_all_leave_type();
            _this.fetch_ip_details();
            setTimeout(() => {
                _this.fetch_all_shifts().then(() => {
                    _this.loadedAPIs();
                });
            }, 2000);
        },
    };
</script>

<style scoped>
    .collapse:not(.show) {
        display: none !important;
    }
</style>
<style>
    .remove-message td .v-text-field__details {
        display: none !important;
    }

    .v-time-picker-clock__item--active {
        color: #fff !important;
        font-size: -webkit-xxx-large !important;
    }

    [class='v-picker__title primary'] {
        background-color: #069 !important;
    }

    .accent {
        background: #069 !important;
    }

    /* .theme--light.v-card {
background-color: #069 !important;
} */
    .create-ip:hover {
        color: #069 !important;
    }

    .ip-address div .v-text-field__details {
        display: none;
    }

    .ip-address div .v-input__slot {
        margin-bottom: 0px !important;
    }

    .ip-address .v-input__control {
        min-height: 35px !important;
    }
</style>
