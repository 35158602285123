import Vue from 'vue';
import './plugins/axios';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from '@/plugins/vuetify';
import IdleVue from 'idle-vue';
import swalPlugin from './plugins/VueSweetalert2';
import JsonExcel from 'vue-json-excel';
import VueHtmlToPaper from 'vue-html-to-paper';

// LOCAL APP
// export const BASE_URL = "http://127.0.0.1:8000/api/";
// export const ASSETS_URL = "http://127.0.0.1:8000";

// DEV APP
// export const BASE_URL = 'https://apiqa.kylianerp.com/api/';
// export const ASSETS_URL = 'https://apiqa.kylianerp.com';

// PLESK APP
export const BASE_URL = 'https://api.kylianerp.com/api/';
export const ASSETS_URL = 'https://api.kylianerp.com';

export const BLOG_URL = 'https://backoffice.kylianerp.com';

export const FLUTTERWAVE_URL = 'https://api.flutterwave.com/v3/';

const eventsHub = new Vue();

const printOptions = {
    name: '_blank',
    specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
    styles: [
        '/assets/print/bootstrap/4.0.0/css/bootstrap.min.css',
        '/assets/print/kidlat-css@0.0.20/css/kidlat.css',
    ],
    timeout: 1000, // default timeout before the print window appears
    windowTitle: window.document.title, // override the window title
};

Vue.mixin({
    computed: {
        mixinPrintPortraitOptions() {
            return printOptions;
        },
        mixinPrintLandscapeOptions() {
            const options = { ...printOptions };
            options.styles.splice(
                options.styles.length,
                0,
                '/assets/print/landscape.css'
            );
            return options;
        },
        monthNames() {
            return [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
            ];
        },
        mixinCompanyWorkDays() {
            if (store.getters.authCompanyAttendanceConfig) {
                return store.getters.authCompanyAttendanceConfig.working_days.split(
                    ','
                );
            } else {
                return [];
            }
        },
        mixinCurrentMonth() {
            const monthNames = [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
            ];
            let index = monthNames[new Date().getMonth()];
            return index;
        },
        lastMonth() {
            const monthNames = [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
            ];
            let currentDate = new Date(),
                currentYear = currentDate.getFullYear(),
                currentMonth = currentDate.getMonth(),
                result = {};
            if (currentMonth == 0) {
                result = {
                    month: monthNames[11],
                    year: currentYear - 1,
                };
            } else {
                result = {
                    month: monthNames[currentDate.getMonth() - 1],
                    year: currentYear,
                };
            }
            return result;
        },
    },
    methods: {
        mixinBranchEmployees(routeName) {
            const _this = this;
            let employees = [...store.getters.authCompanyEmployees];
            if (store.getters.authCompanyBranches.length) {
                if (
                    !(
                        store.getters.authUserData.user_type == 1 ||
                        ((_this.permissionIsAssigned('payroll-read') ||
                            _this.permissionIsAssigned('payroll-create') ||
                            _this.permissionIsAssigned('payroll-approve')) &&
                            (routeName.toLowerCase().includes('payroll') ||
                                routeName
                                    .toLowerCase()
                                    .includes('settings'))) ||
                        store.getters.authCompanyData.is_branch == 0
                    )
                ) {
                    employees = employees.filter((e) => {
                        return store.getters.authEmployeeDataForCompany.branch
                            ? store.getters.authEmployeeDataForCompany.branch ==
                                  e.branch
                            : true;
                    });
                }
            }
            return employees;
        },
        hasWorkflow(workflowName) {
            return (
                store.getters['authCompanyWorkflows'].filter(
                    (workflow) =>
                        workflow.service_name.toLowerCase() ===
                        workflowName.toLowerCase()
                ).length >= 1
            );
        },
        getDayOfWeek(day) {
            return store.state.weekDays[day];
        },
        getDatesBetweenDates(startDate, endDate) {
            let dates = [];
            //to avoid modifying the original date
            const theDate = new Date(startDate);
            while (theDate < endDate) {
                dates = [...dates, new Date(theDate)];
                theDate.setDate(theDate.getDate() + 1);
            }
            return dates;
        },
        getStyleByPattern(number) {
            const remainder = number % 3;
            if (remainder === 0) {
                return 'background: #B7D0E180;';
            } else if (remainder === 1) {
                return 'background: #EBE3DB80;';
            } else {
                return 'background: #EFCEAD80;';
            }
        },
        getSubsetAroundValue(arr, val) {
            const index = arr.indexOf(val);
            if (index === -1) return [];

            let startIndex = Math.max(0, index - 1);
            let endIndex = Math.min(arr.length - 1, index + 1);

            if (endIndex - startIndex < 3) {
                if (startIndex === 0) {
                    endIndex = Math.min(
                        endIndex + (3 - (endIndex - startIndex)),
                        arr.length - 1
                    );
                } else {
                    startIndex = Math.max(
                        startIndex - (3 - (endIndex - startIndex)),
                        0
                    );
                }
            }

            return arr.slice(startIndex, endIndex + 1);
        },
        check_all_services(service) {
            if (store.getters['authCompanyServices']) {
                for (
                    let index = 0;
                    index < store.getters['authCompanyServices'].length;
                    index++
                ) {
                    if (
                        store.getters['authCompanyServices'][index]
                            .service_name == service
                    ) {
                        return true;
                    }
                    if (
                        index ==
                        store.getters['authCompanyServices'].length - 1
                    ) {
                        return false;
                    }
                }
            } else {
                return false;
            }
        },
        permissionIsAssigned(permission_name) {
            let truth = false,
                permission = null,
                assignedPermissions = null;
            if (store.getters['authCompanyPermissions'].length >= 1) {
                permission = store.getters['authCompanyPermissions'].filter(
                    (cp) => {
                        return cp.name == permission_name;
                    }
                );
                if (permission.length >= 1) {
                    permission = permission[0];
                    assignedPermissions = store.getters[
                        'authAssignedPermissions'
                    ].filter((ap) => {
                        return permission.id == ap.permission_id;
                    });
                    if (assignedPermissions.length >= 1) {
                        assignedPermissions.forEach((ap) => {
                            if (ap.role_id == store.getters['userType']) {
                                truth = true;
                            }
                        });
                    }
                }
            }
            return truth;
        },
        getMonthWithIndex(index) {
            const monthNames = [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
            ];
            return monthNames[index];
        },
    },
});

Vue.use(VueHtmlToPaper, printOptions);

// or, using the defaults with no stylesheet
// Vue.use(VueHtmlToPaper);

// Vue.filter("number_with_commas", function (t) {
//   return t.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
// });

Vue.filter('number_with_commas', function(t) {
    let number, split, int, dec, final;
    number = t.toString();
    split = number.split('.');
    int = split[0];
    if (split.length == 1) {
        final = int.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return final;
    } else {
        dec = split[1];
        final =
            int.replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
            '.' +
            dec.substring(0, 2);
        return final;
    }

    // let int = t.toString().split('.')[0];
    // let dec = t.toString().split('.')[1].substring(0, 2);
    // let final = int.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '.' + dec
    // return final;
});

Vue.filter('ordinal_suffix', function(i) {
    if (!i || typeof i !== 'number') return '';
    let j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + 'st';
    }
    if (j === 2 && k !== 12) {
        return i + 'nd';
    }
    if (j === 3 && k !== 13) {
        return i + 'rd';
    }
    return i + 'th';
});

Vue.filter('get_month', function(m) {
    if (m === 1) return 'January';
    if (m === 2) return 'February';
    if (m === 3) return 'March';
    if (m === 4) return 'April';
    if (m === 5) return 'May';
    if (m === 6) return 'June';
    if (m === 7) return 'July';
    if (m === 8) return 'August';
    if (m === 9) return 'September';
    if (m === 10) return 'October';
    if (m === 11) return 'November';
    if (m === 12) return 'December';
});

Vue.filter('blogDateFilter', function(date) {
    const currentDate = new Date(date);
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    const month = months[currentDate.getMonth()];
    const day = currentDate.getDate();
    const year = currentDate.getFullYear();
    const formattedDate = `${month} ${day}, ${year}`;
    return formattedDate;
});

Vue.filter('am_pm', function(time) {
    let hour = parseInt(time.substr(0, 2));
    if (hour >= 1 && hour <= 12) return hour + ':' + time.substr(3, 5) + 'AM';
    if (hour === 0) return 12 + ':' + time.substr(3, 5) + 'AM';
    if (hour >= 13 && hour <= 23)
        return hour - 12 + ':' + time.substr(3, 5) + 'PM';
});

Vue.component('downloadExcel', JsonExcel);

Vue.use(IdleVue, {
    eventEmitter: eventsHub,
    store,
    idleTime: 3600000, // 60 minutes
    startAtIdle: false,
});

Vue.use(swalPlugin);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount('#app');
