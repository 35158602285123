<template>
    <div>
        <!-- <SideMenu /> -->
        <div
            v-if="!overlay"
            class="main-content-wrap sidenav-open d-flex flex-column"
        >
            <div class="main-header pl-4 d-print-none" style="z-index: 60;">
                <div class="row">
                    <div
                        style="align-self: center;"
                        class="logo col-md-1 col-lg-1 col-12 py-0"
                    >
                        <div
                            v-if="view == 'date' && !shiftConfig"
                            class="dropdown animate__animated animate__fadeIn"
                            style="font-size: 120%;"
                        >
                            <i
                                @click="
                                    hasHistory()
                                        ? $router.go(-1)
                                        : $router.push('/')
                                "
                                style="cursor: pointer; position: relative; top: 2px; color: #069;"
                                class="fal fa-arrow-alt-circle-left mr-1"
                            ></i>
                            <span
                                class="ml-2"
                                style="top: 4%; position: relative;"
                                >Back</span
                            >
                        </div>
                        <div
                            v-else-if="view == 'day' && !shiftConfig"
                            class="dropdown animate__animated animate__fadeIn"
                            style="font-size: 120%;"
                        >
                            <i
                                @click="view = 'date'"
                                style="cursor: pointer; position: relative; top: 2px; color: #069;"
                                class="fal fa-arrow-alt-circle-left mr-1"
                            ></i>
                            <span
                                class="ml-2"
                                style="top: 4%; position: relative;"
                                >Back</span
                            >
                        </div>
                        <div
                            v-else-if="view == 'employee' && !shiftConfig"
                            class="dropdown animate__animated animate__fadeIn"
                            style="font-size: 120%;"
                        >
                            <i
                                @click="view = 'day'"
                                style="cursor: pointer; position: relative; top: 2px; color: #069;"
                                class="fal fa-arrow-alt-circle-left mr-1"
                            ></i>
                            <span
                                class="ml-2"
                                style="top: 4%; position: relative;"
                                >Back</span
                            >
                        </div>
                        <div
                            v-else
                            class="dropdown animate__animated animate__fadeIn"
                            style="font-size: 120%;"
                        >
                            <i
                                @click="(view = 'date'), (shiftConfig = false)"
                                style="cursor: pointer; position: relative; top: 2px; color: #069;"
                                class="fal fa-arrow-alt-circle-left mr-1"
                            ></i>
                            <span
                                class="ml-2"
                                style="top: 4%; position: relative;"
                                >Back</span
                            >
                        </div>
                    </div>

                    <div
                        v-if="
                            userType != 1 &&
                                permissionIsAssigned('attendance-view')
                        "
                        class="input-group-prepend"
                    >
                        <button
                            class="btn dropdown-toggle w-100"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <span
                                :style="
                                    $vuetify.breakpoint.name == 'xs'
                                        ? 'margin-left: 1.5rem;'
                                        : $vuetify.breakpoint.name == 'sm'
                                        ? 'margin-left: 5rem;'
                                        : null
                                "
                                >{{
                                    employeeView
                                        ? 'Your Attendance History'
                                        : "All Employees' Attendance"
                                }}</span
                            >
                        </button>
                        <div
                            v-if="permissionIsAssigned('attendance-view')"
                            class="dropdown-menu"
                            style="min-width: 13rem;"
                        >
                            <a
                                @click="
                                    (employeeView = !employeeView),
                                        employeeView == true
                                            ? $router.push({
                                                  name: 'LogReport',
                                              })
                                            : null
                                "
                                class="dropdown-item"
                                style="cursor: pointer;"
                            >
                                {{
                                    employeeView
                                        ? "All Employees' Attendance"
                                        : 'Your Attendance History'
                                }}
                            </a>
                        </div>
                    </div>
                </div>
                <div style="margin: auto"></div>
                <div
                    class="header-part-right"
                    :class="
                        $vuetify.breakpoint.name == 'xs' ||
                        $vuetify.breakpoint.name == 'sm'
                            ? null
                            : 'pr-5'
                    "
                >
                    <!-- Grid menu Dropdown -->
                    <div v-if="userType != 1" class="dropdown">
                        <button
                            type="button"
                            class="btn btn-outline-secondary m-1"
                        >
                            Apply Leave
                        </button>
                    </div>
                    <!-- Notificaiton -->
                    <div class="dropdown">
                        <button
                            v-if="userType != '1'"
                            type="button"
                            class="btn btn-outline-secondary m-1"
                            @click="$router.push({ name: 'LogReport' })"
                        >
                            Log Time
                        </button>
                        <button
                            v-if="
                                (userType == '1' ||
                                    (userType != 1 &&
                                        !employeeView &&
                                        permissionIsAssigned(
                                            'attendance-read'
                                        ))) &&
                                    shiftConfig
                            "
                            type="button"
                            class="btn btn-outline-secondary m-1"
                            @click="shiftConfig = false"
                        >
                            Attendance History
                        </button>
                        <button
                            v-if="
                                (userType == '1' ||
                                    (userType != 1 &&
                                        !employeeView &&
                                        permissionIsAssigned(
                                            'attendance-config-read'
                                        ))) &&
                                    !shiftConfig
                            "
                            type="button"
                            class="btn btn-outline-secondary m-1"
                            @click="shiftConfig = true"
                        >
                            Assign Shifts
                        </button>
                    </div>
                    <!-- Notificaiton End -->
                    <!-- User avatar dropdown -->
                </div>
            </div>
            <!-- ======== Body content start ========= -->
            <div
                v-if="view == 'date' && !shiftConfig"
                class="main-content pt-0"
                :class="
                    $vuetify.breakpoint.name == 'xs' ||
                    $vuetify.breakpoint.name == 'sm'
                        ? 'px-0'
                        : null
                "
            >
                <div class="breadcrumb" style="margin-top: 1rem;">
                    <div class="row">
                        <h1 class="my-0 col-md-12 pb-0">
                            <span class="col-4">Attendance</span>
                            <v-row
                                v-if="
                                    $vuetify.breakpoint.name != 'xs' &&
                                        $vuetify.breakpoint.name != 'sm'
                                "
                                style="float: right;"
                            >
                                <v-col offset="4" cols="4" class="pt-0">
                                    <v-select
                                        class="remove-mb"
                                        :items="allYears"
                                        label="Year"
                                        dense
                                        solo
                                        messages=" *Filter by year"
                                        :full-width="true"
                                        v-model="yearSelected"
                                        @change="update_checking_details()"
                                    ></v-select>
                                </v-col>
                                <v-col cols="4" class="pt-0">
                                    <v-select
                                        class="remove-mb"
                                        :items="monthNames"
                                        label="Month"
                                        dense
                                        solo
                                        messages="*Filter by month"
                                        :full-width="true"
                                        v-model="monthSelected"
                                        @change="update_checking_details()"
                                    ></v-select>
                                </v-col>
                            </v-row>
                        </h1>
                        <v-row
                            class="px-3 mt-3"
                            v-if="
                                $vuetify.breakpoint.name == 'xs' ||
                                    $vuetify.breakpoint.name == 'sm'
                            "
                        >
                            <v-col cols="6" class="pt-0">
                                <v-select
                                    class="remove-mb"
                                    :items="allYears"
                                    label="Year"
                                    dense
                                    solo
                                    messages=" *Filter by year"
                                    :full-width="true"
                                    v-model="yearSelected"
                                    @change="update_checking_details()"
                                ></v-select>
                            </v-col>
                            <v-col cols="6" class="pt-0">
                                <v-select
                                    class="remove-mb"
                                    :items="monthNames"
                                    label="Month"
                                    dense
                                    solo
                                    messages="*Filter by month"
                                    :full-width="true"
                                    v-model="monthSelected"
                                    @change="update_checking_details()"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <div class="col-md-12">
                            <div class="card" style="border-radius: 10px;">
                                <div class="card-body px-1 py-1 pt-0 pr-0">
                                    <div
                                        class="table-responsive"
                                        style="border-radius: 10px; height: 75vh;
                                        overflow-y: scroll;
                                        padding-top: 0;
                                        padding-bottom: 0;"
                                    >
                                        <table class="table table-hover">
                                            <thead>
                                                <tr
                                                    style="border: 2px solid #4440;"
                                                >
                                                    <th scope="col">Date</th>
                                                    <th scope="col">
                                                        Attended
                                                    </th>
                                                    <th scope="col">Absent</th>
                                                    <th scope="col">
                                                        Presence
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    v-for="detail in attendance"
                                                    :key="detail.day"
                                                >
                                                    <th
                                                        class="underline"
                                                        scope="row"
                                                        @click="
                                                            load_day(detail.day)
                                                        "
                                                    >
                                                        {{ detail.day }}
                                                    </th>
                                                    <td>
                                                        {{ detail.attended }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            /*authCompanyAllCheckin.employees_count*/
                                                            mixinBranchEmployees(
                                                                $route.name
                                                            ).length -
                                                                detail.attended
                                                        }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            Math.floor(
                                                                (parseInt(
                                                                    detail.attended
                                                                ) /
                                                                    parseInt(
                                                                        authCompanyAllCheckin.employees_count -
                                                                            1
                                                                    )) *
                                                                    100
                                                            ) > 100
                                                                ? 100
                                                                : Math.floor(
                                                                      (parseInt(
                                                                          detail.attended
                                                                      ) /
                                                                          parseInt(
                                                                              authCompanyAllCheckin.employees_count -
                                                                                  1
                                                                          )) *
                                                                          100
                                                                  )
                                                        }}%
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <!-- ICON BG-->
                </div>
            </div>
            <div
                id="printDayAttendance"
                v-else-if="view == 'day' && !shiftConfig"
                class="main-content pt-0"
                :class="
                    $vuetify.breakpoint.name == 'xs' ||
                    $vuetify.breakpoint.name == 'sm'
                        ? 'px-0'
                        : null
                "
            >
                <div class="breadcrumb" style="margin-top: 1rem;">
                    <div class="row">
                        <h1 class="my-0 col-md-12 pb-0">
                            <span
                                >Attendance on
                                {{ selected_date || day_selected }}</span
                            >
                            <v-row class="d-print-none" style="float: right;">
                                <v-col cols="3" class="pt-0">
                                    <download-excel
                                        :data="json_data"
                                        :fields="json_fields"
                                        :worksheet="
                                            'Attendance on ' +
                                                (day_selected || selected_date)
                                        "
                                        :name="
                                            'Attendance on ' +
                                                (day_selected ||
                                                    selected_date) +
                                                '.xls'
                                        "
                                        class="btn btn-outline-secondary"
                                        style="margin-top: 2px;"
                                    >
                                        Export to Excel
                                    </download-excel>
                                </v-col>
                                <v-col cols="3" class="pt-0">
                                    <button
                                        @click="printDayAttendance()"
                                        type="button"
                                        class="w-100 btn btn-outline-secondary"
                                        style="margin-top: 2px;"
                                    >
                                        Print
                                    </button>
                                </v-col>
                                <v-col cols="3" class="pt-0">
                                    <v-menu
                                        ref="menu"
                                        v-model="menu"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-text-field
                                                color="#069"
                                                prepend-inner-icon="mdi-calendar-month-outline"
                                                v-model="day_selected"
                                                label="Select Date"
                                                messages="*Filter by day"
                                                solo
                                                dense
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                            </v-text-field>
                                        </template>
                                        <v-date-picker
                                            color="#069"
                                            ref="picker"
                                            v-model="day_selected"
                                            :min="
                                                new Date(
                                                    Date.parse(
                                                        new Date(
                                                            allYears[
                                                                allYears.length -
                                                                    1
                                                            ]
                                                        )
                                                    )
                                                )
                                                    .toISOString()
                                                    .substring(0, 10)
                                            "
                                            :max="
                                                new Date(Date.parse(new Date()))
                                                    .toISOString()
                                                    .substring(0, 10)
                                            "
                                            @change="save"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="3" class="pt-0">
                                    <button
                                        @click.prevent="viewAll()"
                                        type="button"
                                        style="margin-top: 2px;"
                                        class="btn btn-md btn-raised ripple btn-raised-secondary text-white"
                                    >
                                        View All
                                    </button>
                                </v-col>
                            </v-row>
                        </h1>

                        <div class="col-md-12">
                            <div class="card" style="border-radius: 10px;">
                                <div class="card-body px-1 py-1 pt-0 pr-0">
                                    <div
                                        class="table-responsive"
                                        style="border-radius: 10px;
                                        padding-top: 0;
                                        padding-bottom: 0;"
                                    >
                                        <table class="table table-hover">
                                            <thead>
                                                <tr
                                                    style="border: 2px solid #4440;"
                                                >
                                                    <th
                                                        class="text-left"
                                                        scope="col"
                                                    >
                                                        Employee name
                                                    </th>
                                                    <th
                                                        class="text-left"
                                                        scope="col"
                                                    >
                                                        Department
                                                    </th>
                                                    <th
                                                        class="text-center"
                                                        scope="col"
                                                    >
                                                        Status
                                                    </th>
                                                    <th
                                                        class="text-left"
                                                        scope="col"
                                                    >
                                                        Check-in
                                                    </th>
                                                    <th
                                                        class="text-left"
                                                        scope="col"
                                                    >
                                                        Check-out
                                                    </th>
                                                    <th
                                                        class="text-left"
                                                        scope="col"
                                                    >
                                                        Work time
                                                    </th>
                                                    <th
                                                        class="text-left"
                                                        scope="col"
                                                    >
                                                        Check-in note
                                                    </th>
                                                    <th
                                                        class="text-left"
                                                        scope="col"
                                                    >
                                                        Check-out note
                                                    </th>
                                                    <th
                                                        class="text-left"
                                                        scope="col"
                                                    >
                                                        Check-in address
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    v-for="detail in day_attendance"
                                                    :key="detail.id"
                                                >
                                                    <th
                                                        class="underline text-left"
                                                        scope="row"
                                                        @click="
                                                            load_employee(
                                                                detail
                                                            )
                                                        "
                                                    >
                                                        {{
                                                            detail.employee_fristname +
                                                                ' ' +
                                                                detail.employee_lastname
                                                        }}
                                                    </th>
                                                    <td class="text-left">
                                                        {{
                                                            detail.department_id
                                                                ? get_dept_name(
                                                                      detail.department_id
                                                                  )
                                                                : 'No assigned department'
                                                        }}
                                                    </td>
                                                    <td class="text-center">
                                                        <span
                                                            v-if="
                                                                detail.status ==
                                                                    'early'
                                                            "
                                                            class="badge badge-round-success sm"
                                                            style="width: 13px; height: 13px; vertical-align: middle; border: 2px solid #4caf50;"
                                                        >
                                                            <span></span>
                                                        </span>
                                                        <span
                                                            v-if="
                                                                detail.status ==
                                                                    'grace'
                                                            "
                                                            class="badge badge-round-warning sm"
                                                            style="width: 13px; height: 13px; vertical-align: middle; border: 2px solid #4caf50;"
                                                        >
                                                            <span></span>
                                                        </span>
                                                        <span
                                                            v-if="
                                                                detail.status ==
                                                                    'late'
                                                            "
                                                            class="badge badge-round-warning sm"
                                                            style="width: 13px; height: 13px; vertical-align: middle; border: 2px solid #ffc107;"
                                                        >
                                                            <span></span>
                                                        </span>
                                                        <span
                                                            v-if="
                                                                detail.status ==
                                                                    'on leave'
                                                            "
                                                            class="badge badge-round-info sm"
                                                            style="width: 13px; height: 13px; vertical-align: middle; border: 2px solid #003473;"
                                                        >
                                                            <span></span>
                                                        </span>
                                                        <span
                                                            v-if="
                                                                detail.status ==
                                                                    'absent'
                                                            "
                                                            class="badge badge-round-danger sm"
                                                            style="width: 13px; height: 13px; vertical-align: middle; border: 2px solid #f44336;"
                                                        >
                                                            <span></span>
                                                        </span>
                                                    </td>
                                                    <td
                                                        class="text-left"
                                                        v-if="detail.check_in"
                                                    >
                                                        {{
                                                            detail.check_in.split(
                                                                ' '
                                                            ).length == 1
                                                                ? detail.check_in
                                                                : detail.check_in.split(
                                                                      ' '
                                                                  )[1]
                                                        }}
                                                    </td>
                                                    <td
                                                        class="text-left"
                                                        v-else
                                                    >
                                                        <i>No check in</i>
                                                    </td>
                                                    <td
                                                        class="text-left"
                                                        v-if="detail.check_out"
                                                    >
                                                        {{
                                                            detail.check_out.split(
                                                                ' '
                                                            ).length == 1
                                                                ? detail.check_out
                                                                : detail.check_out.split(
                                                                      ' '
                                                                  )[1]
                                                        }}
                                                    </td>
                                                    <td
                                                        class="text-left"
                                                        v-else
                                                    >
                                                        <i>No checkout</i>
                                                    </td>
                                                    <td
                                                        class="text-left"
                                                        v-if="detail.duration"
                                                    >
                                                        {{ detail.duration }}
                                                    </td>
                                                    <td
                                                        class="text-left"
                                                        v-else
                                                    >
                                                        <i>No duration</i>
                                                    </td>
                                                    <td
                                                        class="text-left"
                                                        v-if="
                                                            detail.punch_in_note
                                                        "
                                                    >
                                                        {{
                                                            detail.punch_in_note
                                                        }}
                                                    </td>
                                                    <td
                                                        class="text-left"
                                                        v-else
                                                    >
                                                        <i>No check in note</i>
                                                    </td>
                                                    <td
                                                        class="text-left"
                                                        v-if="
                                                            detail.punch_out_note
                                                        "
                                                    >
                                                        {{
                                                            detail.punch_out_note
                                                        }}
                                                    </td>
                                                    <td
                                                        class="text-left"
                                                        v-else
                                                    >
                                                        <i>No checkout note</i>
                                                    </td>
                                                    <td
                                                        class="text-left"
                                                        v-if="
                                                            detail.checkinaddress
                                                        "
                                                    >
                                                        {{
                                                            detail.checkinaddress
                                                        }}
                                                    </td>
                                                    <td
                                                        class="text-left"
                                                        v-else
                                                    >
                                                        <i>Not indicated</i>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <!-- ICON BG-->
                </div>
            </div>
            <div
                id="printEmployeeAttendance"
                v-else-if="view == 'employee' && !shiftConfig"
                class="main-content pt-0"
                :class="
                    $vuetify.breakpoint.name == 'xs' ||
                    $vuetify.breakpoint.name == 'sm'
                        ? 'px-0'
                        : null
                "
            >
                <div class="breadcrumb" style="margin-top: 1rem;">
                    <div class="row">
                        <h1 class="my-0 col-md-12 pb-0">
                            <span
                                >Attendance Report For
                                {{
                                    employee_name ||
                                        employee.employee_fristname +
                                            ' ' +
                                            employee.employee_lastname
                                }}</span
                            >
                            <span class="d-print-block d-none">
                                ({{ monthSelected + ', ' + yearSelected }})
                            </span>
                            <v-row
                                class="d-print-none mt-5"
                                :class="
                                    $vuetify.breakpoint.name == 'xs' ||
                                    $vuetify.breakpoint.name == 'sm'
                                        ? 'mt-5'
                                        : null
                                "
                                style="float: right;"
                            >
                                <v-col
                                    offset-lg="1"
                                    offset-md="1"
                                    offset-xl="1"
                                    lg="3"
                                    md="3"
                                    xl="3"
                                    cols="6"
                                    class="pt-0"
                                >
                                    <v-combobox
                                        class="remove-mb"
                                        :items="allEmployees"
                                        placeholder="Employee"
                                        dense
                                        solo
                                        hint="*Select Employee"
                                        :full-width="true"
                                        v-model="employee_name"
                                        @change="
                                            update_single_checking_details()
                                        "
                                    ></v-combobox>
                                </v-col>
                                <v-col
                                    lg="2"
                                    md="2"
                                    xl="2"
                                    cols="6"
                                    class="pt-0"
                                >
                                    <v-select
                                        class="remove-mb"
                                        :items="allYears"
                                        label="Year"
                                        dense
                                        solo
                                        messages=" *Filter by year"
                                        :full-width="true"
                                        v-model="yearSelected"
                                        @change="
                                            update_single_checking_details()
                                        "
                                    ></v-select>
                                </v-col>
                                <v-col
                                    lg="2"
                                    md="2"
                                    xl="2"
                                    cols="6"
                                    class="pt-0"
                                >
                                    <v-select
                                        class="remove-mb"
                                        :items="monthNames"
                                        label="Month"
                                        dense
                                        solo
                                        messages="*Filter by month"
                                        :full-width="true"
                                        v-model="monthSelected"
                                        @change="
                                            update_single_checking_details()
                                        "
                                    ></v-select>
                                </v-col>
                                <v-col
                                    lg="1"
                                    md="1"
                                    xl="1"
                                    cols="6"
                                    class="pt-0"
                                >
                                    <button
                                        @click.prevent="viewAll()"
                                        type="button"
                                        style="margin-top: 2px;"
                                        class="btn btn-md btn-raised ripple btn-raised-secondary text-white"
                                    >
                                        View All
                                    </button>
                                </v-col>
                                <v-col
                                    lg="2"
                                    md="2"
                                    xl="2"
                                    cols="6"
                                    class="pt-0"
                                >
                                    <download-excel
                                        :data="employee_json_data"
                                        :fields="employee_json_fields"
                                        :worksheet="employeeExcelSheetName"
                                        :name="employeeExcelSheetName + '.xls'"
                                        class="btn btn-outline-secondary"
                                        style="margin-top: 2px;"
                                    >
                                        Export to Excel
                                    </download-excel>
                                </v-col>
                                <v-col
                                    lg="1"
                                    md="1"
                                    xl="1"
                                    cols="6"
                                    class="pt-0"
                                >
                                    <button
                                        @click="printEmployeeAttendance()"
                                        type="button"
                                        class="btn btn-outline-secondary"
                                        style="margin-top: 2px;"
                                    >
                                        Print
                                    </button>
                                </v-col>
                            </v-row>
                        </h1>

                        <div class="col-md-6 col-12 d-print-none">
                            <div class="mb-4">
                                <div class="card-body py-0">
                                    <div
                                        id="basicPie"
                                        style="height: 500px;"
                                    ></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-12 d-print-none">
                            <div class="card" style="border-radius: 10px;">
                                <div class="card-body px-1 py-1 pt-0 pr-0">
                                    <div
                                        class="table-responsive"
                                        style="border-radius: 10px;
                                        padding-top: 0;
                                        padding-bottom: 0;"
                                    >
                                        <table class="table table-hover">
                                            <thead>
                                                <tr
                                                    style="border: 2px solid #4440;"
                                                >
                                                    <th scope="col">Date</th>
                                                    <th scope="col">
                                                        Check-in
                                                    </th>
                                                    <th scope="col">
                                                        Check-out
                                                    </th>
                                                    <th scope="col">
                                                        Duration
                                                    </th>
                                                    <th scope="col">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody
                                                v-if="
                                                    finalCheckingSingleDetails.length
                                                "
                                            >
                                                <tr
                                                    v-for="detail in finalCheckingSingleDetails"
                                                    :key="detail.id"
                                                >
                                                    <th
                                                        @click="
                                                            (currentDetails = detail),
                                                                (commentDialog = true)
                                                        "
                                                        class="underline"
                                                        scope="row"
                                                    >
                                                        {{
                                                            new Date(
                                                                detail.day
                                                            ).toDateString()
                                                        }}
                                                    </th>
                                                    <td v-if="detail.check_in">
                                                        {{
                                                            detail.check_in.split(
                                                                ' '
                                                            ).length == 1
                                                                ? detail.check_in
                                                                : detail.check_in.split(
                                                                      ' '
                                                                  )[1]
                                                        }}
                                                    </td>
                                                    <td v-else>
                                                        <i>No check in</i>
                                                    </td>
                                                    <td v-if="detail.check_out">
                                                        {{
                                                            detail.check_out.split(
                                                                ' '
                                                            ).length == 1
                                                                ? detail.check_out
                                                                : detail.check_out.split(
                                                                      ' '
                                                                  )[1]
                                                        }}
                                                    </td>
                                                    <td v-else>
                                                        <i>No check out</i>
                                                    </td>
                                                    <td v-if="detail.check_out">
                                                        {{ detail.duration }}
                                                    </td>
                                                    <td v-else>
                                                        <i>No duration</i>
                                                    </td>
                                                    <td>
                                                        <span
                                                            v-if="
                                                                detail.status ==
                                                                    'early'
                                                            "
                                                            class="badge badge-round-success sm"
                                                            style="width: 13px; height: 13px; vertical-align: middle; border: 2px solid #4caf50;"
                                                        >
                                                            <span></span>
                                                        </span>
                                                        <span
                                                            v-if="
                                                                detail.status ==
                                                                    'grace'
                                                            "
                                                            class="badge badge-round-warning sm"
                                                            style="width: 13px; height: 13px; vertical-align: middle; border: 2px solid #4caf50;"
                                                        >
                                                            <span></span>
                                                        </span>
                                                        <span
                                                            v-if="
                                                                detail.status ==
                                                                    'late'
                                                            "
                                                            class="badge badge-round-warning sm"
                                                            style="width: 13px; height: 13px; vertical-align: middle; border: 2px solid #ffc107;"
                                                        >
                                                            <span></span>
                                                        </span>
                                                        <span
                                                            v-if="
                                                                detail.status ==
                                                                    'on leave'
                                                            "
                                                            class="badge badge-round-info sm"
                                                            style="width: 13px; height: 13px; vertical-align: middle; border: 2px solid #003473;"
                                                        >
                                                            <span></span>
                                                        </span>
                                                        <span
                                                            v-if="
                                                                detail.status ==
                                                                    'absent'
                                                            "
                                                            class="badge badge-round-danger sm"
                                                            style="width: 13px; height: 13px; vertical-align: middle; border: 2px solid #f44336;"
                                                        >
                                                            <span></span>
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody v-else></tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 d-print-block d-none">
                            <div class="card" style="border-radius: 10px;">
                                <div class="card-body px-1 py-1 pt-0 pr-0">
                                    <div
                                        class="table-responsive"
                                        style="border-radius: 10px;
                                        padding-top: 0;
                                        padding-bottom: 0;"
                                    >
                                        <table class="table table-hover">
                                            <thead>
                                                <tr
                                                    style="border: 2px solid #4440;"
                                                >
                                                    <th scope="col">Date</th>
                                                    <th scope="col">
                                                        Check-in
                                                    </th>
                                                    <th scope="col">
                                                        Check-out
                                                    </th>
                                                    <th scope="col">
                                                        Duration
                                                    </th>
                                                    <th scope="col">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody
                                                v-if="
                                                    finalCheckingSingleDetails.length
                                                "
                                            >
                                                <tr
                                                    v-for="detail in finalCheckingSingleDetails"
                                                    :key="detail.id"
                                                >
                                                    <th
                                                        @click="
                                                            (currentDetails = detail),
                                                                (commentDialog = true)
                                                        "
                                                        class="underline"
                                                        scope="row"
                                                    >
                                                        {{
                                                            new Date(
                                                                detail.day
                                                            ).toDateString()
                                                        }}
                                                    </th>
                                                    <td v-if="detail.check_in">
                                                        {{
                                                            detail.check_in.split(
                                                                ' '
                                                            ).length == 1
                                                                ? detail.check_in
                                                                : detail.check_in.split(
                                                                      ' '
                                                                  )[1]
                                                        }}
                                                    </td>
                                                    <td v-else>
                                                        <i>No check in</i>
                                                    </td>
                                                    <td v-if="detail.check_out">
                                                        {{
                                                            detail.check_out.split(
                                                                ' '
                                                            ).length == 1
                                                                ? detail.check_out
                                                                : detail.check_out.split(
                                                                      ' '
                                                                  )[1]
                                                        }}
                                                    </td>
                                                    <td v-else>
                                                        <i>No check out</i>
                                                    </td>
                                                    <td v-if="detail.check_out">
                                                        {{ detail.duration }}
                                                    </td>
                                                    <td v-else>
                                                        <i>No duration</i>
                                                    </td>
                                                    <td>
                                                        <span
                                                            v-if="
                                                                detail.status ==
                                                                    'early'
                                                            "
                                                            class="badge badge-round-success sm"
                                                            style="width: 13px; height: 13px; vertical-align: middle; border: 2px solid #4caf50;"
                                                        >
                                                            <span></span>
                                                        </span>
                                                        <span
                                                            v-if="
                                                                detail.status ==
                                                                    'grace'
                                                            "
                                                            class="badge badge-round-warning sm"
                                                            style="width: 13px; height: 13px; vertical-align: middle; border: 2px solid #4caf50;"
                                                        >
                                                            <span></span>
                                                        </span>
                                                        <span
                                                            v-if="
                                                                detail.status ==
                                                                    'late'
                                                            "
                                                            class="badge badge-round-warning sm"
                                                            style="width: 13px; height: 13px; vertical-align: middle; border: 2px solid #ffc107;"
                                                        >
                                                            <span></span>
                                                        </span>
                                                        <span
                                                            v-if="
                                                                detail.status ==
                                                                    'on leave'
                                                            "
                                                            class="badge badge-round-info sm"
                                                            style="width: 13px; height: 13px; vertical-align: middle; border: 2px solid #003473;"
                                                        >
                                                            <span></span>
                                                        </span>
                                                        <span
                                                            v-if="
                                                                detail.status ==
                                                                    'absent'
                                                            "
                                                            class="badge badge-round-danger sm"
                                                            style="width: 13px; height: 13px; vertical-align: middle; border: 2px solid #f44336;"
                                                        >
                                                            <span></span>
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody v-else></tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <!-- ICON BG-->
                </div>
            </div>
            <div
                v-else
                class="main-content pt-0"
                :class="
                    $vuetify.breakpoint.name == 'xs' ||
                    $vuetify.breakpoint.name == 'sm'
                        ? 'px-0'
                        : null
                "
            >
                <div class="breadcrumb" style="margin-top: 1rem;">
                    <div class="row">
                        <h1 class="my-0 col-md-12 pb-3">
                            <span>Employee Shifts</span>
                            <v-row style="float: right;">
                                <v-col cols="12" class="pt-0">
                                    <button
                                        v-if="ids.length >= 1"
                                        @click="remove_from_shift()"
                                        style="float: right;"
                                        type="button"
                                        class="btn btn-sm btn-danger m-1 text-white btn-raised ripple"
                                    >
                                        Remove From Shift
                                    </button>
                                    <button
                                        v-if="ids.length >= 1"
                                        @click="assign_shift_dialog = true"
                                        style="float: right;"
                                        type="button"
                                        class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple"
                                    >
                                        Add To Shift
                                    </button>
                                </v-col>
                            </v-row>
                        </h1>

                        <div class="col-md-12">
                            <div class="card" style="border-radius: 10px;">
                                <div class="card-body px-1 py-1 pt-0 pr-0">
                                    <div
                                        class="table-responsive"
                                        style="border-radius: 10px; height: 75vh;
                                        overflow-y: scroll;
                                        padding-top: 0;
                                        padding-bottom: 0;"
                                    >
                                        <table class="table table-hover">
                                            <thead>
                                                <tr
                                                    style="border: 2px solid #4440;"
                                                >
                                                    <th
                                                        style="width: 80px; padding-left: 2.4%;"
                                                    >
                                                        <label
                                                            class="checkbox checkbox-secondary mb-3"
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                :checked="
                                                                    ids.length ==
                                                                        mixinBranchEmployees(
                                                                            $route.name
                                                                        )
                                                                            .length &&
                                                                        mixinBranchEmployees(
                                                                            $route.name
                                                                        )
                                                                            .length !=
                                                                            0
                                                                "
                                                                @change="
                                                                    check_all()
                                                                "
                                                            />
                                                            <span
                                                                class="checkmark"
                                                            ></span>
                                                        </label>
                                                    </th>
                                                    <th
                                                        class="text-left"
                                                        scope="col"
                                                    >
                                                        Employee Name
                                                    </th>
                                                    <th
                                                        class="text-left"
                                                        scope="col"
                                                    >
                                                        Employee E-mail
                                                    </th>
                                                    <th
                                                        class="text-left"
                                                        scope="col"
                                                    >
                                                        Department
                                                    </th>
                                                    <th
                                                        class="text-left"
                                                        scope="col"
                                                    >
                                                        Designation
                                                    </th>
                                                    <th
                                                        class="text-left"
                                                        scope="col"
                                                    >
                                                        Shift Name
                                                    </th>
                                                    <th
                                                        class="text-left"
                                                        scope="col"
                                                    >
                                                        Resumption Time
                                                    </th>
                                                    <th
                                                        class="text-left"
                                                        scope="col"
                                                    >
                                                        Closing Time
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    v-for="e in mixinBranchEmployees(
                                                        $route.name
                                                    )"
                                                    :key="e.id"
                                                >
                                                    <th
                                                        scope="row"
                                                        style="padding-left: 2.5%;"
                                                    >
                                                        <label
                                                            class="checkbox checkbox-secondary"
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                :checked="
                                                                    ids.indexOf(
                                                                        e.id
                                                                    ) != -1
                                                                "
                                                                @change="
                                                                    ids.indexOf(
                                                                        e.id
                                                                    ) == -1
                                                                        ? ids.splice(
                                                                              ids.length -
                                                                                  1,
                                                                              0,
                                                                              e.id
                                                                          )
                                                                        : ids.splice(
                                                                              ids.indexOf(
                                                                                  e.id
                                                                              ),
                                                                              1
                                                                          )
                                                                "
                                                            />
                                                            <span
                                                                class="checkmark"
                                                            ></span>
                                                        </label>
                                                    </th>
                                                    <td class="text-left">
                                                        {{
                                                            e.employee_fristname +
                                                                ' ' +
                                                                e.employee_lastname
                                                        }}
                                                    </td>
                                                    <td class="text-left">
                                                        {{ e.employee_email }}
                                                    </td>
                                                    <td
                                                        class="text-left"
                                                        v-if="
                                                            get_dept_name(
                                                                e.department_id
                                                            )
                                                        "
                                                    >
                                                        {{
                                                            get_dept_name(
                                                                e.department_id
                                                            )
                                                        }}
                                                    </td>
                                                    <td
                                                        class="text-left"
                                                        v-else
                                                    >
                                                        <i
                                                            >No assigned
                                                            department</i
                                                        >
                                                    </td>
                                                    <td
                                                        class="text-left"
                                                        v-if="
                                                            e.employee_designation
                                                        "
                                                    >
                                                        {{
                                                            e.employee_designation
                                                        }}
                                                    </td>
                                                    <td
                                                        class="text-left"
                                                        v-else
                                                    >
                                                        <i
                                                            >No given
                                                            designation</i
                                                        >
                                                    </td>
                                                    <td
                                                        v-if="
                                                            getShiftDetails(
                                                                e.id
                                                            )
                                                        "
                                                        class="text-left"
                                                    >
                                                        {{
                                                            getShiftDetails(
                                                                e.id
                                                            ).shift_name
                                                        }}
                                                    </td>
                                                    <td
                                                        v-else
                                                        class="text-left"
                                                    >
                                                        <i>No shift assigned</i>
                                                    </td>
                                                    <td
                                                        v-if="
                                                            getShiftDetails(
                                                                e.id
                                                            )
                                                        "
                                                        class="text-left"
                                                    >
                                                        {{
                                                            getShiftDetails(
                                                                e.id
                                                            ).from
                                                        }}
                                                    </td>
                                                    <td
                                                        v-else
                                                        class="text-left"
                                                    >
                                                        <i>No shift assigned</i>
                                                    </td>
                                                    <td
                                                        v-if="
                                                            getShiftDetails(
                                                                e.id
                                                            )
                                                        "
                                                        class="text-left"
                                                    >
                                                        {{
                                                            getShiftDetails(
                                                                e.id
                                                            ).to
                                                        }}
                                                    </td>
                                                    <td
                                                        v-else
                                                        class="text-left"
                                                    >
                                                        <i>No shift assigned</i>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <!-- ICON BG-->
                    <v-row justify="center">
                        <v-dialog
                            v-model="assign_shift_dialog"
                            max-width="600px"
                        >
                            <v-card>
                                <v-card-title>
                                    <span class="headline">
                                        Add employees to shift
                                    </span>
                                </v-card-title>
                                <v-card-text>
                                    <v-container>
                                        <v-form @submit.prevent>
                                            <div class="container-fluid">
                                                <v-row>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        md="6"
                                                        class="remove-hint"
                                                    >
                                                        <span
                                                            style="color: #111d5e;"
                                                            >Shift
                                                            <i
                                                                class="fas fa-asterisk"
                                                                style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                                                            ></i
                                                        ></span>
                                                        <v-select
                                                            class="mt-2"
                                                            :items="allShifts"
                                                            placeholder="Select shift"
                                                            solo
                                                            dense
                                                            :full-width="true"
                                                            v-model="
                                                                formData.shift
                                                            "
                                                        ></v-select>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        md="6"
                                                        class="remove-hint"
                                                    >
                                                        <span
                                                            style="opacity: 0; color: #111d5e;"
                                                            >Shift
                                                            <i
                                                                class="fas fa-asterisk"
                                                                style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                                                            ></i
                                                        ></span>
                                                        <button
                                                            @click="
                                                                add_to_shift_submit
                                                                    ? null
                                                                    : add_to_shift()
                                                            "
                                                            style="width: 99%;"
                                                            type="button"
                                                            class="btn btn-secondary mt-2 text-white btn-raised ripple"
                                                        >
                                                            {{
                                                                add_to_shift_text
                                                            }}
                                                            <v-icon
                                                                :style="
                                                                    !add_to_shift_submit
                                                                        ? 'display: none;'
                                                                        : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                                                                "
                                                            >
                                                                fas
                                                                fa-circle-notch
                                                                fa-spin
                                                            </v-icon>
                                                        </button>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </v-form>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="assign_shift_dialog = false"
                                    >
                                        Close
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="commentDialog" max-width="900px">
                            <v-card>
                                <v-card-title>
                                    <span class="headline"
                                        >Punch in/out comments</span
                                    >
                                </v-card-title>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12">
                                                <h5>
                                                    Check-in Note:
                                                    {{
                                                        currentDetails
                                                            ? currentDetails.punch_in_note
                                                            : null
                                                    }}
                                                </h5>
                                            </v-col>
                                            <v-col cols="12">
                                                <h5>
                                                    Check-out Note:
                                                    {{
                                                        currentDetails
                                                            ? currentDetails.punch_out_note
                                                            : null
                                                    }}
                                                </h5>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="commentDialog = false"
                                    >
                                        Close
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-row>
                </div>
            </div>
            <!-- Footer Start -->
            <div class="flex-grow-1"></div>
            <!-- fotter end -->
        </div>
        <v-overlay v-else :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
    import { BASE_URL } from '@/main';
    import axios from 'axios';
    import { mapState, mapGetters, mapActions } from 'vuex';
    export default {
        data() {
            return {
                employee_name: '',
                currentDetails: null,
                commentDialog: false,
                employeeView: false,
                overlay: false,
                formData: {
                    shift: '',
                },
                add_to_shift_text: 'Submit',
                add_to_shift_submit: false,
                assign_shift_dialog: false,
                ids: [],
                shiftConfig: false,
                selected_date: '',
                refreshing_page: false,
                employee: null,
                menu: false,
                day_selected: '',
                view: 'date', // also day and employee
                day_attendance: [],
                checkingSingleDetails: [],
                monthSelected: '',
                yearSelected: '',
                checkingDetails: null,
                monthNames: [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December',
                ],
                firstYear: '',
                allEmployees: [],
            };
        },
        watch: {
            menu(val) {
                val &&
                    setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'));
            },
        },
        computed: {
            ...mapState(['weekDays']),
            ...mapGetters([
                'validEmployeeId',
                'userType',
                'authCompanyData',
                'authCompanyAllCheckin',
                'allEmployeeShifts',
                'authCompanyDepartments',
                'authShifts',
                'authCompanyHolidays',
                'authCompanyAttendanceConfig',
                'authCompanyAllLeave',
            ]),
            employeeExcelSheetName() {
                let _this = this,
                    value = 'Attendance Report For ',
                    employee_name = '';
                employee_name =
                    _this.employee_name ||
                    _this.employee.employee_fristname +
                        ' ' +
                        _this.employee.employee_lastname;
                value += employee_name;
                value += ' ';
                value += '(';
                value += _this.monthSelected;
                value += ', ';
                value += _this.yearSelected;
                value += ')';
                return value;
            },
            employee_json_fields: function() {
                return {
                    'Employee name': 'employee_name',
                    'Check-in': 'check_in',
                    'Check-out': 'check_out',
                    'Work time': 'duration',
                    Status: 'status',
                };
            },
            employee_json_data: function() {
                return this.finalCheckingSingleDetailsExcel;
            },
            finalCheckingSingleDetailsExcel() {
                let _this = this,
                    result = [];
                result = _this.finalCheckingSingleDetails.map((detail) => {
                    return {
                        employee_name:
                            _this.employee_name ||
                            _this.employee.employee_fristname +
                                ' ' +
                                _this.employee.employee_lastname,
                        check_in: detail.check_in
                            ? detail.check_in.split(' ').length == 1
                                ? detail.check_in
                                : detail.check_in.split(' ')[1]
                            : null,
                        check_out: detail.check_out
                            ? detail.check_out.split(' ').length == 1
                                ? detail.check_out
                                : detail.check_out.split(' ')[1]
                            : null,
                        duration: detail.duration,
                        status: detail.status,
                    };
                });
                return result;
            },
            json_fields: function() {
                return {
                    'Employee name': 'employee_name',
                    Department: 'department',
                    Status: 'status',
                    'Check-in': 'check_in',
                    'Check-in note': 'punch_in_note',
                    'Check-out': 'check_out',
                    'Check-out note': 'punch_out_note',
                    'Check-in address': 'checkinaddress',
                    'Work time': 'duration',
                };
            },
            json_data: function() {
                return this.day_attendance_excel;
            },
            day_attendance_excel() {
                let _this = this,
                    result = [];
                result = _this.day_attendance.map((day) => {
                    return {
                        employee_name:
                            day.employee_fristname +
                            ' ' +
                            day.employee_lastname,
                        department: _this.get_dept_name(day.department_id),
                        status: day.status,
                        check_in: day.check_in
                            ? day.check_in.split(' ').length == 1
                                ? day.check_in
                                : day.check_in.split(' ')[1]
                            : null,
                        punch_in_note: day.punch_in_note,
                        check_out: day.check_out
                            ? day.check_out.split(' ').length == 1
                                ? day.check_out
                                : day.check_out.split(' ')[1]
                            : null,
                        punch_out_note: day.punch_out_note,
                        checkinaddress: day.checkinaddress,
                        duration: day.duration,
                    };
                });
                return result;
            },
            employee_id: function() {
                var t = this;
                if (t.employee_name.toLowerCase() != 'all') {
                    return t.mixinBranchEmployees(t.$route.name) &&
                        t.employee_name
                        ? t
                              .mixinBranchEmployees(t.$route.name)
                              .filter(function(e) {
                                  return (
                                      e.employee_fristname +
                                          ' ' +
                                          e.employee_lastname ==
                                      t.employee_name
                                  );
                              })[0].id
                        : null;
                } else {
                    return null;
                }
            },
            employeeApprovedLeave() {
                let _this = this,
                    result = [];
                if (!_this.employee) {
                    return result;
                }
                if (_this.authCompanyAllLeave.length >= 1) {
                    result = _this.authCompanyAllLeave.filter(
                        (lv) =>
                            _this.employee.employee_id == lv.employee_id &&
                            lv.status == 1
                    );
                }
                return result;
            },
            companyWorkDays() {
                if (this.authCompanyAttendanceConfig) {
                    return this.authCompanyAttendanceConfig.working_days.split(
                        ','
                    );
                } else {
                    return [];
                }
            },
            finalCheckingSingleDetails() {
                let _this = this;
                let checkInDays = _this.checkingSingleDetails.map((c) =>
                    c.ms_time.toString().substring(0, 15)
                );
                let dateToCheck,
                    daysToGet,
                    result = [],
                    holidays = [],
                    leaveDays = [];

                // BELOW IS TO GET DAYS ON LEAVE

                if (_this.employeeApprovedLeave.length >= 1) {
                    _this.employeeApprovedLeave.forEach((lv) => {
                        _this
                            .getDatesBetweenDates(
                                new Date(lv.leave_start),
                                new Date(lv.leave_end + ' 01:00:01')
                            )
                            .forEach((d) => {
                                leaveDays.splice(
                                    leaveDays.length,
                                    0,
                                    new Date(d).toString().substring(0, 15)
                                );
                            });
                    });
                }

                // END DAYS ON LEAVE CALCULATION

                dateToCheck = new Date(
                    Date.UTC(
                        parseInt(_this.yearSelected),
                        _this.monthNames.indexOf(_this.monthSelected)
                    )
                );
                daysToGet = [];
                while (
                    dateToCheck.getUTCMonth() ===
                    _this.monthNames.indexOf(_this.monthSelected)
                ) {
                    if (dateToCheck.getTime() > new Date().getTime()) {
                        break;
                    }
                    daysToGet.push(
                        new Date(dateToCheck).toString().substring(0, 15)
                    );
                    dateToCheck.setUTCDate(dateToCheck.getUTCDate() + 1);
                }

                _this.authCompanyHolidays.forEach((h) => {
                    _this
                        .getDatesBetweenDates(
                            new Date(h.start),
                            new Date(h.end.replace('00:00:00', '01:00:00'))
                        )
                        .forEach((d) => {
                            holidays.splice(
                                holidays.length,
                                0,
                                new Date(d).toString().substring(0, 15)
                            );
                        });
                });

                daysToGet.forEach((day) => {
                    if (
                        holidays.indexOf(day) == -1 &&
                        leaveDays.indexOf(day) != -1 &&
                        _this.companyWorkDays.indexOf(
                            _this.getDayOfWeek(new Date(day).getDay())
                        ) != -1
                    ) {
                        if (checkInDays.indexOf(day) == -1) {
                            result.splice(result.length, 0, {
                                day: day,
                                status: 'on leave',
                            });
                        } else {
                            result.splice(
                                result.length,
                                0,
                                _this.checkingSingleDetails[
                                    checkInDays.indexOf(day)
                                ]
                            );
                        }
                    } else if (
                        holidays.indexOf(day) == -1 &&
                        leaveDays.indexOf(day) == -1 &&
                        _this.companyWorkDays.indexOf(
                            _this.getDayOfWeek(new Date(day).getDay())
                        ) != -1
                    ) {
                        // THIS IS IF THE DAY IS NOT A HOLIDAY
                        if (checkInDays.indexOf(day) == -1) {
                            result.splice(result.length, 0, {
                                day: day,
                                status: 'absent',
                            });
                        } else {
                            result.splice(
                                result.length,
                                0,
                                _this.checkingSingleDetails[
                                    checkInDays.indexOf(day)
                                ]
                            );
                        }
                    } else {
                        //
                    }
                });

                return result;
            },
            ids_to_add() {
                let _this = this;
                if (_this.ids_to_submit) {
                    return _this.ids_to_submit.map((i) => {
                        return {
                            company_id: _this.authCompanyData.id,
                            employee_id: i.id,
                            shift_id: _this.main_shift_id,
                        };
                    });
                }
                return null;
            },
            main_shift_id() {
                let _this = this;
                if (_this.authShifts && _this.formData.shift) {
                    let shift = _this.authShifts.filter((s) => {
                        return s.shift_name == _this.formData.shift;
                    });
                    return shift[0].id;
                }
                return null;
            },
            allShifts() {
                let _this = this,
                    shifts = [];
                if (_this.authShifts) {
                    shifts = _this.authShifts.map((s) => {
                        return s.shift_name;
                    });
                }
                return shifts;
            },
            ids_to_submit() {
                let _this = this;
                return _this.ids.map((id) => {
                    return {
                        id,
                    };
                });
            },
            attendance() {
                if (!this.checkingDetails) {
                    return this.authCompanyAllCheckin.attendance;
                } else {
                    return this.checkingDetails;
                }
            },
            month() {
                let d = new Date();
                return this.monthNames[d.getMonth()];
            },
            allYears() {
                let yearsArray = [],
                    currentYear = new Date().getFullYear();
                yearsArray.push(this.firstYear);
                for (
                    let yearX = this.firstYear;
                    yearX <= currentYear;
                    yearX++
                ) {
                    yearsArray.push(yearX.toString());
                }
                return yearsArray.reverse();
            },
        },
        methods: {
            ...mapActions([
                'fetch_all_employee_shifts',
                'fetch_all_shifts',
                'fetch_all_leave',
                'fetch_all_check_in_all_time',
                'fetch_all_check_in',
            ]),
            hasHistory: function() {
                return window.history.length > 2;
            },
            async printDayAttendance() {
                // Pass the element id here
                await this.$htmlToPaper(
                    'printDayAttendance',
                    this.mixinPrintPortraitOptions
                );
            },
            async printEmployeeAttendance() {
                // Pass the element id here
                // window.print();
                await this.$htmlToPaper(
                    'printEmployeeAttendance',
                    this.mixinPrintPortraitOptions
                );
            },
            leaveByEmployee(employee_id) {
                let _this = this,
                    result = [];
                if (_this.authCompanyAllLeave.length >= 1) {
                    result = _this.authCompanyAllLeave.filter(
                        (lv) => lv.employee_id == employee_id && lv.status == 1
                    );
                }
                return result;
            },
            getDayOfWeek(day) {
                return this.weekDays[day];
            },
            getDatesBetweenDates(startDate, endDate) {
                let dates = [];
                //to avoid modifying the original date
                const theDate = new Date(startDate);
                while (theDate < endDate) {
                    dates = [...dates, new Date(theDate)];
                    theDate.setDate(theDate.getDate() + 1);
                }
                return dates;
            },
            getShiftDetails(employee_id) {
                let _this = this;
                if (_this.allEmployeeShifts) {
                    return _this.allEmployeeShifts.filter((e) => {
                        return e.employee_id == employee_id;
                    })[0];
                }
                return null;
            },
            get_dept_name(dept_id) {
                let _this = this;
                let dept = _this.authCompanyDepartments.filter((dept) => {
                    return dept.id == dept_id;
                });
                if (dept.length != 0) {
                    return dept[0].department_name;
                }
                return null;
            },
            check_all() {
                let _this = this;

                if (
                    _this.ids.length !=
                    _this.mixinBranchEmployees(_this.$route.name).length
                ) {
                    _this.ids = [];
                    _this
                        .mixinBranchEmployees(_this.$route.name)
                        .forEach((shift) => {
                            _this.ids.splice(_this.ids.length - 1, 0, shift.id);
                        });
                } else {
                    _this
                        .mixinBranchEmployees(_this.$route.name)
                        .forEach((shift) => {
                            _this.ids.splice(_this.ids.indexOf(shift.id), 1);
                        });
                }
            },
            add_to_shift() {
                let _this = this;

                const Toast = _this.$swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener(
                            'mouseenter',
                            _this.$swal.stopTimer
                        );
                        toast.addEventListener(
                            'mouseleave',
                            _this.$swal.resumeTimer
                        );
                    },
                });
                _this.add_to_shift_text = '';
                _this.add_to_shift_submit = true;
                _this.$swal
                    .fire({
                        title:
                            _this.ids.length == 1
                                ? 'Are you sure you want to add the selected employee to this shift (' +
                                  _this.formData.shift +
                                  ')?'
                                : 'Are you sure you want to add the selected employees to this shift (' +
                                  _this.formData.shift +
                                  ')?',
                        showCancelButton: true,
                        confirmButtonText: `Yes, Add`,
                    })
                    .then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            // _this.$swal.fire(number >= 2 ? 'Appraisals deleted!' : 'Appraisal deleted!', '', 'success')
                            axios
                                .post(`${BASE_URL}addemployeestoshift`, {
                                    employeecollections: _this.ids_to_add,
                                })
                                .then(function() {
                                    Toast.fire({
                                        icon: 'success',
                                        title:
                                            _this.ids.length == 1
                                                ? 'Employee has been added to the shift successfully'
                                                : 'The employees has been added to the shift successfully',
                                    });
                                    _this.ids = [];
                                    _this.assign_shift_dialog = false;
                                    _this.formData.shift = '';
                                })
                                .catch(function(error) {
                                    Toast.fire({
                                        icon: 'error',
                                        title:
                                            _this.ids.length == 1
                                                ? 'The employee could not be added to the shift'
                                                : 'The employees could not be added to the shift',
                                    });
                                    console.log(error);
                                })
                                .then(function() {
                                    _this.add_to_shift_submit = false;
                                    _this.add_to_shift_text = 'Submit';
                                    _this.fetch_all_shifts();
                                    _this.fetch_all_employee_shifts();
                                });
                        }
                    });
            },
            remove_from_shift() {
                let _this = this;

                const Toast = _this.$swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener(
                            'mouseenter',
                            _this.$swal.stopTimer
                        );
                        toast.addEventListener(
                            'mouseleave',
                            _this.$swal.resumeTimer
                        );
                    },
                });
                _this.$swal
                    .fire({
                        title:
                            _this.ids.length == 1
                                ? 'Are you sure you want to remove the selected employee from his/her shift?'
                                : 'Are you sure you want to remove the selected employees from their shifts?',
                        showCancelButton: true,
                        confirmButtonText: `Yes, Remove`,
                    })
                    .then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            // _this.$swal.fire(number >= 2 ? 'Appraisals deleted!' : 'Appraisal deleted!', '', 'success')
                            axios
                                .post(`${BASE_URL}removeemployeefromshift`, {
                                    ids: _this.ids_to_submit,
                                })
                                .then(function() {
                                    Toast.fire({
                                        icon: 'success',
                                        title:
                                            _this.ids.length == 1
                                                ? 'Employee removal from shift successful'
                                                : "Employees' removal from shift successfully",
                                    });
                                    _this.ids = [];
                                })
                                .catch(function(error) {
                                    Toast.fire({
                                        icon: 'error',
                                        title:
                                            _this.ids.length == 1
                                                ? 'The employee could not be removed from the shift'
                                                : 'The employees could not be removed from the shift',
                                    });
                                    console.log(error);
                                })
                                .then(function() {
                                    _this.fetch_all_employee_shifts();
                                });
                        }
                    });
            },
            viewAll() {
                this.monthSelected = this.monthNames[new Date().getMonth()];
                this.yearSelected = new Date().getFullYear().toString();
                this.checkingDetails = null;
                this.view = 'date';
            },
            save(date) {
                this.$refs.menu.save(date);
                let _this = this;
                axios
                    .post(`${BASE_URL}fetchcheckingbyday`, {
                        company_id: _this.authCompanyData.id,
                        day: _this.day_selected,
                    })
                    .then(function(response) {
                        console.log('BELOW IS THE response.data.attendance');
                        console.log(response.data.attendance);
                        if (response.data.attendance.length == 0) {
                            _this.selected_date = _this.day_selected;
                            _this.day_attendance = [];
                        } else {
                            _this.selected_date = _this.day_selected;
                            _this.day_attendance = response.data.attendance;
                        }
                        _this.view = 'day';

                        let attendantIds = _this.day_attendance.map(
                            (da) => da.employee_id
                        );

                        let leaveIds = [];
                        _this
                            .mixinBranchEmployees(_this.$route.name)
                            .forEach((te) => {
                                if (attendantIds.indexOf(te.id) == -1) {
                                    // return te.id;
                                    _this
                                        .leaveByEmployee(te.id)
                                        .forEach((lv) => {
                                            let readable = _this
                                                .getDatesBetweenDates(
                                                    new Date(lv.leave_start),
                                                    new Date(
                                                        lv.leave_end +
                                                            ' 01:00:01'
                                                    )
                                                )
                                                .map((nd) => {
                                                    return new Date(
                                                        nd
                                                    ).toDateString();
                                                });
                                            if (
                                                readable.indexOf(
                                                    new Date(
                                                        _this.day_selected
                                                    ).toDateString()
                                                ) != -1 &&
                                                leaveIds.indexOf(te.id) == -1
                                            ) {
                                                leaveIds.splice(
                                                    leaveIds.length - 1,
                                                    0,
                                                    te.id
                                                );
                                            }
                                        });
                                }
                            });

                        leaveIds.forEach((a) => {
                            let employee = _this
                                .mixinBranchEmployees(_this.$route.name)
                                .filter((e) => e.id == a)[0];
                            _this.day_attendance.splice(
                                _this.day_attendance.length,
                                0,
                                {
                                    employee_id: employee.id,
                                    employee_fristname:
                                        employee.employee_fristname,
                                    employee_lastname:
                                        employee.employee_lastname,
                                    department_id: employee.department_id,
                                    status: 'on leave',
                                }
                            );
                        });

                        let absentIds = [];
                        _this
                            .mixinBranchEmployees(_this.$route.name)
                            .forEach((te) => {
                                if (
                                    attendantIds.indexOf(te.id) == -1 &&
                                    leaveIds.indexOf(te.id) == -1
                                ) {
                                    // return te.id;
                                    absentIds.splice(
                                        absentIds.length - 1,
                                        0,
                                        te.id
                                    );
                                }
                            });

                        absentIds.forEach((a) => {
                            let employee = _this
                                .mixinBranchEmployees(_this.$route.name)
                                .filter((e) => e.id == a)[0];
                            _this.day_attendance.splice(
                                _this.day_attendance.length,
                                0,
                                {
                                    employee_id: employee.id,
                                    employee_fristname:
                                        employee.employee_fristname,
                                    employee_lastname:
                                        employee.employee_lastname,
                                    department_id: employee.department_id,
                                    status: 'absent',
                                }
                            );
                        });
                    })
                    .catch(function(error) {
                        console.log(error.response);
                        _this.day_attendance = [
                            {
                                day: _this.day_selected,
                            },
                        ];
                    })
                    .then(function() {
                        //
                    });
            },
            update_single_checking_details() {
                let _this = this;
                axios
                    .post(`${BASE_URL}fetchemployeechecking`, {
                        company_id: _this.authCompanyData.id,
                        employee_id:
                            _this.employee_id || _this.employee.employee_id,
                        month: _this.monthSelected,
                        year: _this.yearSelected,
                    })
                    .then(function(response) {
                        _this.checkingSingleDetails =
                            response.data.user_attendance;
                        // let firstYear = response.data.year_object.day.split("-")[0];
                        // _this.yearSelected = firstYear;
                        _this.view = 'employee';
                        const basicPieElem = document.getElementById(
                            'basicPie'
                        );
                        let early,
                            late,
                            absent = 0,
                            onLeave = 0,
                            checkInDays,
                            dateToCheck,
                            daysToGet,
                            holidays = [],
                            leaveDays = [];
                        if (
                            _this.checkingSingleDetails.length >= 1 ||
                            new Date(response.data.year_object.day).getTime() <
                                new Date().getTime() ||
                            new Date().getTime() - 34716000000 <
                                new Date().getTime()
                        ) {
                            early = _this.finalCheckingSingleDetails.filter(
                                (check) => check.status == 'early'
                            ).length;
                            late = _this.finalCheckingSingleDetails.filter(
                                (check) => check.status == 'late'
                            ).length;

                            // BELOW IS TO GET DAYS ABSENT
                            checkInDays = _this.checkingSingleDetails.map((c) =>
                                c.ms_time.toString().substring(0, 15)
                            );

                            // console.log(checkInDays);

                            dateToCheck = new Date(
                                Date.UTC(
                                    parseInt(_this.yearSelected),
                                    _this.monthNames.indexOf(
                                        _this.monthSelected
                                    )
                                )
                            );
                            daysToGet = [];
                            while (
                                dateToCheck.getUTCMonth() ===
                                _this.monthNames.indexOf(_this.monthSelected)
                            ) {
                                if (
                                    dateToCheck.getTime() > new Date().getTime()
                                ) {
                                    break;
                                }
                                daysToGet.push(
                                    new Date(dateToCheck)
                                        .toString()
                                        .substring(0, 15)
                                );
                                dateToCheck.setUTCDate(
                                    dateToCheck.getUTCDate() + 1
                                );
                            }

                            // BELOW IS TO GET DAYS ON LEAVE

                            _this.employeeApprovedLeave.forEach((lv) => {
                                _this
                                    .getDatesBetweenDates(
                                        new Date(lv.leave_start),
                                        new Date(lv.leave_end + ' 01:00:01')
                                    )
                                    .forEach((d) => {
                                        leaveDays.splice(
                                            leaveDays.length,
                                            0,
                                            new Date(d)
                                                .toString()
                                                .substring(0, 15)
                                        );
                                    });
                            });

                            daysToGet.forEach((day) => {
                                if (
                                    checkInDays.indexOf(day) == -1 &&
                                    leaveDays.indexOf(day) != -1 &&
                                    holidays.indexOf(day) == -1 &&
                                    _this.companyWorkDays.indexOf(
                                        _this.getDayOfWeek(
                                            new Date(day).getDay()
                                        )
                                    ) != -1
                                ) {
                                    // console.log(day);
                                    onLeave += 1;
                                }
                            });

                            // END DAYS ON LEAVE CALCULATION

                            _this.authCompanyHolidays.forEach((h) => {
                                _this
                                    .getDatesBetweenDates(
                                        new Date(h.start),
                                        new Date(
                                            h.end.replace(
                                                '00:00:00',
                                                '01:00:00'
                                            )
                                        )
                                    )
                                    .forEach((d) => {
                                        holidays.splice(
                                            holidays.length,
                                            0,
                                            new Date(d)
                                                .toString()
                                                .substring(0, 15)
                                        );
                                    });
                            });

                            daysToGet.forEach((day) => {
                                if (
                                    checkInDays.indexOf(day) == -1 &&
                                    holidays.indexOf(day) == -1 &&
                                    leaveDays.indexOf(day) == -1 &&
                                    _this.companyWorkDays.indexOf(
                                        _this.getDayOfWeek(
                                            new Date(day).getDay()
                                        )
                                    ) != -1
                                ) {
                                    // console.log(day);
                                    absent += 1;
                                }
                            });

                            // absent = 0;

                            // END DAYS ABSENT CALCULATION
                        } else {
                            early = 0;
                            late = 0;
                            absent = 0;
                            onLeave = 0;
                        }
                        if (basicPieElem) {
                            var basicPie = window.echarts.init(basicPieElem);
                            basicPie.setOption({
                                color: [
                                    '#4caf50',
                                    '#ebcb37',
                                    '#003473',
                                    '#c13018',
                                ],
                                grid: {
                                    left: '3%',
                                    right: '4%',
                                    bottom: '3%',
                                    containLabel: true,
                                },
                                xAxis: [
                                    {
                                        axisLine: {
                                            show: false,
                                        },
                                        splitLine: {
                                            show: false,
                                        },
                                    },
                                ],
                                yAxis: [
                                    {
                                        axisLine: {
                                            show: false,
                                        },
                                        splitLine: {
                                            show: false,
                                        },
                                    },
                                ],
                                series: [
                                    {
                                        name: 'Time Logs',
                                        type: 'pie',
                                        radius: '70%',
                                        center: ['50%', '50%'],
                                        data: [
                                            {
                                                value: early,
                                                name:
                                                    early == 1
                                                        ? 'EARLY (' +
                                                          early +
                                                          ' day)'
                                                        : 'EARLY (' +
                                                          early +
                                                          ' days)',
                                            },
                                            {
                                                value: late,
                                                name:
                                                    late == 1
                                                        ? 'LATE (' +
                                                          late +
                                                          ' day)'
                                                        : 'LATE (' +
                                                          late +
                                                          ' days)',
                                            },
                                            _this.check_all_services(
                                                'Absence or Leave'
                                            )
                                                ? {
                                                      value: onLeave,
                                                      name:
                                                          onLeave == 1
                                                              ? 'ON LEAVE (' +
                                                                onLeave +
                                                                ' day)'
                                                              : 'ON LEAVE (' +
                                                                onLeave +
                                                                ' days)',
                                                  }
                                                : null,
                                            {
                                                value: absent,
                                                name:
                                                    absent == 1
                                                        ? 'ABSENT (' +
                                                          absent +
                                                          ' day)'
                                                        : 'ABSENT (' +
                                                          absent +
                                                          ' days)',
                                            },
                                        ],
                                        itemStyle: {
                                            emphasis: {
                                                shadowBlur: 10,
                                                shadowOffsetX: 0,
                                                shadowColor:
                                                    'rgba(0, 0, 0, 0.5)',
                                            },
                                        },
                                    },
                                ],
                            });
                            window.$(window).on('resize', function() {
                                setTimeout(function() {
                                    basicPie.resize();
                                }, 500);
                            });
                        }
                    })
                    .catch(function(error) {
                        console.log(error.response);
                    })
                    .then(function() {
                        //
                    });
            },
            load_employee(employee) {
                let _this = this;
                _this.overlay = true;
                _this.employee = employee;
                _this.monthSelected = _this.monthNames[new Date().getMonth()];
                _this.yearSelected = new Date().getFullYear().toString();
                _this.view = 'employee';
                axios
                    .post(`${BASE_URL}fetchemployeechecking`, {
                        company_id: _this.authCompanyData.id,
                        employee_id: _this.employee_id || employee.employee_id,
                        month: _this.monthSelected,
                        year: _this.yearSelected,
                    })
                    .then(function(response) {
                        _this.overlay = false;
                        setTimeout(() => {
                            _this.checkingSingleDetails =
                                response.data.user_attendance;
                            // let firstYear = response.data.year_object.day.split("-")[0];
                            // _this.yearSelected = firstYear;
                            const basicPieElem = document.getElementById(
                                'basicPie'
                            );
                            let early,
                                late,
                                absent = 0,
                                onLeave = 0,
                                checkInDays,
                                dateToCheck,
                                daysToGet,
                                holidays = [],
                                leaveDays = [];
                            if (
                                _this.checkingSingleDetails.length >= 1 ||
                                new Date(
                                    response.data.year_object.day
                                ).getTime() < new Date().getTime() ||
                                new Date().getTime() - 1 < new Date().getTime()
                            ) {
                                early = _this.finalCheckingSingleDetails.filter(
                                    (check) => check.status == 'early'
                                ).length;
                                late = _this.finalCheckingSingleDetails.filter(
                                    (check) => check.status == 'late'
                                ).length;

                                // BELOW IS TO GET DAYS ABSENT
                                checkInDays = _this.checkingSingleDetails.map(
                                    (c) => c.ms_time.toString().substring(0, 15)
                                );

                                // console.log(checkInDays);

                                dateToCheck = new Date(
                                    Date.UTC(
                                        parseInt(_this.yearSelected),
                                        _this.monthNames.indexOf(
                                            _this.monthSelected
                                        )
                                    )
                                );
                                daysToGet = [];
                                while (
                                    dateToCheck.getUTCMonth() ===
                                    _this.monthNames.indexOf(
                                        _this.monthSelected
                                    )
                                ) {
                                    if (
                                        dateToCheck.getTime() >
                                        new Date().getTime()
                                    ) {
                                        break;
                                    }
                                    daysToGet.push(
                                        new Date(dateToCheck)
                                            .toString()
                                            .substring(0, 15)
                                    );
                                    dateToCheck.setUTCDate(
                                        dateToCheck.getUTCDate() + 1
                                    );
                                }

                                // BELOW IS TO GET DAYS ON LEAVE

                                _this.employeeApprovedLeave.forEach((lv) => {
                                    _this
                                        .getDatesBetweenDates(
                                            new Date(lv.leave_start),
                                            new Date(lv.leave_end + ' 01:00:01')
                                        )
                                        .forEach((d) => {
                                            leaveDays.splice(
                                                leaveDays.length,
                                                0,
                                                new Date(d)
                                                    .toString()
                                                    .substring(0, 15)
                                            );
                                        });
                                });

                                daysToGet.forEach((day) => {
                                    if (
                                        checkInDays.indexOf(day) == -1 &&
                                        leaveDays.indexOf(day) != -1 &&
                                        holidays.indexOf(day) == -1 &&
                                        _this.companyWorkDays.indexOf(
                                            _this.getDayOfWeek(
                                                new Date(day).getDay()
                                            )
                                        ) != -1
                                    ) {
                                        // console.log(day);
                                        onLeave += 1;
                                    }
                                });

                                // END DAYS ON LEAVE CALCULATION

                                _this.authCompanyHolidays.forEach((h) => {
                                    _this
                                        .getDatesBetweenDates(
                                            new Date(h.start),
                                            new Date(
                                                h.end.replace(
                                                    '00:00:00',
                                                    '01:00:00'
                                                )
                                            )
                                        )
                                        .forEach((d) => {
                                            holidays.splice(
                                                holidays.length,
                                                0,
                                                new Date(d)
                                                    .toString()
                                                    .substring(0, 15)
                                            );
                                        });
                                });

                                daysToGet.forEach((day) => {
                                    if (
                                        checkInDays.indexOf(day) == -1 &&
                                        holidays.indexOf(day) == -1 &&
                                        leaveDays.indexOf(day) == -1 &&
                                        _this.companyWorkDays.indexOf(
                                            _this.getDayOfWeek(
                                                new Date(day).getDay()
                                            )
                                        ) != -1
                                    ) {
                                        // console.log(day);
                                        absent += 1;
                                    }
                                });

                                // absent = 0;

                                // END DAYS ABSENT CALCULATION
                            } else {
                                early = 0;
                                late = 0;
                                absent = 0;
                                onLeave = 0;
                            }
                            if (basicPieElem) {
                                var basicPie = window.echarts.init(
                                    basicPieElem
                                );
                                basicPie.setOption({
                                    color: [
                                        '#4caf50',
                                        '#ebcb37',
                                        '#003473',
                                        '#c13018',
                                    ],
                                    grid: {
                                        left: '3%',
                                        right: '4%',
                                        bottom: '3%',
                                        containLabel: true,
                                    },
                                    xAxis: [
                                        {
                                            axisLine: {
                                                show: false,
                                            },
                                            splitLine: {
                                                show: false,
                                            },
                                        },
                                    ],
                                    yAxis: [
                                        {
                                            axisLine: {
                                                show: false,
                                            },
                                            splitLine: {
                                                show: false,
                                            },
                                        },
                                    ],
                                    series: [
                                        {
                                            name: 'Time Logs',
                                            type: 'pie',
                                            radius: '70%',
                                            center: ['50%', '50%'],
                                            data: [
                                                {
                                                    value: early,
                                                    name:
                                                        early == 1
                                                            ? 'EARLY (' +
                                                              early +
                                                              ' day)'
                                                            : 'EARLY (' +
                                                              early +
                                                              ' days)',
                                                },
                                                {
                                                    value: late,
                                                    name:
                                                        late == 1
                                                            ? 'LATE (' +
                                                              late +
                                                              ' day)'
                                                            : 'LATE (' +
                                                              late +
                                                              ' days)',
                                                },
                                                _this.check_all_services(
                                                    'Absence or Leave'
                                                )
                                                    ? {
                                                          value: onLeave,
                                                          name:
                                                              onLeave == 1
                                                                  ? 'ON LEAVE (' +
                                                                    onLeave +
                                                                    ' day)'
                                                                  : 'ON LEAVE (' +
                                                                    onLeave +
                                                                    ' days)',
                                                      }
                                                    : null,
                                                {
                                                    value: absent,
                                                    name:
                                                        absent == 1
                                                            ? 'ABSENT (' +
                                                              absent +
                                                              ' day)'
                                                            : 'ABSENT (' +
                                                              absent +
                                                              ' days)',
                                                },
                                            ],
                                            itemStyle: {
                                                emphasis: {
                                                    shadowBlur: 10,
                                                    shadowOffsetX: 0,
                                                    shadowColor:
                                                        'rgba(0, 0, 0, 0.5)',
                                                },
                                            },
                                        },
                                    ],
                                });
                                window.$(window).on('resize', function() {
                                    setTimeout(function() {
                                        basicPie.resize();
                                    }, 500);
                                });
                            }
                        }, 1000);
                    })
                    .catch(function(error) {
                        _this.overlay = false;
                        console.log(error.response);
                    })
                    .then(function() {
                        //
                    });
            },
            load_day(day) {
                let _this = this;
                _this.overlay = true;
                axios
                    .post(`${BASE_URL}fetchcheckingbyday`, {
                        company_id: _this.authCompanyData.id,
                        day,
                    })
                    .then(function(response) {
                        _this.day_attendance = response.data.attendance;
                        _this.view = 'day';
                        _this.day_selected = day;

                        let attendantIds = _this.day_attendance.map(
                            (da) => da.employee_id
                        );

                        let leaveIds = [];
                        _this
                            .mixinBranchEmployees(_this.$route.name)
                            .forEach((te) => {
                                if (attendantIds.indexOf(te.id) == -1) {
                                    // return te.id;
                                    _this
                                        .leaveByEmployee(te.id)
                                        .forEach((lv) => {
                                            let readable = _this
                                                .getDatesBetweenDates(
                                                    new Date(lv.leave_start),
                                                    new Date(
                                                        lv.leave_end +
                                                            ' 01:00:01'
                                                    )
                                                )
                                                .map((nd) => {
                                                    return new Date(
                                                        nd
                                                    ).toDateString();
                                                });
                                            if (
                                                readable.indexOf(
                                                    new Date(
                                                        _this.day_selected
                                                    ).toDateString()
                                                ) != -1 &&
                                                leaveIds.indexOf(te.id) == -1
                                            ) {
                                                leaveIds.splice(
                                                    leaveIds.length - 1,
                                                    0,
                                                    te.id
                                                );
                                            }
                                        });
                                }
                            });

                        leaveIds.forEach((a) => {
                            let employee = _this
                                .mixinBranchEmployees(_this.$route.name)
                                .filter((e) => e.id == a)[0];
                            _this.day_attendance.splice(
                                _this.day_attendance.length,
                                0,
                                {
                                    employee_id: employee.id,
                                    employee_fristname:
                                        employee.employee_fristname,
                                    employee_lastname:
                                        employee.employee_lastname,
                                    department_id: employee.department_id,
                                    status: 'on leave',
                                }
                            );
                        });

                        let absentIds = [];
                        _this
                            .mixinBranchEmployees(_this.$route.name)
                            .forEach((te) => {
                                if (
                                    attendantIds.indexOf(te.id) == -1 &&
                                    leaveIds.indexOf(te.id) == -1
                                ) {
                                    // return te.id;
                                    absentIds.splice(
                                        absentIds.length - 1,
                                        0,
                                        te.id
                                    );
                                }
                            });

                        absentIds.forEach((a) => {
                            let employee = _this
                                .mixinBranchEmployees(_this.$route.name)
                                .filter((e) => e.id == a)[0];
                            _this.day_attendance.splice(
                                _this.day_attendance.length,
                                0,
                                {
                                    employee_id: employee.id,
                                    employee_fristname:
                                        employee.employee_fristname,
                                    employee_lastname:
                                        employee.employee_lastname,
                                    department_id: employee.department_id,
                                    status: 'absent',
                                }
                            );
                        });
                    })
                    .catch(function(error) {
                        console.log(error);
                    })
                    .then(function() {
                        _this.overlay = false;
                    });
            },
            update_checking_details() {
                let _this = this;
                axios
                    .post(`${BASE_URL}fetchallcompanychecking`, {
                        company_id: _this.authCompanyData.id,
                        month: _this.monthSelected,
                        year: _this.yearSelected,
                    })
                    .then(function(response) {
                        _this.checkingDetails = response.data.attendance;
                        _this.firstYear = new Date(
                            _this.authCompanyData.created_at
                        )
                            .getFullYear()
                            .toString();
                        // _this.yearSelected = _this.firstYear;

                        // let attendantIds = _this.day_attendance.map(da => da.employee_id);
                        // let absentIds = _this.mixinBranchEmployees(_this.$route.name).map(te => {
                        //   if (attendantIds.indexOf(te.id) == -1) {
                        //     return te.id;
                        //   }
                        // });
                        // absentIds.forEach(a => {
                        //   let employee = _this.mixinBranchEmployees(_this.$route.name).filter(e => e.id == a)[0];
                        //   _this.day_attendance.splice(_this.day_attendance.length - 1, 0, {
                        //     employee_fristname: employee.employee_fristname,
                        //     employee_lastname: employee.employee_lastname,
                        //     department_id: employee.department_id,
                        //     status: 'absent'
                        //   })
                        // });
                    })
                    .catch(function(error) {
                        console.log(error.response);
                    })
                    .then(function() {
                        //
                    });
            },
        },
        mounted() {
            document.title = 'Kylian ERP - Attendance';
            let _this = this;
            _this.mixinBranchEmployees(_this.$route.name).forEach(function(e) {
                _this.allEmployees.push(
                    e.employee_fristname + ' ' + e.employee_lastname
                );
            });
            this.fetch_all_employee_shifts();
            this.fetch_all_shifts();
            this.fetch_all_leave();
            this.fetch_all_check_in_all_time();
            this.fetch_all_check_in();
            this.firstYear = new Date(this.authCompanyData.created_at)
                .getFullYear()
                .toString();
            this.monthSelected = this.monthNames[new Date().getMonth()];
            this.yearSelected = new Date().getFullYear().toString();
        },
    };
</script>

<style>
    .underline:hover {
        text-decoration: underline;
        cursor: pointer;
    }

    .lead {
        font-size: 130%;
    }

    th,
    td {
        text-align: center;
    }
</style>

<style>
    .mdi-calendar-month-outline {
        margin-right: 10px;
        color: #069 !important;
    }

    .remove-mb div .v-text-field__details {
        margin-bottom: 0px !important;
    }
</style>

<style scoped>
    @media (max-width: 991px) {
        #basicPie div canvas {
            top: -100px !important;
        }
    }

    @media (min-width: 992px) {
        #basicPie div canvas {
            top: -40px !important;
        }
    }
</style>
