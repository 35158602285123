<template>
    <div>
        <!-- <SideMenu /> -->
        <div class="main-content-wrap sidenav-open d-flex flex-column pb-4">
            <div
                class="main-header pl-4"
                style="z-index: 60; align-content: center;"
                :class="
                    $vuetify.breakpoint.name != 'xs' &&
                    $vuetify.breakpoint.name != 'sm'
                        ? 'mt-2'
                        : null
                "
            >
                <div class="row">
                    <div
                        style="align-self: center;"
                        class="logo col-md-1 col-lg-1 col-12 py-0"
                    >
                        <div
                            class="dropdown animate__animated animate__fadeIn"
                            style="font-size: 120%;"
                        >
                            <i
                                @click="
                                    create_asset != 2
                                        ? (create_asset = 2)
                                        : $router.push({ name: 'ClientArea' })
                                "
                                style="cursor: pointer; position: relative; top: 2px; color: #069;"
                                class="fal fa-arrow-alt-circle-left mr-1"
                            ></i>
                            <span
                                class="ml-2"
                                style="top: 4%; position: relative;"
                                >Back</span
                            >
                        </div>
                    </div>
                    <div
                        v-if="
                            userType != 1 && permissionIsAssigned('assets-read')
                        "
                        class="input-group-prepend"
                    >
                        <button
                            class="btn dropdown-toggle w-100"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <span
                                :style="
                                    $vuetify.breakpoint.name == 'xs'
                                        ? 'margin-left: 1.5rem;'
                                        : $vuetify.breakpoint.name == 'sm'
                                        ? 'margin-left: 5rem;'
                                        : null
                                "
                                >{{
                                    employeeView
                                        ? 'Your Assets'
                                        : "All Employees' Assets"
                                }}</span
                            >
                        </button>
                        <div
                            v-if="permissionIsAssigned('assets-read')"
                            class="dropdown-menu"
                            style="min-width: 13rem;"
                        >
                            <a
                                @click="employeeView = !employeeView"
                                class="dropdown-item"
                                style="cursor: pointer;"
                            >
                                {{
                                    employeeView
                                        ? "All Employees' Assets"
                                        : 'Your Assets'
                                }}
                            </a>
                        </div>
                    </div>
                </div>
                <div style="margin: auto"></div>
                <div class="header-part-right pr-5">
                    <div
                        v-if="
                            userType == 1 ||
                                (userType != 1 &&
                                    !employeeView &&
                                    permissionIsAssigned('assets-read'))
                        "
                        class="dropdown animate__animated animate__fadeIn"
                    >
                        <v-row>
                            <v-col v-if="create_asset == 2" cols="8">
                                <v-text-field
                                    v-if="
                                        $vuetify.breakpoint.name != 'xs' &&
                                            $vuetify.breakpoint.name != 'sm'
                                    "
                                    v-model="filters.string"
                                    solo
                                    dense
                                    placeholder="Search through assets"
                                    messages="Search by name, location, price, etc"
                                    class="search-employees"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="4" style="align-self: center;">
                                <template
                                    v-if="
                                        create_asset == 1 || create_asset == 2
                                    "
                                >
                                    <button
                                        v-if="
                                            create_asset == 2 &&
                                                permissionIsAssigned(
                                                    'assets-create'
                                                )
                                        "
                                        @click="tweak_display()"
                                        style="float: right;"
                                        type="button"
                                        class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple"
                                    >
                                        {{
                                            create_asset == 1
                                                ? 'View Assets'
                                                : create_asset == 2
                                                ? 'Create Asset'
                                                : ''
                                        }}
                                    </button>
                                </template>
                                <button
                                    v-else
                                    @click="create_asset = 2"
                                    style="float: right;"
                                    type="button"
                                    class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple"
                                >
                                    {{ 'View Assets' }}
                                </button>
                            </v-col>
                        </v-row>
                    </div>
                    <div
                        v-if="
                            userType != 1 &&
                                !employeeView &&
                                permissionIsAssigned('assets-read')
                        "
                        class="dropdown animate__animated animate__fadeIn"
                    >
                        <v-row>
                            <v-col v-if="create_asset == 2" cols="8">
                                <v-text-field
                                    v-if="
                                        $vuetify.breakpoint.name != 'xs' &&
                                            $vuetify.breakpoint.name != 'sm'
                                    "
                                    v-model="filters.string"
                                    solo
                                    dense
                                    placeholder="Search through assets"
                                    messages="Search by name, location, price, etc"
                                    class="search-employees"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="4" style="align-self: center;">
                                <template
                                    v-if="
                                        create_asset == 1 || create_asset == 2
                                    "
                                >
                                    <button
                                        v-if="
                                            create_asset == 2 &&
                                                permissionIsAssigned(
                                                    'assets-create'
                                                )
                                        "
                                        @click="tweak_display()"
                                        style="float: right;"
                                        type="button"
                                        class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple"
                                    >
                                        {{
                                            create_asset == 1
                                                ? 'View Assets'
                                                : create_asset == 2
                                                ? 'Create Asset'
                                                : ''
                                        }}
                                    </button>
                                </template>
                                <button
                                    v-else
                                    @click="create_asset = 2"
                                    style="float: right;"
                                    type="button"
                                    class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple"
                                >
                                    {{ 'View Assets' }}
                                </button>
                            </v-col>
                        </v-row>
                    </div>
                    <!-- Notificaiton End -->
                    <!-- User avatar dropdown -->
                </div>
            </div>
            <!-- ======== Body content start ========= -->
            <template
                v-if="
                    userType == 1 ||
                        (userType != 1 &&
                            !employeeView &&
                            permissionIsAssigned('assets-read'))
                "
            >
                <div
                    v-if="create_asset == 1"
                    class="main-content"
                    :class="
                        $vuetify.breakpoint.name == 'xs' ||
                        $vuetify.breakpoint.name == 'sm'
                            ? 'px-2'
                            : null
                    "
                >
                    <div
                        class="breadcrumb mb-0"
                        :style="
                            $vuetify.breakpoint.name == 'xs' ||
                            $vuetify.breakpoint.name == 'sm'
                                ? null
                                : null
                        "
                        style="margin-top: 1rem;"
                    >
                        <h1>
                            <span class="col-4">Create an Asset</span>
                        </h1>
                    </div>
                    <v-form @submit.prevent>
                        <div class="container-fluid">
                            <v-row>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="6"
                                    class="remove-hint"
                                >
                                    <span style="color: #111d5e;"
                                        >Asset Name
                                        <i
                                            class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                                        ></i
                                    ></span>
                                    <v-text-field
                                        v-model="formData.name"
                                        placeholder="Enter asset name"
                                        solo
                                        class="mt-2"
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="6"
                                    class="remove-hint"
                                >
                                    <span style="color: #111d5e;"
                                        >Asset Description
                                        <i
                                            class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                                        ></i
                                    ></span>
                                    <v-text-field
                                        v-model="formData.description"
                                        placeholder="Enter asset description"
                                        solo
                                        class="mt-2"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="6"
                                    class="remove-hint"
                                >
                                    <span style="color: #111d5e;"
                                        >Asset Type Name
                                        <i
                                            class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                                        ></i
                                    ></span>
                                    <v-select
                                        v-model="formData.type_name"
                                        item-color="#069"
                                        :items="asset_type_names"
                                        placeholder="Select asset type"
                                        solo
                                        class="mt-2"
                                        color="#069"
                                    ></v-select>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="6"
                                    class="remove-hint"
                                >
                                    <span style="color: #111d5e;"
                                        >Serial Number
                                        <i
                                            class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                                        ></i
                                    ></span>
                                    <v-text-field
                                        v-model="formData.serial_number"
                                        placeholder="Enter serial number"
                                        solo
                                        class="mt-2"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="6"
                                    class="remove-hint"
                                >
                                    <span style="color: #111d5e;"
                                        >Vendor
                                        <i
                                            class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                                        ></i
                                    ></span>
                                    <v-select
                                        v-model="formData.vendor"
                                        item-color="#069"
                                        :items="vendor_names"
                                        placeholder="Select vendor"
                                        solo
                                        class="mt-2"
                                        color="#069"
                                    ></v-select>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="6"
                                    class="remove-hint"
                                >
                                    <span style="color: #111d5e;"
                                        >Unit Cost
                                        <i
                                            class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                                        ></i
                                    ></span>
                                    <v-text-field
                                        v-model="formData.unit_cost"
                                        placeholder="Enter unit cost"
                                        solo
                                        class="mt-2"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="6"
                                    class="remove-hint"
                                >
                                    <span style="color: #111d5e;"
                                        >Acquisition Cost
                                        <i
                                            class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                                        ></i
                                    ></span>
                                    <v-text-field
                                        v-model="formData.acquisition_cost"
                                        placeholder="Enter acquisition cost"
                                        solo
                                        class="mt-2"
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="6"
                                    class="remove-hint"
                                >
                                    <span style="color: #111d5e;"
                                        >Acquisition Date
                                        <i
                                            class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                                        ></i
                                    ></span>
                                    <v-menu
                                        ref="menu"
                                        v-model="menu"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-text-field
                                                color="#069"
                                                prepend-inner-icon="mdi-calendar-month-outline"
                                                v-model="
                                                    formData.acquisition_date
                                                "
                                                placeholder="Select acquisition date"
                                                solo
                                                class="mt-2"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            color="#069"
                                            ref="picker"
                                            v-model="formData.acquisition_date"
                                            :max="
                                                new Date(
                                                    new Date().getTime() +
                                                        86400000000
                                                )
                                                    .toISOString()
                                                    .substr(0, 10)
                                            "
                                            @change="save"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="6"
                                    class="remove-hint"
                                >
                                    <span style="color: #111d5e;"
                                        >Location Assigned To
                                        <i
                                            class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                                        ></i
                                    ></span>
                                    <v-text-field
                                        v-model="formData.location_assigned_to"
                                        placeholder="Enter location assigned to"
                                        solo
                                        class="mt-2"
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="6"
                                    class="remove-hint"
                                >
                                    <span style="color: #111d5e;"
                                        >Additional Comments</span
                                    >
                                    <v-text-field
                                        v-model="formData.additional_comments"
                                        placeholder="Enter additonal comments"
                                        solo
                                        class="mt-2"
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="remove-hint text-center"
                                >
                                    <button
                                        @click="
                                            create_asset_submit
                                                ? null
                                                : add_asset()
                                        "
                                        type="button"
                                        class="btn btn-secondary m-1 ripple"
                                    >
                                        {{ create_asset_text }}
                                        <v-icon
                                            :style="
                                                !create_asset_submit
                                                    ? 'display: none;'
                                                    : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                                            "
                                        >
                                            fal fa-circle-notch fa-spin
                                        </v-icon>
                                    </button>
                                    <button
                                        @click="create_asset = 2"
                                        type="button"
                                        class="btn btn-outline-secondary m-1"
                                    >
                                        Cancel
                                    </button>
                                </v-col>
                            </v-row>
                        </div>
                    </v-form>
                </div>
                <div
                    v-else-if="create_asset == 2"
                    class="main-content"
                    :class="
                        $vuetify.breakpoint.name == 'xs' ||
                        $vuetify.breakpoint.name == 'sm'
                            ? 'px-2'
                            : null
                    "
                >
                    <div
                        class="breadcrumb mb-0"
                        :style="
                            $vuetify.breakpoint.name == 'xs' ||
                            $vuetify.breakpoint.name == 'sm'
                                ? null
                                : null
                        "
                        style="margin-top: 1rem;"
                    >
                        <h1>
                            <span class="col-4 pl-0">Company Assets</span>
                        </h1>
                    </div>
                    <div
                        class="main-content mt-0 row"
                        :class="
                            $vuetify.breakpoint.name == 'xs' ||
                            $vuetify.breakpoint.name == 'sm'
                                ? 'px-2'
                                : null
                        "
                    >
                        <div class="card w-100" style="border-radius: 10px;">
                            <div class="card-body px-1 py-1 pt-0 pr-0">
                                <div
                                    class="table-responsive"
                                    style="border-radius: 10px; height: 75vh;
                                        overflow-y: scroll;
                                        padding-top: 0;
                                        padding-bottom: 0;"
                                >
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th
                                                    class="text-left"
                                                    style="border-top: none;"
                                                    scope="col"
                                                >
                                                    Asset Name
                                                </th>
                                                <th
                                                    class="text-left"
                                                    style="border-top: none;"
                                                    scope="col"
                                                >
                                                    Description
                                                </th>
                                                <th
                                                    class="text-left"
                                                    style="border-top: none;"
                                                    scope="col"
                                                >
                                                    Asset Type
                                                </th>
                                                <th
                                                    class="text-left"
                                                    style="border-top: none;"
                                                    scope="col"
                                                >
                                                    Location
                                                </th>
                                                <th
                                                    class="text-left"
                                                    style="border-top: none;"
                                                    scope="col"
                                                >
                                                    Vendor
                                                </th>
                                                <th
                                                    class="text-left"
                                                    style="border-top: none;"
                                                    scope="col"
                                                >
                                                    Cost
                                                </th>
                                                <th
                                                    class="text-left"
                                                    style="border-top: none;"
                                                    scope="col"
                                                >
                                                    Total
                                                </th>
                                                <th
                                                    class="text-left"
                                                    style="border-top: none;"
                                                    scope="col"
                                                >
                                                    Acq. Date
                                                </th>
                                                <th
                                                    class="text-left"
                                                    v-if="
                                                        permissionIsAssigned(
                                                            'assets-update'
                                                        ) == true ||
                                                            permissionIsAssigned(
                                                                'assets-delete'
                                                            ) == true ||
                                                            permissionIsAssigned(
                                                                'assets-assign'
                                                            ) != false
                                                    "
                                                    style="border-top: none;"
                                                    scope="col"
                                                >
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="asset in filteredAssets"
                                                :key="asset.id"
                                            >
                                                <th class="underline text-left">
                                                    {{ asset.asset_name }}
                                                </th>
                                                <td class="text-left">
                                                    {{
                                                        asset.asset_description
                                                    }}
                                                </td>
                                                <td class="text-left">
                                                    {{ asset.assettype_name }}
                                                </td>
                                                <td class="text-left">
                                                    {{
                                                        asset.location_assigned_to
                                                    }}
                                                </td>
                                                <td class="text-left">
                                                    {{ asset.vendor }}
                                                </td>
                                                <td class="text-left">
                                                    ₦{{
                                                        asset.unit_cost
                                                            | number_with_commas
                                                    }}
                                                </td>
                                                <td class="text-left">
                                                    ₦{{
                                                        asset.acqusition_cost
                                                            | number_with_commas
                                                    }}
                                                    ({{
                                                        parseInt(
                                                            asset.acqusition_cost
                                                        ) /
                                                            parseInt(
                                                                asset.unit_cost
                                                            )
                                                    }}
                                                    {{
                                                        parseInt(
                                                            asset.acqusition_cost
                                                        ) /
                                                            parseInt(
                                                                asset.unit_cost
                                                            ) ==
                                                        1
                                                            ? ' unit'
                                                            : ' units'
                                                    }})
                                                </td>
                                                <td class="text-left">
                                                    {{ asset.acqusation_date }}
                                                </td>
                                                <td
                                                    class="text-left px-1"
                                                    style="vertical-align: middle;"
                                                >
                                                    <div class="row">
                                                        <v-tooltip
                                                            v-if="
                                                                permissionIsAssigned(
                                                                    'assets-assign'
                                                                )
                                                            "
                                                            bottom
                                                        >
                                                            <template
                                                                v-slot:activator="{
                                                                    on,
                                                                    attrs,
                                                                }"
                                                            >
                                                                <i
                                                                    v-bind="
                                                                        attrs
                                                                    "
                                                                    v-on="on"
                                                                    class="py-0 text-center fal fa-user"
                                                                    :class="
                                                                        $vuetify
                                                                            .breakpoint
                                                                            .name ==
                                                                            'xs' ||
                                                                        $vuetify
                                                                            .breakpoint
                                                                            .name ==
                                                                            'sm'
                                                                            ? 'col-1'
                                                                            : 'offset-1 col-3'
                                                                    "
                                                                    @click="
                                                                        assign_asset_launcher(
                                                                            asset
                                                                        )
                                                                    "
                                                                    style="color: #069 !important; font-size: 125%; cursor: pointer;"
                                                                ></i>
                                                            </template>
                                                            <span
                                                                >Assign
                                                                {{
                                                                    asset.asset_name
                                                                }}
                                                                to
                                                                employee</span
                                                            >
                                                        </v-tooltip>
                                                        <v-tooltip
                                                            v-if="
                                                                permissionIsAssigned(
                                                                    'assets-update'
                                                                )
                                                            "
                                                            bottom
                                                        >
                                                            <template
                                                                v-slot:activator="{
                                                                    on,
                                                                    attrs,
                                                                }"
                                                            >
                                                                <i
                                                                    v-bind="
                                                                        attrs
                                                                    "
                                                                    v-on="on"
                                                                    class="col-3 py-0 text-center fal fa-edit"
                                                                    :class="
                                                                        $vuetify
                                                                            .breakpoint
                                                                            .name ==
                                                                            'xs' ||
                                                                        $vuetify
                                                                            .breakpoint
                                                                            .name ==
                                                                            'sm'
                                                                            ? 'col-1'
                                                                            : 'offset-1 col-3'
                                                                    "
                                                                    @click="
                                                                        edit_asset(
                                                                            asset
                                                                        )
                                                                    "
                                                                    style="color: #069 !important; font-size: 125%; cursor: pointer;"
                                                                ></i>
                                                            </template>
                                                            <span
                                                                >Edit
                                                                {{
                                                                    asset.asset_name
                                                                }}</span
                                                            >
                                                        </v-tooltip>
                                                        <v-tooltip
                                                            v-if="
                                                                permissionIsAssigned(
                                                                    'assets-delete'
                                                                )
                                                            "
                                                            bottom
                                                        >
                                                            <template
                                                                v-slot:activator="{
                                                                    on,
                                                                    attrs,
                                                                }"
                                                            >
                                                                <i
                                                                    v-bind="
                                                                        attrs
                                                                    "
                                                                    v-on="on"
                                                                    class="col-3 py-0 text-center fal fa-trash"
                                                                    @click="
                                                                        delete_asset(
                                                                            asset
                                                                        )
                                                                    "
                                                                    style="color: #069 !important; font-size: 125%; cursor: pointer;"
                                                                ></i>
                                                            </template>
                                                            <span
                                                                >Delete
                                                                {{
                                                                    asset.asset_name
                                                                }}</span
                                                            >
                                                        </v-tooltip>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-else
                    class="main-content"
                    :class="
                        $vuetify.breakpoint.name == 'xs' ||
                        $vuetify.breakpoint.name == 'sm'
                            ? 'px-2'
                            : null
                    "
                >
                    <div
                        class="breadcrumb"
                        style="margin-top: 1rem;"
                        :style="$vuetify.breakpoint.name != 'xs' ? null : null"
                    >
                        <h1>
                            <span>Edit Asset - {{ editFormData.name }}</span>
                        </h1>
                    </div>
                    <v-form @submit.prevent>
                        <div class="container-fluid">
                            <v-row>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="6"
                                    class="remove-hint"
                                >
                                    <span style="color: #111d5e;"
                                        >Asset Name
                                        <i
                                            class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                                        ></i
                                    ></span>
                                    <v-text-field
                                        v-model="editFormData.name"
                                        placeholder="Enter asset name"
                                        solo
                                        class="mt-2"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="6"
                                    class="remove-hint"
                                >
                                    <span style="color: #111d5e;"
                                        >Asset Description
                                        <i
                                            class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                                        ></i
                                    ></span>
                                    <v-text-field
                                        v-model="editFormData.description"
                                        placeholder="Enter asset description"
                                        solo
                                        class="mt-2"
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="6"
                                    class="remove-hint"
                                >
                                    <span style="color: #111d5e;"
                                        >Asset Type Name
                                        <i
                                            class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                                        ></i
                                    ></span>
                                    <v-select
                                        v-model="editFormData.type_name"
                                        item-color="#069"
                                        :items="asset_type_names"
                                        placeholder="Select asset type"
                                        solo
                                        class="mt-2"
                                        color="#069"
                                    ></v-select>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="6"
                                    class="remove-hint"
                                >
                                    <span style="color: #111d5e;"
                                        >Serial Number
                                        <i
                                            class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                                        ></i
                                    ></span>
                                    <v-text-field
                                        v-model="editFormData.serial_number"
                                        placeholder="Enter serial number"
                                        solo
                                        class="mt-2"
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="6"
                                    class="remove-hint"
                                >
                                    <span style="color: #111d5e;"
                                        >Vendor
                                        <i
                                            class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                                        ></i
                                    ></span>
                                    <v-select
                                        v-model="editFormData.vendor"
                                        item-color="#069"
                                        :items="vendor_names"
                                        placeholder="Select vendor"
                                        solo
                                        class="mt-2"
                                        color="#069"
                                    ></v-select>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="6"
                                    class="remove-hint"
                                >
                                    <span style="color: #111d5e;"
                                        >Unit Cost
                                        <i
                                            class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                                        ></i
                                    ></span>
                                    <v-text-field
                                        v-model="editFormData.unit_cost"
                                        placeholder="Enter unit cost"
                                        solo
                                        class="mt-2"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="6"
                                    class="remove-hint"
                                >
                                    <span style="color: #111d5e;"
                                        >Acquisition Cost
                                        <i
                                            class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                                        ></i
                                    ></span>
                                    <v-text-field
                                        v-model="editFormData.acquisition_cost"
                                        placeholder="Enter acquisition cost"
                                        solo
                                        class="mt-2"
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="6"
                                    class="remove-hint"
                                >
                                    <span style="color: #111d5e;"
                                        >Acquisition Date
                                        <i
                                            class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                                        ></i
                                    ></span>
                                    <v-menu
                                        ref="menu"
                                        v-model="menu"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-text-field
                                                color="#069"
                                                prepend-inner-icon="mdi-calendar-month-outline"
                                                v-model="
                                                    editFormData.acquisition_date
                                                "
                                                placeholder="Select acquisition date"
                                                solo
                                                class="mt-2"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            color="#069"
                                            ref="picker"
                                            v-model="
                                                editFormData.acquisition_date
                                            "
                                            :max="
                                                new Date(
                                                    new Date().getTime() +
                                                        86400000000
                                                )
                                                    .toISOString()
                                                    .substr(0, 10)
                                            "
                                            @change="save"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="6"
                                    class="remove-hint"
                                >
                                    <span style="color: #111d5e;"
                                        >Location Assigned To
                                        <i
                                            class="fal fa-asterisk"
                                            style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                                        ></i
                                    ></span>
                                    <v-text-field
                                        v-model="
                                            editFormData.location_assigned_to
                                        "
                                        placeholder="Enter location assigned to"
                                        solo
                                        class="mt-2"
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="6"
                                    class="remove-hint"
                                >
                                    <span style="color: #111d5e;"
                                        >Additional Comments</span
                                    >
                                    <v-text-field
                                        v-model="
                                            editFormData.additional_comments
                                        "
                                        placeholder="Enter additonal comments"
                                        solo
                                        class="mt-2"
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="remove-hint text-center"
                                >
                                    <button
                                        @click="
                                            update_asset_submit
                                                ? null
                                                : update_asset()
                                        "
                                        type="button"
                                        class="btn btn-secondary m-1 ripple"
                                    >
                                        {{ update_asset_text }}
                                        <v-icon
                                            :style="
                                                !update_asset_submit
                                                    ? 'display: none;'
                                                    : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                                            "
                                        >
                                            fal fa-circle-notch fa-spin
                                        </v-icon>
                                    </button>
                                    <button
                                        @click="create_asset = 2"
                                        type="button"
                                        class="btn btn-outline-secondary m-1"
                                    >
                                        Cancel
                                    </button>
                                </v-col>
                            </v-row>
                        </div>
                    </v-form>
                </div>
                <div class="flex-grow-1"></div>
            </template>
            <template v-else>
                <div
                    class="main-content"
                    :class="
                        $vuetify.breakpoint.name == 'xs' ||
                        $vuetify.breakpoint.name == 'sm'
                            ? 'px-2'
                            : null
                    "
                >
                    <div
                        class="breadcrumb mt-1 mb-2"
                        style="margin-top: 1rem;"
                        :style="
                            $vuetify.breakpoint.name == 'xs' ||
                            $vuetify.breakpoint.name == 'sm'
                                ? null
                                : null
                        "
                    >
                        <h1>
                            <span class="col-4">Your Assets</span>
                        </h1>
                    </div>
                    <div
                        class="main-content mt-0 row"
                        :class="
                            $vuetify.breakpoint.name == 'xs' ||
                            $vuetify.breakpoint.name == 'sm'
                                ? 'px-2'
                                : 'px-3'
                        "
                    >
                        <div class="card w-100" style="border-radius: 10px;">
                            <div class="card-body px-1 py-1 pt-0 pr-0">
                                <div
                                    class="table-responsive"
                                    style="border-radius: 10px; height: 75vh;
                                        overflow-y: scroll;
                                        padding-top: 0;
                                        padding-bottom: 0;"
                                >
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th
                                                    class="text-left"
                                                    style="border-top: none;"
                                                    scope="col"
                                                >
                                                    Asset Name
                                                </th>
                                                <th
                                                    class="text-left"
                                                    style="border-top: none;"
                                                    scope="col"
                                                >
                                                    Description
                                                </th>
                                                <th
                                                    class="text-left"
                                                    style="border-top: none;"
                                                    scope="col"
                                                >
                                                    Details
                                                </th>
                                                <th
                                                    class="text-left"
                                                    style="border-top: none;"
                                                    scope="col"
                                                >
                                                    Asset Type
                                                </th>
                                                <th
                                                    class="text-left"
                                                    style="border-top: none;"
                                                    scope="col"
                                                >
                                                    Given Date
                                                </th>
                                                <th
                                                    class="text-left"
                                                    style="border-top: none;"
                                                    scope="col"
                                                >
                                                    Return Date
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="asset in employee_assets"
                                                :key="asset.id"
                                            >
                                                <td class="text-left">
                                                    {{ asset.asset_name }}
                                                </td>
                                                <td class="text-left">
                                                    {{
                                                        asset.asset_description
                                                    }}
                                                </td>
                                                <td
                                                    v-if="asset.asset_details"
                                                    class="text-left"
                                                >
                                                    {{ asset.asset_details }}
                                                </td>
                                                <td v-else class="text-left">
                                                    <i>No details</i>
                                                </td>
                                                <td class="text-left">
                                                    {{
                                                        get_type_name(
                                                            asset.assettype_id
                                                        )
                                                    }}
                                                </td>
                                                <td class="text-left">
                                                    {{ asset.given_date }}
                                                </td>
                                                <td class="text-left">
                                                    {{ asset.return_date }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <v-row justify="center">
            <v-dialog v-model="dialog" max-width="600px">
                <v-card>
                    <v-card-title>
                        <span class="headline">{{
                            'Assign ' + assignFormData.name + ' to an employee'
                        }}</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-form @submit.prevent>
                                <div class="container-fluid">
                                    <v-row>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            md="6"
                                            class="remove-hint"
                                        >
                                            <span style="color: #111d5e;"
                                                >Asset Name
                                                <i
                                                    class="fal fa-asterisk"
                                                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                                                ></i
                                            ></span>
                                            <v-text-field
                                                :disabled="true"
                                                dense
                                                v-model="assignFormData.name"
                                                placeholder="Asset Name"
                                                solo
                                                class="mt-2"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            md="6"
                                            class="remove-hint"
                                        >
                                            <span style="color: #111d5e;"
                                                >Asset Type
                                                <i
                                                    class="fal fa-asterisk"
                                                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                                                ></i
                                            ></span>
                                            <v-text-field
                                                :disabled="true"
                                                dense
                                                v-model="
                                                    assignFormData.type_name
                                                "
                                                placeholder="Select Asset Type"
                                                solo
                                                class="mt-2"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            md="6"
                                            class="remove-hint"
                                        >
                                            <span style="color: #111d5e;"
                                                >Details
                                                <i
                                                    class="fal fa-asterisk"
                                                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                                                ></i
                                            ></span>
                                            <v-text-field
                                                dense
                                                v-model="assignFormData.details"
                                                placeholder="Details"
                                                solo
                                                class="mt-2"
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            md="6"
                                            class="remove-hint"
                                        >
                                            <span style="color: #111d5e;"
                                                >Employee Name
                                                <i
                                                    class="fal fa-asterisk"
                                                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                                                ></i
                                            ></span>
                                            <v-combobox
                                                dense
                                                v-model="
                                                    assignFormData.employee
                                                "
                                                item-color="#069"
                                                :items="allEmployees"
                                                placeholder="Select Employee"
                                                solo
                                                class="mt-2"
                                                color="#069"
                                            ></v-combobox>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            md="6"
                                            class="remove-hint"
                                        >
                                            <span style="color: #111d5e;"
                                                >Given Date
                                                <i
                                                    class="fal fa-asterisk"
                                                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                                                ></i
                                            ></span>
                                            <v-menu
                                                ref="menu3"
                                                v-model="menu3"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto"
                                            >
                                                <template
                                                    v-slot:activator="{
                                                        on,
                                                        attrs,
                                                    }"
                                                >
                                                    <v-text-field
                                                        dense
                                                        color="#069"
                                                        prepend-inner-icon="mdi-calendar-month-outline"
                                                        v-model="
                                                            assignFormData.given_date
                                                        "
                                                        placeholder="Given Date"
                                                        solo
                                                        class="mt-2"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    color="#069"
                                                    ref="picker3"
                                                    v-model="
                                                        assignFormData.given_date
                                                    "
                                                    :min="
                                                        new Date(
                                                            new Date().getTime() +
                                                                86400000
                                                        )
                                                            .toISOString()
                                                            .substr(0, 10)
                                                    "
                                                    :max="
                                                        new Date(
                                                            new Date().getTime() +
                                                                86400000000
                                                        )
                                                            .toISOString()
                                                            .substr(0, 10)
                                                    "
                                                    @change="save3"
                                                ></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            md="6"
                                            class="remove-hint"
                                        >
                                            <span style="color: #111d5e;"
                                                >Return Date
                                                <i
                                                    class="fal fa-asterisk"
                                                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                                                ></i
                                            ></span>
                                            <v-menu
                                                ref="menu4"
                                                v-model="menu4"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto"
                                            >
                                                <template
                                                    v-slot:activator="{
                                                        on,
                                                        attrs,
                                                    }"
                                                >
                                                    <v-text-field
                                                        dense
                                                        color="#069"
                                                        prepend-inner-icon="mdi-calendar-month-outline"
                                                        v-model="
                                                            assignFormData.return_date
                                                        "
                                                        placeholder="Return Date"
                                                        solo
                                                        class="mt-2"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    color="#069"
                                                    ref="picker4"
                                                    v-model="
                                                        assignFormData.return_date
                                                    "
                                                    :min="
                                                        new Date(
                                                            new Date().getTime() +
                                                                86400000
                                                        )
                                                            .toISOString()
                                                            .substr(0, 10)
                                                    "
                                                    :max="
                                                        new Date(
                                                            new Date().getTime() +
                                                                86400000000
                                                        )
                                                            .toISOString()
                                                            .substr(0, 10)
                                                    "
                                                    @change="save4"
                                                ></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            md="6"
                                            offset-md="3"
                                            offset-sm="3"
                                            class="remove-hint"
                                        >
                                            <button
                                                @click="
                                                    assign_asset_submit
                                                        ? null
                                                        : assign_asset_to_employee()
                                                "
                                                style="width: 100%;"
                                                type="button"
                                                class="btn btn-secondary m-1 ripple"
                                            >
                                                {{ assign_asset_text }}
                                                <v-icon
                                                    :style="
                                                        !assign_asset_submit
                                                            ? 'display: none;'
                                                            : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                                                    "
                                                >
                                                    fal fa-circle-notch fa-spin
                                                </v-icon>
                                            </button>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-form>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="dialog = false"
                        >
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
    import axios from 'axios';
    import { BASE_URL } from '@/main';
    import { mapActions, mapGetters } from 'vuex';
    export default {
        data() {
            return {
                employeeView: true,
                filters: {
                    string: '',
                },
                vendor_names: [],
                dialog: false,
                create_asset_text: 'Submit',
                create_asset_submit: false,
                update_asset_text: 'Update',
                update_asset_submit: false,
                assign_asset_text: 'Assign',
                assign_asset_submit: false,
                create_asset: 2,
                employee_assets: [],
                menu: false,
                menu3: false,
                menu4: false,
                // menu: false,
                assignFormData: {
                    id: '',
                    type_name: '',
                    name: '',
                    given_date: '',
                    return_date: '',
                    details: '',
                    employee: '',
                },
                formData: {
                    name: '',
                    description: '',
                    serial_number: '',
                    type_name: '',
                    acquisition_date: '',
                    vendor: '',
                    unit_cost: '',
                    location_assigned_to: '',
                    acquisition_cost: '',
                },
                editFormData: {
                    id: '',
                    name: '',
                    description: '',
                    serial_number: '',
                    type_name: '',
                    acquisition_date: '',
                    vendor: '',
                    unit_cost: '',
                    location_assigned_to: '',
                    acquisition_cost: '',
                },
                allEmployees: [],
                // all_asset_types: [],
                // apply_for_leave_text: 'Submit',
                // apply_for_leave_submit: false,
            };
        },
        watch: {
            menu(val) {
                val &&
                    setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'));
            },
            menu3(val) {
                val &&
                    setTimeout(
                        () => (this.$refs.picker3.activePicker = 'YEAR')
                    );
            },
            menu4(val) {
                val &&
                    setTimeout(
                        () => (this.$refs.picker4.activePicker = 'YEAR')
                    );
            },
        },
        computed: {
            ...mapGetters([
                'validEmployeeId',
                'authCompanyData',
                'authEmployeeDataForCompany',
                'authUserData',
                'authCompanyAssets',
                'userType',
                'authCompanyAllAssetType',
                'authCompanyAllVendors',
            ]),
            filteredAssets() {
                let _this = this,
                    assets = [],
                    isTrue = null;
                if (!_this.filters.string) {
                    return _this.authCompanyAssets;
                }

                if (_this.authCompanyAssets) {
                    assets = _this.authCompanyAssets.filter((a) => {
                        isTrue =
                            a.AssetType.toLowerCase().indexOf(
                                _this.filters.string.toLowerCase()
                            ) != -1 ||
                            a.acqusition_cost
                                .toLowerCase()
                                .indexOf(_this.filters.string.toLowerCase()) !=
                                -1 ||
                            a.asset_description
                                .toLowerCase()
                                .indexOf(_this.filters.string.toLowerCase()) !=
                                -1 ||
                            a.asset_name
                                .toLowerCase()
                                .indexOf(_this.filters.string.toLowerCase()) !=
                                -1 ||
                            a.assettype_name
                                .toLowerCase()
                                .indexOf(_this.filters.string.toLowerCase()) !=
                                -1 ||
                            a.location_assigned_to
                                .toLowerCase()
                                .indexOf(_this.filters.string.toLowerCase()) !=
                                -1 ||
                            a.vendor
                                .toLowerCase()
                                .indexOf(_this.filters.string.toLowerCase()) !=
                                -1;
                        return isTrue;
                    });
                }
                return assets;
            },
            employee_id() {
                let _this = this;
                if (_this.assignFormData.employee) {
                    return _this
                        .mixinBranchEmployees(_this.$route.name)
                        .filter((person) => {
                            return (
                                _this.assignFormData.employee ==
                                person.employee_fristname +
                                    ' ' +
                                    person.employee_lastname
                            );
                        })[0].id;
                } else {
                    return '';
                }
            },
            asset_type_names() {
                let _this = this,
                    all = [];
                if (_this.authCompanyAllAssetType) {
                    _this.authCompanyAllAssetType.forEach((type) => {
                        all.push(type.assettype_name);
                    });
                }
                return all;
            },
            edit_asset_type_id() {
                let _this = this;
                if (_this.editFormData.type_name) {
                    return _this.authCompanyAllAssetType.filter((type) => {
                        return (
                            _this.editFormData.type_name == type.assettype_name
                        );
                    })[0].id;
                } else {
                    return '';
                }
            },
            assign_asset_type_id() {
                let _this = this;
                if (_this.assignFormData.type_name) {
                    return _this.authCompanyAllAssetType.filter((type) => {
                        return (
                            _this.assignFormData.type_name ==
                            type.assettype_name
                        );
                    })[0].id;
                } else {
                    return '';
                }
            },
            asset_type_id() {
                let _this = this;
                if (_this.formData.type_name) {
                    return _this.authCompanyAllAssetType.filter((type) => {
                        return _this.formData.type_name == type.assettype_name;
                    })[0].id;
                } else {
                    return '';
                }
            },
        },
        methods: {
            ...mapActions(['fetch_company_assets', 'fetch_all_asset_types']),
            hasHistory: function() {
                return window.history.length > 2;
            },
            save(date) {
                this.$refs.menu.save(date);
            },
            save3(date) {
                this.$refs.menu3.save(date);
            },
            save4(date) {
                this.$refs.menu4.save(date);
            },
            tweak_display() {
                if (this.create_asset == 1) {
                    this.create_asset = 2;
                } else if (
                    this.create_asset == 2 &&
                    this.permissionIsAssigned('assets-create')
                ) {
                    this.create_asset = 1;
                } else {
                    ('');
                }
            },
            get_type_name(type_id) {
                let _this = this;
                if (type_id && _this.authCompanyAllAssetType) {
                    let type = _this.authCompanyAllAssetType.filter(
                        (type) => type.id == type_id
                    );
                    // console.log(type[0].leave_name);
                    if (type.length >= 1) {
                        return type[0].assettype_name;
                    } else {
                        return '';
                    }
                } else {
                    return '';
                }
            },
            assign_asset_launcher(asset) {
                let _this = this;
                _this.assignFormData.id = asset.id;
                // _this.assignFormData.type_name = _this.get_type_name(
                //   asset.assettype_name
                // );
                _this.assignFormData.type_name = asset.assettype_name;
                _this.assignFormData.name = asset.asset_name;
                _this.dialog = true;
            },
            fetch_employee_assets() {
                let _this = this;
                axios
                    .post(`${BASE_URL}fetchemployeeasset`, {
                        company_id:
                            _this.authEmployeeDataForCompany.company_id ||
                            _this.authCompanyData.id,
                    })
                    .then(function(response) {
                        console.log(RESPONSE);
                        const RESPONSE = response.data;
                        RESPONSE.Employees.forEach((asset) => {
                            if (_this.userType == 1) {
                                _this.employee_assets.push(asset);
                            } else {
                                if (
                                    asset.employee_id !=
                                    _this.authEmployeeDataForCompany.id
                                ) {
                                    ('');
                                } else {
                                    _this.employee_assets.push(asset);
                                }
                            }
                        });
                    })
                    .catch(function(error) {
                        console.log(error.response);
                    })
                    .then(function() {
                        //
                    });
            },
            edit_asset(asset) {
                let _this = this;
                _this.create_asset = 3;
                _this.editFormData.id = asset.id;
                _this.editFormData.name = asset.asset_name;
                _this.editFormData.description = asset.asset_description;
                _this.editFormData.serial_number = asset.serial_number;
                _this.editFormData.type_name = _this.get_type_name(asset.id);
                _this.editFormData.acquisition_date = asset.acqusation_date;
                _this.editFormData.vendor = asset.vendor;
                _this.editFormData.unit_cost = asset.unit_cost;
                _this.editFormData.location_assigned_to =
                    asset.location_assigned_to;
                _this.editFormData.acquisition_cost = asset.acqusition_cost;
            },
            delete_asset(asset) {
                let _this = this;

                const Toast = _this.$swal.mixin({
                    toast: true,
                    position: 'top-end',
                    company_id: _this.authCompanyData.id,
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener(
                            'mouseenter',
                            _this.$swal.stopTimer
                        );
                        toast.addEventListener(
                            'mouseleave',
                            _this.$swal.resumeTimer
                        );
                    },
                });

                _this.$swal
                    .fire({
                        title:
                            'Are you sure you want to delete [' +
                            asset.asset_name +
                            '] from company assets?',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6 !important',
                        confirmButtonText: `Yes, Delete`,
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            axios
                                .post(`${BASE_URL}deleteasset`, {
                                    ids: [
                                        {
                                            id: asset.id,
                                        },
                                    ],
                                })
                                .then(function() {
                                    _this.fetch_all_asset_types();
                                    _this.fetch_company_assets();
                                    Toast.fire({
                                        icon: 'success',
                                        title: 'Asset deleted successfully',
                                    });
                                })
                                .catch(function(error) {
                                    Toast.fire({
                                        icon: 'error',
                                        title:
                                            asset.asset_name +
                                            " couldn't be deleted",
                                    });
                                    console.log(error);
                                })
                                .then(function() {
                                    //
                                });
                        }
                    });
            },
            assign_asset_to_employee() {
                let _this = this;
                _this.assign_asset_text = '';
                _this.assign_asset_submit = true;
                const Toast = _this.$swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener(
                            'mouseenter',
                            _this.$swal.stopTimer
                        );
                        toast.addEventListener(
                            'mouseleave',
                            _this.$swal.resumeTimer
                        );
                    },
                });

                axios
                    .post(`${BASE_URL}createemployeeasset`, {
                        company_id: _this.authCompanyData.id,
                        asset_id: _this.assignFormData.id,
                        employee_id: _this.employee_id,
                        employee_name: _this.assignFormData.employee,
                        assettype_id: _this.assign_asset_type_id,
                        given_date: _this.assignFormData.given_date,
                        return_date: _this.assignFormData.return_date,
                        asset_details: _this.assignFormData.details,
                    })
                    .then(function() {
                        _this.fetch_all_asset_types();
                        _this.fetch_company_assets();

                        Toast.fire({
                            icon: 'success',
                            title:
                                'Asset has been assigned to ' +
                                _this.assignFormData.employee,
                        });
                        _this.assignFormData.id = '';
                        _this.assignFormData.employee = '';
                        _this.assign_asset_type_id = '';
                        _this.assignFormData.given_date = '';
                        _this.assignFormData.return_date = '';
                        _this.assignFormData.details = '';
                        _this.dialog = false;
                    })
                    .catch(function(error) {
                        Toast.fire({
                            icon: 'error',
                            title: 'Asset could not be assigned',
                        });
                        console.log(error.response);
                    })
                    .then(function() {
                        _this.assign_asset_submit = false;
                        _this.assign_asset_text = 'Assign';
                    });
            },
            update_asset() {
                let _this = this;
                _this.update_asset_text = '';
                _this.update_asset_submit = true;
                const Toast = _this.$swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener(
                            'mouseenter',
                            _this.$swal.stopTimer
                        );
                        toast.addEventListener(
                            'mouseleave',
                            _this.$swal.resumeTimer
                        );
                    },
                });

                axios
                    .post(`${BASE_URL}editasset`, {
                        company_id: _this.authCompanyData.id,
                        id: _this.editFormData.id,
                        asset_name: _this.editFormData.name,
                        asset_description: _this.editFormData.description,
                        serial_number: _this.editFormData.serial_number,
                        assettype_name: _this.edit_asset_type_id,
                        acqusation_date: _this.editFormData.acquisition_date,
                        vendor: _this.editFormData.vendor,
                        unit_cost: _this.editFormData.unit_cost,
                        location_assigned_to:
                            _this.editFormData.location_assigned_to,
                        acqusition_cost: _this.editFormData.acquisition_cost,
                    })
                    .then(function() {
                        _this.editFormData.name = '';
                        _this.editFormData.description = '';
                        _this.editFormData.serial_number = '';
                        _this.editFormData.type_name = '';
                        _this.editFormData.acquisition_date = '';
                        _this.editFormData.vendor = '';
                        _this.editFormData.unit_cost = '';
                        _this.editFormData.location_assigned_to = '';
                        _this.editFormData.acquisition_cost = '';

                        _this.fetch_all_asset_types();
                        _this.fetch_company_assets();

                        Toast.fire({
                            icon: 'success',
                            title: 'Asset Updated',
                        });
                        _this.create_asset = 2;
                    })
                    .catch(function(error) {
                        Toast.fire({
                            icon: 'error',
                            title: 'Assets could not be updated',
                        });
                        console.log(error.response);
                    })
                    .then(function() {
                        _this.update_asset_submit = false;
                        _this.update_asset_text = 'Update';
                    });
            },
            add_asset() {
                let _this = this;
                _this.create_asset_text = '';
                _this.create_asset_submit = true;
                const Toast = _this.$swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener(
                            'mouseenter',
                            _this.$swal.stopTimer
                        );
                        toast.addEventListener(
                            'mouseleave',
                            _this.$swal.resumeTimer
                        );
                    },
                });

                axios
                    .post(`${BASE_URL}createasset`, {
                        company_id: _this.authCompanyData.id,
                        asset_name: _this.formData.name,
                        asset_description: _this.formData.description,
                        serial_number: _this.formData.serial_number,
                        assettype_name: _this.asset_type_id,
                        acqusation_date: _this.formData.acquisition_date,
                        vendor: _this.formData.vendor,
                        unit_cost: _this.formData.unit_cost,
                        location_assigned_to:
                            _this.formData.location_assigned_to,
                        acqusition_cost: _this.formData.acquisition_cost,
                    })
                    .then(function() {
                        _this.formData.name = '';
                        _this.formData.description = '';
                        _this.formData.serial_number = '';
                        _this.formData.type_name = '';
                        _this.formData.acquisition_date = '';
                        _this.formData.vendor = '';
                        _this.formData.unit_cost = '';
                        _this.formData.location_assigned_to = '';
                        _this.formData.acquisition_cost = '';

                        _this.fetch_all_asset_types();
                        _this.fetch_company_assets();

                        Toast.fire({
                            icon: 'success',
                            title: 'Asset Created',
                        });
                        _this.create_asset = 1;
                    })
                    .catch(function(error) {
                        Toast.fire({
                            icon: 'error',
                            title: 'Asset could not be created',
                        });
                        console.log(error.response);
                    })
                    .then(function() {
                        _this.create_asset_submit = false;
                        _this.create_asset_text = 'Submit';
                    });
            },
        },
        mounted() {
            document.title = 'Kylian ERP - Assets';
            let _this = this;
            _this.fetch_all_asset_types();
            if (_this.authCompanyAllVendors) {
                _this.authCompanyAllVendors.forEach((vendor) => {
                    _this.vendor_names.push(
                        vendor.vendor_name + ' (' + vendor.vendor_company + ')'
                    );
                });
            }
            if (_this.userType) {
                _this
                    .mixinBranchEmployees(_this.$route.name)
                    .forEach((person) => {
                        _this.allEmployees.push(
                            person.employee_fristname +
                                ' ' +
                                person.employee_lastname
                        );
                    });
                _this.fetch_employee_assets();
            } else {
                _this.fetch_employee_assets();
            }
        },
    };
</script>

<style>
    .remove-hint div div div.v-text-field__details {
        display: none !important;
    }

    .educationField .v-text-field__details {
        display: none !important;
    }

    .educationField .v-input__slot {
        margin: 0;
    }
</style>
