<template>
    <div class="" style="margin-top: 100px;">
        <v-app class="">
            <!-- <h3>Open your free account</h3>
      <v-form>
        <v-container>
          <v-row>
            <v-col class="pb-0" cols="12" md="12" lg="6">
              <v-text-field
                dense
                label="Full Name"
                solo
                v-model="formData.name"
              ></v-text-field>
            </v-col>

            <v-col class="pb-0" cols="12" md="12" lg="6">
              <v-text-field
                dense
                label="Company Name"
                solo
                v-model="formData.company_name"
              ></v-text-field>
            </v-col>

            <v-col class="pb-0 company-name" cols="12" md="12" lg="8">
              <v-text-field
                dense
                solo
                prefix="https://kylianerp.com/"
                hint="*Enter your preferred company URL"
                v-model="formData.subdomain"
              ></v-text-field>
            </v-col>

            <v-col class="d-flex pb-0" cols="12" md="12" lg="4">
              <v-text-field
                type="number"
                dense
                label="Number of Staff"
                solo
                v-model="formData.number_of_employees"
              ></v-text-field>
            </v-col>

            <v-col class="pb-0" cols="12" md="12" lg="6">
              <v-text-field
                dense
                label="Email Address"
                solo
                v-model="formData.email"
              ></v-text-field>
            </v-col>

            <v-col class="pb-0" cols="12" md="12" lg="6">
              <v-text-field
                dense
                label="Phone Number"
                solo
                v-model="formData.phone"
              ></v-text-field>
            </v-col>

            <v-col class="pb-0" cols="12" md="12" lg="6">
              <v-text-field
                dense
                solo
                :append-icon="show ? 'fas fa-eye' : 'fas fa-eye-slash'"
                :rules="[rules.required, rules.min]"
                :type="show ? 'text' : 'password'"
                name="input-10-2"
                label="Create Password"
                class="input-group--focused"
                @click:append="show = !show"
                color="#069"
                v-model="formData.password"
              ></v-text-field>
            </v-col>

            <v-col class="pb-0" cols="12" md="12" lg="6">
              <v-text-field
                dense
                solo
                :append-icon="qShow ? 'fas fa-eye' : 'fas fa-eye-slash'"
                :rules="[qRulesRequired, qRulesMin]"
                :type="qShow ? 'text' : 'password'"
                name="input-10-2"
                label="Confirm Password"
                class="input-group--focused"
                @click:append="qShow = !qShow"
                color="#069"
                v-model="formData.confirm_password"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="12">
              <v-btn @click.prevent="isLoading ? null : register_request(formData)" type="button" x-large dark class="create-submit">
                {{ btnTxt }}
                <v-icon :style="!isLoading ? 'display: none' : ''"
                  >fas fa-circle-notch fa-spin</v-icon
                >
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <div class="text-center">
        <small
          >By clicking the “Open account” button, you agree to
          <router-link id="small-link" tag="a" :to="{ name: 'Home' }"
            >Kylian ERP</router-link
          >
          terms of acceptable use.</small
        >
      </div> -->

            <!-- <v-row>
        <v-col
          :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || || $vuetify.breakpoint.name == 'md' ? 'margin-bottom: 85px;' : null"
          style="margin-bottom: 50px;" class="px-0" cols="12" lg="7" md="7">
          <v-form @submit.prevent="isLoading ? null : register_request(formData)" class="mt-10 mt-md-0">
            <v-container>
              <v-row :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || || $vuetify.breakpoint.name == 'md' ? 'px-4' : null">
                <v-col cols="12" class="text-center">
                  <img v-if="$vuetify.breakpoint.name != 'xs' && $vuetify.breakpoint.name != 'sm'"
                    src="/img/kylian-erp-logo.png" style="opacity: 0; width: 10%;" alt="">
                </v-col>
                <v-col cols="12" class="text-center">
                  <h3 class="text-new-primary">Create your account</h3>
                  <p style="font-size: 17px;">Create your company account by entering information below</p>
                </v-col>
                <v-col cols="12" xl="6" lg="6" md="6" class="pb-0">
                  <v-text-field v-model="formData.name" label="Contact Name" outlined
                    placeholder="Enter your contact full name"></v-text-field>
                </v-col>
                <v-col cols="12" xl="6" lg="6" md="6" class="pb-0">
                  <v-text-field v-model="formData.company_name" label="Company Name" outlined
                    placeholder="Enter your company name"></v-text-field>
                </v-col>
                <v-col cols="12" xl="6" lg="6" md="6" class="pb-0">
                  <v-text-field v-model="formData.number_of_employees" label="Number of staff" outlined :min="1"
                    type="number" placeholder="Enter the number of staff"></v-text-field>
                </v-col>
                <v-col cols="12" xl="6" lg="6" md="6" class="pb-0">
                  <v-text-field v-model="formData.phone" label="Phone Number" outlined
                    placeholder="Enter your phone number"></v-text-field>
                </v-col>
                <v-col cols="12" xl="6" lg="6" md="6" class="pb-0">
                  <v-text-field v-model="formData.email" label="Email Address" outlined></v-text-field>
                </v-col>
                <v-col cols="12" xl="6" lg="6" md="6" class="pb-0">
                  <v-text-field v-model="formData.password" label="Password" outlined
                    :append-icon="show ? 'fal fa-eye' : 'fal fa-eye-slash'" :type="show ? 'text' : 'password'"
                    :rules="[rules.required, rules.min]" @click:append="show = !show"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" xl="6" lg="6" md="6" class="pb-0">
                  <v-text-field v-model="formData.confirm_password" label="Confirm password" outlined
                    :append-icon="qShow ? 'fal fa-eye' : 'fal fa-eye-slash'" :type="qShow ? 'text' : 'password'"
                    :rules="[qRulesRequired, qRulesMin]" @click:append="qShow = !qShow"
                    ></v-text-field>
                </v-col>
                <v-col class="pb-6 text-center mt-5" cols="12" sm="12">
                  <v-btn @click.prevent="isLoading ? null : register_request(formData)" type="submit" x-large dark
                    class="new-create-submit"
                    :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || || $vuetify.breakpoint.name == 'md' ? 'w-100' : 'w-50'">
                    {{ btnTxt }}
                    <v-icon :style="!isLoading ? 'display: none' : ''">fas fa-circle-notch fa-spin</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.name != 'xs' && $vuetify.breakpoint.name != 'sm'" class="px-0" cols="12" lg="4"
          md="4">
          <img class="w-100" src="/img/login_img.png" alt="login image">
        </v-col>
      </v-row> -->

            <v-row>
                <v-col class="px-0 d-none d-md-block" cols="12" md="6">
                    <img
                        src="/img/auth.png"
                        style="height: 88vh; width: 100%;"
                    />
                </v-col>
                <v-col
                    class="px-0 py-0"
                    cols="12"
                    md="6"
                    style="background: #FFF; border-radius: 10px;"
                    :style="
                        $vuetify.breakpoint.name == 'xs' ||
                        $vuetify.breakpoint.name == 'sm' ||
                        $vuetify.breakpoint.name == 'md'
                            ? ''
                            : 'position: absolute; top: 30px; right: 200px;'
                    "
                >
                    <div
                        class="card"
                        style="background: #FFFFFF; box-shadow: 2px 6px 50px rgba(0, 0, 43, 0.15); border-radius: 10px;"
                    >
                        <div class="card-body py-0">
                            <v-form
                                ref="form"
                                @submit.prevent="
                                    isLoading
                                        ? null
                                        : register_request(formData)
                                "
                                class="mt-10 mt-md-0"
                            >
                                <v-container
                                    :style="
                                        $vuetify.breakpoint.name == 'xs' ||
                                        $vuetify.breakpoint.name == 'sm' ||
                                        $vuetify.breakpoint.name == 'md'
                                            ? 'padding: 20px 0px;'
                                            : 'padding: 40px 12px;'
                                    "
                                >
                                    <v-row
                                        :class="
                                            $vuetify.breakpoint.name == 'xs' ||
                                            $vuetify.breakpoint.name == 'sm' ||
                                            $vuetify.breakpoint.name == 'md'
                                                ? 'px-4'
                                                : null
                                        "
                                        :style="
                                            $vuetify.breakpoint.name == 'xs' ||
                                            $vuetify.breakpoint.name == 'sm' ||
                                            $vuetify.breakpoint.name == 'md'
                                                ? 'padding-bottom: 80px;'
                                                : null
                                        "
                                    >
                                        <v-col cols="12" class="text-center">
                                            <h3
                                                class="text-new-primary"
                                                style="color: #111023; font-weight: 500; font-size: 33.18px;"
                                            >
                                                Create an account
                                            </h3>
                                            <p
                                                style="font-size: 16px; color: #6B6A7D; font-weight: 500;"
                                            >
                                                Create your company account by
                                                entering information below
                                            </p>
                                        </v-col>
                                        <v-col cols="12" md="6" class="pb-0">
                                            <v-text-field
                                                v-model="formData.name"
                                                label="Contact Name *"
                                                outlined
                                                dense
                                                placeholder="Enter the contact full name"
                                                :rules="[
                                                    (v) =>
                                                        !!v ||
                                                        'Name is required',
                                                ]"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6" class="pb-0">
                                            <v-select
                                                dense
                                                v-model="formData.role"
                                                :rules="[
                                                    (v) =>
                                                        !!v ||
                                                        'This is required',
                                                ]"
                                                label="Role *"
                                                outlined
                                                :items="[
                                                    'Business Owner',
                                                    'CEO',
                                                    'Director',
                                                    'HR Business Partner',
                                                    'HR Manager',
                                                    'HR Consultant',
                                                    'HR Officer',
                                                    'Recruitment Specialist',
                                                    'Employee Relations Specialist',
                                                    'Other',
                                                ]"
                                            ></v-select>
                                        </v-col>
                                        <v-col cols="12" md="6" class="pb-0">
                                            <v-text-field
                                                v-model="formData.company_name"
                                                label="Company Name *"
                                                outlined
                                                dense
                                                placeholder="Enter the company name"
                                                :rules="[
                                                    (v) =>
                                                        !!v ||
                                                        'Company name is required',
                                                ]"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6" class="pb-0">
                                            <v-text-field
                                                v-model="
                                                    formData.number_of_employees
                                                "
                                                label="Number of users *"
                                                outlined
                                                dense
                                                type="number"
                                                placeholder="Enter the number of users"
                                                :rules="[
                                                    (v) =>
                                                        !!v ||
                                                        'Number of users is required',
                                                ]"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6" class="pb-0">
                                            <v-text-field
                                                v-model="formData.phone"
                                                label="Phone Number *"
                                                outlined
                                                dense
                                                placeholder="Enter your phone number"
                                                :rules="[
                                                    (v) =>
                                                        !!v ||
                                                        'Phone number is required',
                                                ]"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6" class="pb-0">
                                            <v-text-field
                                                label="Company Location (optional)"
                                                outlined
                                                dense
                                                placeholder="Enter your company location"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6" class="pb-0">
                                            <v-text-field
                                                v-model="formData.email"
                                                label="Email Address *"
                                                outlined
                                                dense
                                                placeholder="Enter your email address"
                                                :rules="[
                                                    (v) =>
                                                        !!v ||
                                                        'Email address is required',
                                                    (v) =>
                                                        (v.indexOf('@') != -1 &&
                                                            v.indexOf('.') !=
                                                                -1) ||
                                                        'Entry must be an email',
                                                ]"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6" class="pb-0">
                                            <v-text-field
                                                dense
                                                v-model="formData.password"
                                                label="Password *"
                                                outlined
                                                :append-icon="
                                                    show
                                                        ? 'fal fa-eye'
                                                        : 'fal fa-eye-slash'
                                                "
                                                :type="
                                                    show ? 'text' : 'password'
                                                "
                                                :rules="[
                                                    rules.required,
                                                    rules.min,
                                                    (v) =>
                                                        !!v ||
                                                        'Password is required',
                                                    (v) =>
                                                        v.length >= 8 ||
                                                        'Password must be at least 8 characters long',
                                                ]"
                                                @click:append="show = !show"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6" class="pb-0">
                                            <v-text-field
                                                dense
                                                v-model="
                                                    formData.confirm_password
                                                "
                                                label="Confirm password *"
                                                outlined
                                                :append-icon="
                                                    qShow
                                                        ? 'fal fa-eye'
                                                        : 'fal fa-eye-slash'
                                                "
                                                :type="
                                                    qShow ? 'text' : 'password'
                                                "
                                                :rules="[
                                                    qRulesRequired,
                                                    qRulesMin,
                                                ]"
                                                @click:append="qShow = !qShow"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6" class="pb-0">
                                            <!-- <v-text-field 
                        dense v-model="formData.how_you_heard" label="How did you hear about us *" outlined
                        :append-icon="qShow ? 'fal fa-eye' : 'fal fa-eye-slash'" :type="qShow ? 'text' : 'password'"
                        :rules="[qRulesRequired, qRulesMin]" @click:append="qShow = !qShow"
                        ></v-text-field> -->
                                            <v-select
                                                dense
                                                v-model="formData.how_you_heard"
                                                :rules="[
                                                    (v) =>
                                                        !!v ||
                                                        'This is required',
                                                ]"
                                                label="How did you hear about us *"
                                                outlined
                                                :items="[
                                                    'Twitter',
                                                    'Instagram',
                                                    'Facebook',
                                                    'LinkedIn',
                                                    'YouTube',
                                                    'A friend/colleague',
                                                    'Other',
                                                ]"
                                            ></v-select>
                                        </v-col>
                                        <v-col cols="12" class="py-4">
                                            <!-- I CAN USE SLOTS FOR THE LABEL BELOW IF WE EVER HAVE TERMS AND CONDITIONS TO ADD -->
                                            <v-checkbox
                                                v-model="
                                                    formData.agree_to_terms
                                                "
                                                :rules="[
                                                    (v) =>
                                                        !!v ||
                                                        'Please agree to our terms',
                                                ]"
                                                class="py-0 my-0"
                                                dense
                                                density="compact"
                                                color="#0B54C0"
                                                hide-details
                                            >
                                                <template v-slot:label>
                                                    <div>
                                                        I agree to the Kylian
                                                        ERP
                                                        <a
                                                            target="_blank"
                                                            href="#"
                                                            @click.stop
                                                        >
                                                            terms and conditions
                                                        </a>
                                                    </div>
                                                </template>
                                            </v-checkbox>
                                        </v-col>
                                        <v-col
                                            class="pb-6 text-center"
                                            cols="12"
                                            md="6"
                                            offset-md="3"
                                        >
                                            <v-btn
                                                style="background: #0B54C0 !important; height: 40px; border-radius: 4px !important;"
                                                type="submit"
                                                x-large
                                                dark
                                                class="new-create-submit w-100"
                                                :class="
                                                    $vuetify.breakpoint.name ==
                                                        'xs' ||
                                                    $vuetify.breakpoint.name ==
                                                        'sm' ||
                                                    $vuetify.breakpoint.name ==
                                                        'md'
                                                        ? 'w-100'
                                                        : 'w-50'
                                                "
                                            >
                                                {{ btnTxt }}
                                                <v-icon
                                                    :style="
                                                        !isLoading
                                                            ? 'display: none'
                                                            : ''
                                                    "
                                                    >fas fa-circle-notch
                                                    fa-spin</v-icon
                                                >
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </div>
                    </div>
                </v-col>
            </v-row>

            <div class="text-center">
                <v-dialog v-model="dialog" width="500">
                    <v-card>
                        <v-card-text class="pt-5">
                            <div class="col-md-8 mx-auto text-center pb-5">
                                <img
                                    src="@/assets/graphics/cross-check.png"
                                    alt=""
                                    class="img-fluid"
                                />
                            </div>
                            <h3
                                class="msg pl-5 pr-5 text-center"
                                style="display: block;"
                            >
                                {{ registerError }}
                            </h3>
                        </v-card-text>

                        <v-divider class="mb-0"></v-divider>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="#069" text @click="dialog = false">
                                <strong>Close</strong>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>

            <div class="text-center">
                <v-dialog v-model="incompleteDialog" width="500">
                    <v-card>
                        <v-card-text class="pt-5">
                            <div class="col-md-8 mx-auto text-center pb-5">
                                <img
                                    src="@/assets/graphics/cross-check.png"
                                    alt=""
                                    class="img-fluid"
                                />
                            </div>
                            <h3
                                class="msg pl-5 pr-5 text-center"
                                style="display: block;"
                            >
                                Please ensure all fields are filled
                            </h3>
                        </v-card-text>

                        <v-divider class="mb-0"></v-divider>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="#069"
                                text
                                @click="incompleteDialog = false"
                            >
                                <strong>Close</strong>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>

            <div class="text-center">
                <v-dialog v-model="dialogSuccess" width="500">
                    <v-card>
                        <v-card-text class="pt-5">
                            <div class="col-md-8 mx-auto text-center pb-5">
                                <img
                                    src="@/assets/graphics/check.png"
                                    alt=""
                                    class="img-fluid"
                                />
                            </div>
                            <h3
                                class="msg pl-5 pr-5 text-center"
                                style="display: block;"
                            >
                                Account Created Successfully.<br />Please check
                                your email to verify your account
                            </h3>
                        </v-card-text>

                        <v-divider class="mb-0"></v-divider>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="#069"
                                text
                                @click="closeDialogSuccess"
                            >
                                <strong>Close</strong>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>

            <div class="text-center">
                <v-dialog v-model="over30Dialog" width="500">
                    <v-card>
                        <v-card-text class="pt-5">
                            <div class="col-md-8 mx-auto text-center pb-5">
                                <img
                                    src="@/assets/graphics/attention.png"
                                    alt=""
                                    class="img-fluid"
                                />
                            </div>
                            <h3
                                class="msg pl-5 pr-5 text-center"
                                style="display: block;"
                            >
                                Please for more than 30 employees, contact
                                kylian@kylianerp.com or call +2348055257262 to
                                proceed.
                            </h3>
                        </v-card-text>

                        <v-divider class="mb-0"></v-divider>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="#069"
                                text
                                @click="over30Dialog = false"
                            >
                                <strong>Close</strong>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>

            <div class="text-center">
                <v-dialog v-model="termsDialog" width="500">
                    <v-card>
                        <v-card-text class="pt-5">
                            <div class="col-md-8 mx-auto text-center pb-5">
                                <img
                                    src="@/assets/graphics/attention.png"
                                    alt=""
                                    class="img-fluid"
                                />
                            </div>
                            <h3
                                class="msg pl-5 pr-5 text-center"
                                style="display: block;"
                            >
                                Kindly agree to our terms and conditions to
                                proceed.
                            </h3>
                        </v-card-text>

                        <v-divider class="mb-0"></v-divider>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="#069"
                                text
                                @click="termsDialog = false"
                            >
                                <strong>Close</strong>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
        </v-app>
    </div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex';
    export default {
        data() {
            return {
                over30Dialog: false,
                incompleteDialog: false,
                dialog: false,
                dialogSuccess: false,
                termsDialog: false,
                text: 'ERROR REGISTERING COMPANY',
                timeout: 5000,
                btnTxt: 'Create Account',
                isLoading: false,
                formData: {
                    agree_to_terms: false,
                    name: '',
                    company_name: '',
                    email: '',
                    phone: '',
                    // subdomain: "",
                    password: '',
                    confirm_password: '',
                    number_of_employees: '',
                    how_you_heard: '',
                    role: '',
                },
                show: false,
                qShow: false,
                password: 'Password',
                rules: {
                    required: (value) => !!value || 'Enter a password here',
                    //   required (value) {
                    //       return !!value || Enter a password here
                    //   }
                    min: (value) => value.length >= 8 || 'Min 8 characters',
                    //   min (value) {
                    //       return value.length >= 8 || 'Min 8 characters'
                    //   }
                },
            };
        },
        watch: {
            authStatus(newValue) {
                const _this = this;
                if (newValue === 'registered') {
                    console.log('registered');
                    // _this.$router.push('/clientarea')
                    _this.isLoading = false;
                    _this.dialogSuccess = true;
                    setTimeout(function() {
                        _this.$router.push('/login');
                    }, 10000);
                    _this.$watch('dialogSuccess', (newVal) => {
                        if (newVal === false) {
                            _this.$router.push('/login');
                        }
                    });
                } else if (newValue === 'error') {
                    console.log('error');
                    _this.isLoading = false;
                    _this.btnTxt = 'Create Account';
                    // alert('Error Creating Account');
                    _this.dialog = true;
                    _this.$store.commit('AUTH_LOGOUT');
                } else {
                    console.log('none');
                    ('');
                }
            },
        },
        computed: {
            ...mapGetters(['authStatus']),
            ...mapState(['registerError']),
        },
        methods: {
            closeDialogSuccess() {
                this.dialogSuccess = false;
                // this.$router.push('/login')
            },
            // qRules: {
            //   required: value => !!value || 'Confirm password here',
            // //   required (value) {
            // //       return !!value || Enter a password here
            // //   }
            //   min (value) {
            //       return value === this.formData.password || 'Passwords don\'t match'
            //   }
            // //   min (value) {
            // //       return value.length >= 8 || 'Min 8 characters'
            // //   }
            // },
            qRulesRequired(value) {
                return !!value || 'Enter a password here';
            },
            qRulesMin(value) {
                return (
                    value === this.formData.password || "Passwords don't match"
                );
            },

            async register_request(formData) {
                const valid = await this.$refs.form.validate();
                if (!valid) {
                    return;
                }
                // this.$store.actions.auth_request(formData)
                if (
                    this.formData.name === '' ||
                    this.formData.company_name === '' ||
                    this.formData.email === '' ||
                    this.formData.phone === '' ||
                    // this.formData.subdomain === "" ||
                    this.formData.password === '' ||
                    this.formData.confirm_password === '' ||
                    this.formData.number_of_employees === '' ||
                    this.formData.role === ''
                ) {
                    this.incompleteDialog = true;
                } else {
                    let _this = this;
                    // if (_this.formData.number_of_employees > 30) {
                    //   _this.over30Dialog = true;
                    //   return;
                    // }
                    if (_this.formData.agree_to_terms === false) {
                        _this.termsDialog = true;
                        return;
                    }
                    // _this.btnTxt = "";
                    // _this.isLoading = true;
                    // _this.$store.dispatch("register_request", formData).then(() => {
                    //   _this.isLoading = false;
                    //   _this.btnTxt = "Create Account";
                    // });
                }
            },
        },
        mounted() {
            document.title = 'Kylian ERP - Register';
        },
    };
</script>

<style lang="scss">
    $primary-color: #069;
    $text-field-color: #4e4e52;

    @media (max-width: 991px) {
        .company-name .v-text-field--single-line .v-text-field__suffix,
        .company-name .v-text-field--single-line .v-text-field__prefix {
            font-size: 10px !important;
        }

        .create-submit {
            height: 45px !important;
        }

        .v-form {
            margin: 0 !important;
        }
    }

    .push-register-prefix div div div div {
        padding-top: 0;
    }

    .fa-eye,
    .fa-eyeslash {
        color: $primary-color !important;
    }

    .company-name .v-text-field--single-line {
        .v-text-field__suffix,
        .v-text-field__prefix {
            color: $text-field-color !important;
            background-color: #00669914;
            padding: 10px;
            height: 38px;
        }
    }

    // .v-text-field__suffix, .v-text-field__prefix, input {
    //     color: $text-field-color !important;
    //     background-color: #00669914;
    //     padding: 10px;
    //     height: 48px;
    // }
    .v-text-field__suffix {
        border-radius: 0px 5px 5px 0px;
        padding-top: 8px;
        padding-left: 8px !important;
    }

    .v-text-field__prefix {
        border-radius: 5px 0px 0px 5px;
        padding-top: 8px;
        padding-right: 8px !important;
        text-decoration: underline;
    }

    .company-name
        .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) {
        label {
            margin-left: 2%;
        }

        input {
            text-indent: 3%;
        }
    }

    .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
        > .v-input__control
        > .v-input__slot {
        box-shadow: 0px 0px 50px 0px #00000014 !important;
    }

    .v-text-field__details {
        padding-left: 0 !important;
    }

    .theme--light.v-messages {
        color: $primary-color !important;
    }

    .company-name
        .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
        > .v-input__control
        > .v-input__slot {
        padding: 0px !important;
    }

    h3 {
        font-family: Work Sans;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: -0.6252632141113281px;
        text-align: center;
        color: #111d5e;
    }

    label {
        color: #999 !important;
    }
</style>
<style lang="scss" scoped>
    .v-form {
        margin: 0 10%;
    }

    $primary-color: #069;
    $new-button-color: #2081ff;

    #small-link {
        color: $primary-color !important;
    }

    .space-top {
        padding-top: 20px;
    }

    .create-submit {
        width: 100%;
        height: 60px;
        border-radius: 10px !important;
        background-color: #069 !important;
        text-transform: initial !important;
        font-weight: 600;

        span {
            text-transform: initial;
        }
    }

    .new-create-submit {
        width: 100%;
        height: 60px;
        border-radius: 10px !important;
        background-color: $new-button-color !important;
        text-transform: initial !important;
        font-weight: 600 !important;

        span {
            text-transform: initial !important;
        }
    }
</style>
